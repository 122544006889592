import {
  Attribution,
  Boy,
  BoyOutlined,
  BoyRounded,
  Chair, ChairAlt,
  ChairOutlined, MusicNote, MusicNoteOutlined,
  Water,
  WaterOutlined,
  ZoomIn,
  ZoomOut
} from "@mui/icons-material";
import GridOnIcon from "@mui/icons-material/GridOn";
import React from "react";
import useSceneInteractions from "../hooks/useSceneInteractions";
import Counter from "./Counter";

export function RightSideButtons({pointerControls}: any) {

  const { roomName, toggleFurnished, toggleBand, toggleGrid, toggleZoom, zoomOn, furnished, band, dummiesCount, setDummiesCount } = useSceneInteractions();

  // const handleToggleFurnished = () => {
  //   // toggleFurnished(); // todo add this back in
  //
  //   setDummyCount(dummyCount++);
  //
  //   if (firstPerson) {
  //     setTimeout(() => {
  //       // @ts-ignore
  //       pointerControls.current.unlock()
  //     },100)
  //   }
  // }

  const handleToggleBand = () => {
    toggleBand();
  }
  const handleToggleFurnished = () => {
    toggleFurnished();
  }

  const handleToggleGrid = () => {
    toggleGrid();
  }


  return (
    <div className={`buttons-container buttons-container--mid-right`}>
      {/*{ furnished && (*/}
      {/*  <Attribution className="pointer" style={{ color: "white", margin: "4px 4px" }} onClick={() => {handleToggleFurnished()}}/>*/}
      {/*)}*/}
      {/*{ !furnished && (*/}
      {/*  <BoyOutlined className="pointer" style={{ color: "white", margin: "4px 4px" }} onClick={() => {handleToggleFurnished()}}/>*/}
      {/*)}*/}

      <>
        { band && (
          <MusicNote className={`pointer site-button`} style={{ color: "white", margin: "4px 4px" }} onClick={() => {handleToggleBand()}}/>
        )}
        { !band && (
          <MusicNoteOutlined className={`pointer site-button`} style={{ color: "white", margin: "4px 4px" }} onClick={() => {handleToggleBand()}}/>
        )}
        {/*{ furnished && (*/}
        {/*  <Chair className={`pointer site-button`} style={{ color: "white", margin: "4px 4px" }} onClick={() => {handleToggleFurnished()}}/>*/}
        {/*)}*/}
        {/*{ !furnished && (*/}
        {/*  <ChairOutlined className={`pointer site-button`} style={{ color: "white", margin: "4px 4px" }} onClick={() => {handleToggleFurnished()}}/>*/}
        {/*)}*/}


      </>


      {/*{ zoomOn && (*/}
      {/*  <ZoomIn className="pointer" style={{ color: "white", margin: "4px 4px" }} onClick={() => {toggleZoom()}}/>*/}
      {/*)}*/}
      {/*{ !zoomOn && (*/}
      {/*  <ZoomOut className="pointer" style={{ color: "white", margin: "4px 4px" }} onClick={() => {toggleZoom()}}/>*/}
      {/*)}*/}
    </div>
  )
}
