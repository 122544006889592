import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {HighQuality, HighQualityOutlined} from "@mui/icons-material";
import useSceneInteractions from "../hooks/useSceneInteractions";
import { useNavigate } from "react-router-dom";

export default function RoomSelector({}: any) {

  const { room, roomsArray, highQuality, toggleHighQuality } = useSceneInteractions();
  let navigate = useNavigate();

  const handleChangeRoom = (event: SelectChangeEvent) => {
    let newRoomName = event.target.value as string;
    console.log('new room name', newRoomName)
    let newRoom;
    if(roomsArray) {
      newRoom = roomsArray.filter((room: any) => room.name === newRoomName);
      // setRoom(newRoom[0]); // this is now done in app.tsx
      navigate(`/${newRoom[0].name}`);
    }
  };

  return (
    <div className={`room-selector my-first-step`}>

      <div className={`hq-selector ${highQuality ? 'hq-selector--on' : 'hq-selector--off'}`}>
        { highQuality && (
          <HighQuality className={"button"}  onClick={() => toggleHighQuality()} />
        )}
        { !highQuality && (
          <HighQualityOutlined className={"button button--light"} onClick={() => toggleHighQuality()} />
        )}
      </div>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Room</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={room.name}
          defaultValue={""}
          label="Room"
          onChange={(event: any) => {handleChangeRoom(event)}}
        >
          { roomsArray.map((_room: any, index:number) => {
            return <MenuItem key={index} value={_room.name}>{_room.fullName}</MenuItem>
          })}
        </Select>
      </FormControl>

    </div>
  )
}
