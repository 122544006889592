import React from "react";
import useSceneInteractions from "../../hooks/useSceneInteractions";
import Theatre1 from "./Theatre1";
import Theatre2 from "./Theatre2";
import Wembley from "./Wembley";
import Orion from "./Orion";
import {LightRoom} from "./LightRoom";

export default function Rooms({bandPosition, setBandPosition}: any) {
  const { room } = useSceneInteractions();

  console.log('why am i refreshing?')

  return (
    <>
      {/*todo can these be added dynamcially?*/}
      { room.name === 'theatre1' && (
        <Theatre1 bandPosition={bandPosition} setBandPosition={setBandPosition} />
      )}
      { room.name === 'theatre2' && (
        <Theatre2 bandPosition={bandPosition} setBandPosition={setBandPosition} />
      )}
      { room.name === 'wembley' && (
        <Wembley />
      )}
      { room.name === 'orion' && (
        <Orion />
      )}
      { room.name === 'lightroom' && (
        <LightRoom />
      )}
    </>
  )
}
