import * as THREE from "three";
import { animated, useSpring } from "@react-spring/three";
import {useDrag} from "@use-gesture/react";
import useSceneInteractions from "../../hooks/useSceneInteractions";


export default function _MovableBase ({position, setPosition, children}: any) {
  const { setIsDragging } = useSceneInteractions();
  const floorPlane = new THREE.Plane(new THREE.Vector3(0, 0.65, 0), 0); // todo might need to change this to the bounding box
  let planeIntersectPoint = new THREE.Vector3();

  const [spring, api] = useSpring(() => ({
    position: position,
    scale: 1,
    // rotation: [0, 0, 0],
    config: { friction: 10 }
  }));

  const bind = useDrag(({ active, movement: [x, y], timeStamp, event }: any) => {
      if (active) {
        event.ray.intersectPlane(floorPlane, planeIntersectPoint);
        setPosition([planeIntersectPoint.x, 0.65, planeIntersectPoint.z]); // 0.65 is the y
      }

      setIsDragging(active);

      api.start({
        position: position,
        scale: active ? 1.2 : 1,
        // rotation: [y / aspect, x / aspect, 0]
      });
      return timeStamp;
    },
    { delay: true }
  );

  // https://github.com/pmndrs/use-gesture/discussions/287
  return (
    <animated.mesh {...spring} {...bind() as any} castShadow>
      {children}
    </animated.mesh>
  )
}
