import {Dummies} from "../Dummies/Dummies";
import React, {useEffect, useRef, useState} from "react";
import * as THREE from "three";
import useSceneInteractions from "../../hooks/useSceneInteractions";
import Crate from "../Furniture/Crate";

export default function _RoomBase ({geometry, material, dummiesArray, position, rotation, roomName, dummiesPosition = [0,0,0], cratePosition = [0,0.65,16], children}: any) {
  const { setTarget, dummies, room, asset } = useSceneInteractions();
  const meshRef = useRef<any>();
  const [showWhereClicked, setShowWhereClicked] = useState(false);
  const [showWhereClickedLocation, setShowWhereClickedLocation] = useState([0,0,0]);

  const [_cratePosition, setCratePosition] = useState(cratePosition)

  const group = useRef<THREE.Group>(null!);

  const handleOnDoubleClick = (e: any) => {
    setTarget([e.point.x, 0, e.point.z])
    setShowWhereClicked(true);
    setShowWhereClickedLocation([e.point.x, 0, e.point.z]);
    setTimeout(() => {
      setShowWhereClicked(false);
    }, 500);
  }

  return (
    <group>
      <group ref={group} dispose={null} onDoubleClick={handleOnDoubleClick}>
        {/*click pointer*/}
        { showWhereClicked && (
          <mesh position={[showWhereClickedLocation[0],showWhereClickedLocation[1],showWhereClickedLocation[2]]}>
            <sphereBufferGeometry args={[0.3, 24, 24]} />
            <meshStandardMaterial color={"green"} />
          </mesh>
        )}

        {/*room*/}
        {/*<mesh ref={meshRef}*/}
        {/*      onDoubleClick={handleOnDoubleClick}*/}
        {/*  // onPointerUp={handlePointerUp}*/}
        {/*      receiveShadow geometry={geometry} material={material} position={position} rotation={rotation} />*/}

        {/*any other props or children*/}
        {children}
      </group>
      { dummies && (
        <group position={dummiesPosition}>
          <Dummies dummiesArray={dummiesArray}/>
        </group>
      )}

      {
        asset && (
          <Crate scale={0.03} position={_cratePosition} setPosition={setCratePosition} />
        )
      }
    </group>
  )
}
