import Chair from "./Chair";

export default function Chairs () {

  const SCALE = 0.7
  const rows = new Array(7).fill(0)
  return (
    <group position={[3,-1.5,3]} rotation={[0,1.6,0]}>
      { rows.map((row, index) => {
        return (
          <group position={[0, 0, -index]}>
            <Chair position={[0, 0, 0]} scale={SCALE}/>
            <Chair position={[-0.7, 0, 0]} scale={SCALE}/>
            <Chair position={[-1.4, 0, 0]} scale={SCALE}/>
            {/*<Chair position={[-2.1,0,0]} scale={SCALE} />*/}
            <Chair position={[-3.3, 0, 0]} scale={SCALE}/>
            <Chair position={[-3.9, 0, 0]} scale={SCALE}/>
            <Chair position={[-4.6, 0, 0]} scale={SCALE}/>
          </group>
        )
      })}
    </group>
  )
}
