import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import NightTimeSwitch from "./NightTimeSwitch";

export default function NavBar(
  {toggleLeftSideDrawer,firstPerson, setShowBookingModal, showBookingModal, showInfoModal, setShowInfoModal, pointerControls, nightTime, setNightTime}:
    any) {


  /**
   * returnHome
   */
  const returnHome = () => {
    window.location.replace(window.location.origin);
  };

  const handleHamburgerClick = (event: any) => {
    if (firstPerson) {
      setTimeout(() => {
        // @ts-ignore
        pointerControls.current.unlock()
      },100)
    } else {
      toggleLeftSideDrawer(event); // don't show in first person mode
    }
  }

  const handleClickBody = () => {
    if (firstPerson) {
      setTimeout(() => {
        // @ts-ignore
        pointerControls.current.unlock()
      },100)
    }
  }

  return (
    <Box component="div" sx={{ flexGrow: 1 }} className="navBar" onClick={() => {handleClickBody()}}>
      <AppBar position="fixed" color="transparent">
        <Toolbar>
          {/*<div className={`hamburger-button`}>*/}
          {/*  <Menu className="pointer" style={{ color: "#615438", margin: "4px 10px 0 -5px" }} onClick={(event: any) => {handleHamburgerClick(event)}}/>*/}
          {/*</div>*/}
          <Typography  variant="h6" component="div" sx={{ flexGrow: 1 }} >
            <span style={{color: "#ffffff"}} className="main-title" onClick={() => returnHome()}>{process.env.REACT_APP_NAV_TITLE}</span>
          </Typography>

          <div style={{display:'flex', flexDirection:'row'}}>
            <NightTimeSwitch nightTime={nightTime} setNightTime={setNightTime} />

            {/*<Typography  style={{marginLeft:'20px'}} variant="h6" component="div" sx={{ flexGrow: 1 }} >*/}
            {/*  <span style={{float: 'right', color:'white'}}><a style={{color: 'white', textDecoration:'none'}} target="_blank" href="https://unegma.digital">unegma<span style={{color:'cyan'}}>.</span>digital</a></span>*/}
            {/*</Typography>*/}
          </div>


        </Toolbar>
      </AppBar>
    </Box>
  )
}
