import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cylinder056_metal_0: THREE.Mesh
    polySurface16_wood_0: THREE.Mesh
    polySurface17_metal_0: THREE.Mesh
    polySurface18_metal_0: THREE.Mesh
    polySurface19_metal_0: THREE.Mesh
    polySurface20_metal_0: THREE.Mesh
    polySurface21_metal_0: THREE.Mesh
    polySurface22_metal_0: THREE.Mesh
    polySurface23_metal_0: THREE.Mesh
    polySurface24_metal_0: THREE.Mesh
    polySurface25_metal_0: THREE.Mesh
    polySurface26_metal_0: THREE.Mesh
    polySurface27_wood_0: THREE.Mesh
    polySurface28_wood_0: THREE.Mesh
    polySurface29_plastic_0: THREE.Mesh
    polySurface30_plastic_0: THREE.Mesh
    polySurface32_metal_0: THREE.Mesh
    polySurface33_metal_0: THREE.Mesh
    polySurface34_metal_0: THREE.Mesh
    polySurface35_metal_0: THREE.Mesh
    polySurface36_metal_0: THREE.Mesh
    polySurface37_metal_0: THREE.Mesh
    polySurface38_metal_0: THREE.Mesh
    polySurface39_metal_0: THREE.Mesh
    polySurface40_metal_0: THREE.Mesh
    polySurface41_metal_0: THREE.Mesh
    polySurface42_wood_0: THREE.Mesh
    polySurface43_wood_0: THREE.Mesh
    polySurface44_plastic_0: THREE.Mesh
    polySurface45_plastic_0: THREE.Mesh
    polySurface46_leather_0: THREE.Mesh
    polySurface48_leather_0: THREE.Mesh
    polySurface49_leather_0: THREE.Mesh
    polySurface50_leather_0: THREE.Mesh
    polySurface51_wood_0: THREE.Mesh
  }
  materials: {
    metal: THREE.MeshStandardMaterial
    wood: THREE.MeshStandardMaterial
    plastic: THREE.MeshStandardMaterial
    leather: THREE.MeshStandardMaterial
  }
}

export default function Chair({ ...props }: JSX.IntrinsicElements['group']) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/furniture/chair-modern-transformed.glb`

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Cylinder056_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface16_wood_0.geometry} material={materials.wood} />
      <mesh geometry={nodes.polySurface17_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface18_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface19_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface20_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface21_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface22_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface23_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface24_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface25_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface26_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface27_wood_0.geometry} material={materials.wood} />
      <mesh geometry={nodes.polySurface28_wood_0.geometry} material={materials.wood} />
      <mesh geometry={nodes.polySurface29_plastic_0.geometry} material={materials.plastic} />
      <mesh geometry={nodes.polySurface30_plastic_0.geometry} material={materials.plastic} />
      <mesh geometry={nodes.polySurface32_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface33_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface34_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface35_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface36_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface37_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface38_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface39_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface40_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface41_metal_0.geometry} material={materials.metal} />
      <mesh geometry={nodes.polySurface42_wood_0.geometry} material={materials.wood} />
      <mesh geometry={nodes.polySurface43_wood_0.geometry} material={materials.wood} />
      <mesh geometry={nodes.polySurface44_plastic_0.geometry} material={materials.plastic} />
      <mesh geometry={nodes.polySurface45_plastic_0.geometry} material={materials.plastic} />
      <mesh geometry={nodes.polySurface46_leather_0.geometry} material={materials.leather} />
      <mesh geometry={nodes.polySurface48_leather_0.geometry} material={materials.leather} />
      <mesh geometry={nodes.polySurface49_leather_0.geometry} material={materials.leather} />
      <mesh geometry={nodes.polySurface50_leather_0.geometry} material={materials.leather} />
      <mesh geometry={nodes.polySurface51_wood_0.geometry} material={materials.wood} />
    </group>
  )
}
