import {Button, Modal, Typography, Box} from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import useSceneInteractions from "../hooks/useSceneInteractions";

export default function BlueprintsModal ({ pointerControls, showBlueprintsModal, setShowBlueprintsModal }: any) {
  const { room, roomsArray } = useSceneInteractions();

  const handleClose = () => {
    setShowBlueprintsModal(false);
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  return (
    <Modal
      className="info-modal"
      open={showBlueprintsModal}
      onClose={handleClose}
    >
      <Box component="div" sx={modalStyle}>
        <HighlightOffIcon className="closeModalButton" onClick={() => { setShowBlueprintsModal(false)}}/>
        <Typography variant="h3" className="secondaryColor">Floor Plan</Typography>
        <div>
          <Typography  variant="h4" className="secondaryColor">{room.fullName}</Typography>
          <img className="blueprint" src={`${process.env.REACT_APP_ASSETS_URL}/blueprints/${room.blueprint}`} />
        </div>

        <br/>
        <hr/>
        <br/>
        <br/>

        <div className={'infoModal__button-container'}>
          <Button className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
