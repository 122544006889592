import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import {extend, ReactThreeFiber, useFrame, Vector3} from "@react-three/fiber";
import _MovableBase from "../Rooms/_MovableBase";

type GLTFResult = GLTF & {
  nodes: {
    Object_25: THREE.SkinnedMesh
    Object_27: THREE.SkinnedMesh
    Object_29: THREE.SkinnedMesh
    Object_31: THREE.SkinnedMesh
    Object_33: THREE.SkinnedMesh
    Object_35: THREE.SkinnedMesh
    Object_37: THREE.SkinnedMesh
    Object_39: THREE.SkinnedMesh
    ['NONE-DC_Shell__0']: THREE.Mesh
    ['NONE-DC_Shell__0001']: THREE.Mesh
    ['NONE-DC_Shell__0002']: THREE.Mesh
    ['NONE-DC_Shell__0003']: THREE.Mesh
    ['NONE-DC_Shell__0004']: THREE.Mesh
    ['NONE-DC_Shell__0005']: THREE.Mesh
    ['NONE-DC_Shell_08_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0001']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0002']: THREE.Mesh
    ['NONE-DC_Shell__0006']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0003']: THREE.Mesh
    ['NONE-DC_Shell__0007']: THREE.Mesh
    ['NONE-DC_Shell__0008']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0001']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0002']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0003']: THREE.Mesh
    ['NONE-DC_Shell_01_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_01_-_Default_0001']: THREE.Mesh
    ['NONE-DC_Shell_01_-_Default_0002']: THREE.Mesh
    ['CirPattern1-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0012']: THREE.Mesh
    ['NONE-DC_Shell_03_-_Default_0001']: THREE.Mesh
    ['Fillet17-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_03_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0004']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0005']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0006']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0007']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0008']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0009']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0010']: THREE.Mesh
    ['NONE-DC_Shell_02_-_Default_0011']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0001']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0002']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0003']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0004']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0005']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0006']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0007']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0008']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0009']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0010']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0011']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0012']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0013']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0014']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0015']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0016']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0017']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0018']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0019']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0020']: THREE.Mesh
    ['NONE-DC_Shell_04_-_Default_0021']: THREE.Mesh
    ['NONE-DC_Shell__0009']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0004']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0001']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0002']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0003']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0004']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0005']: THREE.Mesh
    ['NONE-DC_Shell__0010']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0005']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0006']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0006']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0007']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0008']: THREE.Mesh
    ['NONE-DC_Shell_06_-_Default_0009']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0007']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0008']: THREE.Mesh
    ['NONE-DC_Shell_07_-_Default_0009']: THREE.Mesh
    ['NONE-DC_Shell__0011']: THREE.Mesh
    ['NONE-DC_Shell__0012']: THREE.Mesh
    ['NONE-DC_Shell__0013']: THREE.Mesh
    ['NONE-DC_Shell__0014']: THREE.Mesh
    ['NONE-DC_Shell__0015']: THREE.Mesh
    ['NONE-DC_Shell__0016']: THREE.Mesh
    ['NONE-DC_Shell__0017']: THREE.Mesh
    ['NONE-DC_Shell__0018']: THREE.Mesh
    ['NONE-DC_Shell__0019']: THREE.Mesh
    ['NONE-DC_Shell__0020']: THREE.Mesh
    ['NONE-DC_Shell__0021']: THREE.Mesh
    ['NONE-DC_Shell__0022']: THREE.Mesh
    ['NONE-DC_Shell__0023']: THREE.Mesh
    ['NONE-DC_Shell__0024']: THREE.Mesh
    ['NONE-DC_Shell__0025']: THREE.Mesh
    ['NONE-DC_Shell__0026']: THREE.Mesh
    ['NONE-DC_Shell__0027']: THREE.Mesh
    ['CirPattern3-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Cut-Extrude2-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Extrude-Thin1-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Revolve1-DC_Shell_05_-_Default_0']: THREE.Mesh
    ['Scale11-DC_Shell__0']: THREE.Mesh
    ['NONE-DC_Shell__0028']: THREE.Mesh
    ['NONE-DC_Shell__0029']: THREE.Mesh
    ['NONE-DC_Shell__0030']: THREE.Mesh
    ['NONE-DC_Shell__0031']: THREE.Mesh
    ['NONE-DC_Shell__0032']: THREE.Mesh
    ['NONE-DC_Shell__0033']: THREE.Mesh
    ['NONE-DC_Shell__0034']: THREE.Mesh
    ['NONE-DC_Shell__0035']: THREE.Mesh
    ['NONE-DC_Shell__0036']: THREE.Mesh
    ['NONE-DC_Shell__0037']: THREE.Mesh
    ['NONE-DC_Shell__0038']: THREE.Mesh
    ['NONE-DC_Shell__0039']: THREE.Mesh
    ['NONE-DC_Shell__0040']: THREE.Mesh
    ['NONE-DC_Shell__0041']: THREE.Mesh
    ['NONE-DC_Shell__0042']: THREE.Mesh
    ['NONE-DC_Shell__0043']: THREE.Mesh
    ['NONE-DC_Shell__0044']: THREE.Mesh
    ['NONE-DC_Shell__0045']: THREE.Mesh
    ['NONE-DC_Shell__0046']: THREE.Mesh
    ['NONE-DC_Shell__0047']: THREE.Mesh
    ['NONE-DC_Shell__0048']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0001']: THREE.Mesh
    ['Scale11-DC_Shell__0001']: THREE.Mesh
    ['NONE-DC_Shell__0049']: THREE.Mesh
    ['NONE-DC_Shell__0050']: THREE.Mesh
    ['NONE-DC_Shell__0051']: THREE.Mesh
    ['NONE-DC_Shell__0052']: THREE.Mesh
    ['NONE-DC_Shell__0053']: THREE.Mesh
    ['NONE-DC_Shell__0054']: THREE.Mesh
    ['NONE-DC_Shell__0055']: THREE.Mesh
    ['NONE-DC_Shell__0056']: THREE.Mesh
    ['NONE-DC_Shell__0057']: THREE.Mesh
    ['NONE-DC_Shell__0058']: THREE.Mesh
    ['NONE-DC_Shell__0059']: THREE.Mesh
    ['NONE-DC_Shell__0060']: THREE.Mesh
    ['NONE-DC_Shell__0061']: THREE.Mesh
    ['NONE-DC_Shell__0062']: THREE.Mesh
    ['NONE-DC_Shell__0063']: THREE.Mesh
    ['NONE-DC_Shell__0064']: THREE.Mesh
    ['NONE-DC_Shell__0065']: THREE.Mesh
    ['NONE-DC_Shell__0066']: THREE.Mesh
    ['NONE-DC_Shell__0067']: THREE.Mesh
    ['NONE-DC_Shell__0068']: THREE.Mesh
    ['NONE-DC_Shell__0069']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0002']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0003']: THREE.Mesh
    ['Scale11-DC_Shell__0002']: THREE.Mesh
    ['NONE-DC_Shell__0070']: THREE.Mesh
    ['NONE-DC_Shell__0071']: THREE.Mesh
    ['NONE-DC_Shell__0072']: THREE.Mesh
    ['NONE-DC_Shell__0073']: THREE.Mesh
    ['NONE-DC_Shell__0074']: THREE.Mesh
    ['NONE-DC_Shell__0075']: THREE.Mesh
    ['NONE-DC_Shell__0076']: THREE.Mesh
    ['NONE-DC_Shell__0077']: THREE.Mesh
    ['NONE-DC_Shell__0078']: THREE.Mesh
    ['NONE-DC_Shell__0079']: THREE.Mesh
    ['NONE-DC_Shell__0080']: THREE.Mesh
    ['NONE-DC_Shell__0081']: THREE.Mesh
    ['NONE-DC_Shell__0082']: THREE.Mesh
    ['NONE-DC_Shell__0083']: THREE.Mesh
    ['NONE-DC_Shell__0084']: THREE.Mesh
    ['NONE-DC_Shell__0085']: THREE.Mesh
    ['NONE-DC_Shell__0086']: THREE.Mesh
    ['NONE-DC_Shell__0087']: THREE.Mesh
    ['NONE-DC_Shell__0088']: THREE.Mesh
    ['NONE-DC_Shell__0089']: THREE.Mesh
    ['NONE-DC_Shell__0090']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0004']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0005']: THREE.Mesh
    ['Scale11-DC_Shell__0003']: THREE.Mesh
    ['NONE-DC_Shell__0091']: THREE.Mesh
    ['NONE-DC_Shell__0092']: THREE.Mesh
    ['NONE-DC_Shell__0093']: THREE.Mesh
    ['NONE-DC_Shell__0094']: THREE.Mesh
    ['NONE-DC_Shell__0095']: THREE.Mesh
    ['NONE-DC_Shell__0096']: THREE.Mesh
    ['NONE-DC_Shell__0097']: THREE.Mesh
    ['NONE-DC_Shell__0098']: THREE.Mesh
    ['NONE-DC_Shell__0099']: THREE.Mesh
    ['NONE-DC_Shell__0100']: THREE.Mesh
    ['NONE-DC_Shell__0101']: THREE.Mesh
    ['NONE-DC_Shell__0102']: THREE.Mesh
    ['NONE-DC_Shell__0103']: THREE.Mesh
    ['NONE-DC_Shell__0104']: THREE.Mesh
    ['NONE-DC_Shell__0105']: THREE.Mesh
    ['NONE-DC_Shell__0106']: THREE.Mesh
    ['NONE-DC_Shell__0107']: THREE.Mesh
    ['NONE-DC_Shell__0108']: THREE.Mesh
    ['NONE-DC_Shell__0109']: THREE.Mesh
    ['NONE-DC_Shell__0110']: THREE.Mesh
    ['NONE-DC_Shell__0111']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0006']: THREE.Mesh
    ['NONE-DC_Shell_05_-_Default_0007']: THREE.Mesh
    ['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Fillet1-DC_Shell_05_-_Default_0']: THREE.Mesh
    ['Fillet2-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau']: THREE.Mesh
    ['Fillet1-DC_Shell_05_-_Default_0001']: THREE.Mesh
    ['Fillet2-DC_Shell_07_-_Default_0001']: THREE.Mesh
    ['Fillet2-DC_Shell_07_-_Default_0002']: THREE.Mesh
    ['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau001']: THREE.Mesh
    ['Fillet1-DC_Shell_05_-_Default_0002']: THREE.Mesh
    ['Fillet2-DC_Shell_07_-_Default_0003']: THREE.Mesh
    ['Fillet2-DC_Shell_07_-_Default_0004']: THREE.Mesh
    ['Fillet2-DC_Shell_07_-_Default_0005']: THREE.Mesh
    ['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau002']: THREE.Mesh
    ['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau003']: THREE.Mesh
    ['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau004']: THREE.Mesh
    ['Fillet1-DC_Shell_05_-_Default_0003']: THREE.Mesh
    ['Fillet1-DC_Shell_05_-_Default_0004']: THREE.Mesh
    ['Fillet1-DC_Shell_05_-_Default_0005']: THREE.Mesh
    ['Imported1-DC_Shell__0']: THREE.Mesh
    ['Imported1-DC_Shell__0001']: THREE.Mesh
    ['Imported1-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Imported1-DC_Shell__0002']: THREE.Mesh
    ['Imported1-DC_Shell__0003']: THREE.Mesh
    ['Imported1-DC_Shell__0004']: THREE.Mesh
    ['Imported1-DC_Shell__0005']: THREE.Mesh
    ['Imported1-DC_Shell__0006']: THREE.Mesh
    ['Imported1-DC_Shell__0007']: THREE.Mesh
    ['Imported1-DC_Shell__0008']: THREE.Mesh
    ['Imported1-DC_Shell__0009']: THREE.Mesh
    ['Imported1-DC_Shell__0010']: THREE.Mesh
    ['Imported1-DC_Shell__0011']: THREE.Mesh
    ['Sweep1-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Sweep2-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Sweep3-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Sweep4-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Sweep5-DC_Shell_07_-_Default_0']: THREE.Mesh
    ['Sweep6-DC_Shell_07_-_Default_0']: THREE.Mesh
    Object_140: THREE.SkinnedMesh
    Object_142: THREE.SkinnedMesh
    Object_144: THREE.SkinnedMesh
    Object_146: THREE.SkinnedMesh
    Object_148: THREE.SkinnedMesh
    Object_150: THREE.SkinnedMesh
    Object_152: THREE.SkinnedMesh
    Object_154: THREE.SkinnedMesh
    ['NONE-DC_Shell_Material_#27_0']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0001']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0002']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0003']: THREE.Mesh
    ['NONE-DC_Shell_09_-_Default_0']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0001']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0002']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0003']: THREE.Mesh
    ['NONE-DC_Shell_09_-_Default_0001']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0004']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0004']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0005']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0005']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0006']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0007']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0006']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0007']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0008']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0009']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0010']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0011']: THREE.Mesh
    ['NONE-DC_Shell__0112']: THREE.Mesh
    ['NONE-DC_Shell__0113']: THREE.Mesh
    ['NONE-DC_Shell__0114']: THREE.Mesh
    ['NONE-DC_Shell__0115']: THREE.Mesh
    ['NONE-DC_Shell__0116']: THREE.Mesh
    ['NONE-DC_Shell__0117']: THREE.Mesh
    ['NONE-DC_Shell__0118']: THREE.Mesh
    ['NONE-DC_Shell__0119']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0012']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0013']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0008']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0009']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0014']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0015']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0010']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0011']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0016']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0012']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0013']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0017']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0014']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0015']: THREE.Mesh
    ['NONE-DC_Shell__0120']: THREE.Mesh
    ['NONE-DC_Shell__0121']: THREE.Mesh
    ['NONE-DC_Shell__0122']: THREE.Mesh
    ['NONE-DC_Shell__0123']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0016']: THREE.Mesh
    ['NONE-DC_Shell__0124']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0017']: THREE.Mesh
    ['NONE-DC_Shell__0125']: THREE.Mesh
    ['NONE-DC_Shell__0126']: THREE.Mesh
    ['NONE-DC_Shell__0127']: THREE.Mesh
    ['NONE-DC_Shell__0128']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0018']: THREE.Mesh
    ['NONE-DC_Shell__0129']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0001']: THREE.Mesh
    ['NONE-DC_Shell__0130']: THREE.Mesh
    ['NONE-DC_Shell__0131']: THREE.Mesh
    ['NONE-DC_Shell__0132']: THREE.Mesh
    ['NONE-DC_Shell__0133']: THREE.Mesh
    ['NONE-DC_Shell__0134']: THREE.Mesh
    ['NONE-DC_Shell__0135']: THREE.Mesh
    ['NONE-DC_Shell__0136']: THREE.Mesh
    ['NONE-DC_Shell__0137']: THREE.Mesh
    ['NONE-DC_Shell__0138']: THREE.Mesh
    ['NONE-DC_Shell__0139']: THREE.Mesh
    ['NONE-DC_Shell__0140']: THREE.Mesh
    ['NONE-DC_Shell__0141']: THREE.Mesh
    ['NONE-DC_Shell__0142']: THREE.Mesh
    ['NONE-DC_Shell__0143']: THREE.Mesh
    ['NONE-DC_Shell__0144']: THREE.Mesh
    ['NONE-DC_Shell__0145']: THREE.Mesh
    ['NONE-DC_Shell__0146']: THREE.Mesh
    ['NONE-DC_Shell__0147']: THREE.Mesh
    ['NONE-DC_Shell__0148']: THREE.Mesh
    ['NONE-DC_Shell__0149']: THREE.Mesh
    ['NONE-DC_Shell__0150']: THREE.Mesh
    ['NONE-DC_Shell__0151']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0002']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0003']: THREE.Mesh
    ['NONE-DC_Shell__0152']: THREE.Mesh
    ['NONE-DC_Shell__0153']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0004']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0005']: THREE.Mesh
    ['NONE-DC_Shell__0154']: THREE.Mesh
    ['NONE-DC_Shell__0155']: THREE.Mesh
    ['NONE-DC_Shell__0156']: THREE.Mesh
    ['NONE-DC_Shell__0157']: THREE.Mesh
    ['NONE-DC_Shell__0158']: THREE.Mesh
    ['NONE-DC_Shell__0159']: THREE.Mesh
    ['NONE-DC_Shell__0160']: THREE.Mesh
    ['NONE-DC_Shell__0161']: THREE.Mesh
    ['NONE-DC_Shell__0162']: THREE.Mesh
    ['NONE-DC_Shell__0163']: THREE.Mesh
    ['NONE-DC_Shell__0164']: THREE.Mesh
    ['NONE-DC_Shell__0165']: THREE.Mesh
    ['NONE-DC_Shell__0166']: THREE.Mesh
    ['NONE-DC_Shell__0167']: THREE.Mesh
    ['NONE-DC_Shell__0168']: THREE.Mesh
    ['NONE-DC_Shell__0169']: THREE.Mesh
    ['NONE-DC_Shell__0170']: THREE.Mesh
    ['NONE-DC_Shell__0171']: THREE.Mesh
    ['NONE-DC_Shell__0172']: THREE.Mesh
    ['NONE-DC_Shell__0173']: THREE.Mesh
    ['NONE-DC_Shell__0174']: THREE.Mesh
    ['NONE-DC_Shell__0175']: THREE.Mesh
    ['NONE-DC_Shell__0176']: THREE.Mesh
    ['NONE-DC_Shell__0177']: THREE.Mesh
    ['NONE-DC_Shell__0178']: THREE.Mesh
    ['NONE-DC_Shell__0179']: THREE.Mesh
    ['NONE-DC_Shell__0180']: THREE.Mesh
    ['NONE-DC_Shell__0181']: THREE.Mesh
    ['NONE-DC_Shell__0182']: THREE.Mesh
    ['NONE-DC_Shell__0183']: THREE.Mesh
    ['NONE-DC_Shell__0184']: THREE.Mesh
    ['NONE-DC_Shell__0185']: THREE.Mesh
    ['NONE-DC_Shell__0186']: THREE.Mesh
    ['NONE-DC_Shell__0187']: THREE.Mesh
    ['NONE-DC_Shell__0188']: THREE.Mesh
    ['NONE-DC_Shell__0189']: THREE.Mesh
    ['NONE-DC_Shell__0190']: THREE.Mesh
    ['NONE-DC_Shell__0191']: THREE.Mesh
    ['NONE-DC_Shell__0192']: THREE.Mesh
    ['NONE-DC_Shell__0193']: THREE.Mesh
    ['NONE-DC_Shell__0194']: THREE.Mesh
    ['NONE-DC_Shell__0195']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0006']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0007']: THREE.Mesh
    ['NONE-DC_Shell__0196']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0008']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0009']: THREE.Mesh
    ['NONE-DC_Shell__0197']: THREE.Mesh
    ['NONE-DC_Shell__0198']: THREE.Mesh
    ['NONE-DC_Shell__0199']: THREE.Mesh
    ['NONE-DC_Shell__0200']: THREE.Mesh
    ['NONE-DC_Shell__0201']: THREE.Mesh
    ['NONE-DC_Shell__0202']: THREE.Mesh
    ['NONE-DC_Shell__0203']: THREE.Mesh
    ['NONE-DC_Shell__0204']: THREE.Mesh
    ['NONE-DC_Shell__0205']: THREE.Mesh
    ['NONE-DC_Shell__0206']: THREE.Mesh
    ['NONE-DC_Shell__0207']: THREE.Mesh
    ['NONE-DC_Shell__0208']: THREE.Mesh
    ['NONE-DC_Shell__0209']: THREE.Mesh
    ['NONE-DC_Shell__0210']: THREE.Mesh
    ['NONE-DC_Shell__0211']: THREE.Mesh
    ['NONE-DC_Shell__0212']: THREE.Mesh
    ['NONE-DC_Shell__0213']: THREE.Mesh
    ['NONE-DC_Shell__0214']: THREE.Mesh
    ['NONE-DC_Shell__0215']: THREE.Mesh
    ['NONE-DC_Shell__0216']: THREE.Mesh
    ['NONE-DC_Shell__0217']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0019']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0020']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0021']: THREE.Mesh
    ['NONE-DC_Shell_Material_#29_0010']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0022']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0001']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0002']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0003']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0004']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0005']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0006']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0007']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0008']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0009']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0010']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0011']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0012']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0013']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0014']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0015']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0016']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0017']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0018']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0019']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0020']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0021']: THREE.Mesh
    ['NONE-DC_Shell_Material_#30_0022']: THREE.Mesh
    ['NONE-DC_Shell__0218']: THREE.Mesh
    ['NONE-DC_Shell__0219']: THREE.Mesh
    ['NONE-DC_Shell__0220']: THREE.Mesh
    ['NONE-DC_Shell__0221']: THREE.Mesh
    ['NONE-DC_Shell__0222']: THREE.Mesh
    ['NONE-DC_Shell__0223']: THREE.Mesh
    ['NONE-DC_Shell__0224']: THREE.Mesh
    ['NONE-DC_Shell__0225']: THREE.Mesh
    ['NONE-DC_Shell__0226']: THREE.Mesh
    ['NONE-DC_Shell__0227']: THREE.Mesh
    ['NONE-DC_Shell_Material_#31_0']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0023']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0024']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0025']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0026']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0027']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0028']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0029']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0030']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0031']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0032']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0033']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0034']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0035']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0036']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0037']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0038']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0039']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0040']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0018']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0019']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0020']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0021']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0022']: THREE.Mesh
    ['NONE-DC_Shell_Material_#28_0023']: THREE.Mesh
    ['NONE-DC_Shell__0228']: THREE.Mesh
    ['NONE-DC_Shell__0229']: THREE.Mesh
    ['NONE-DC_Shell__0230']: THREE.Mesh
    ['NONE-DC_Shell__0231']: THREE.Mesh
    ['NONE-DC_Shell__0232']: THREE.Mesh
    ['NONE-DC_Shell__0233']: THREE.Mesh
    ['NONE-DC_Shell__0234']: THREE.Mesh
    ['NONE-DC_Shell__0235']: THREE.Mesh
    ['NONE-DC_Shell__0236']: THREE.Mesh
    ['NONE-DC_Shell__0237']: THREE.Mesh
    ['NONE-DC_Shell__0238']: THREE.Mesh
    ['NONE-DC_Shell__0239']: THREE.Mesh
    ['NONE-DC_Shell__0240']: THREE.Mesh
    ['NONE-DC_Shell__0241']: THREE.Mesh
    ['NONE-DC_Shell__0242']: THREE.Mesh
    ['NONE-DC_Shell__0243']: THREE.Mesh
    ['NONE-DC_Shell__0244']: THREE.Mesh
    ['NONE-DC_Shell__0245']: THREE.Mesh
    ['NONE-DC_Shell__0246']: THREE.Mesh
    ['NONE-DC_Shell__0247']: THREE.Mesh
    ['NONE-DC_Shell_Material_#27_0041']: THREE.Mesh
    ['NONE-DC_Shell__0248']: THREE.Mesh
    ['NONE-DC_Shell__0249']: THREE.Mesh
    ['NONE-DC_Shell_Material_#31_0001']: THREE.Mesh
    ['NONE-DC_Shell__0250']: THREE.Mesh
    ['NONE-DC_Shell_Material_#31_0002']: THREE.Mesh
    Object_1641: THREE.SkinnedMesh
    Object_1643: THREE.SkinnedMesh
    Object_1645: THREE.SkinnedMesh
    Object_1647: THREE.SkinnedMesh
    Object_1649: THREE.SkinnedMesh
    Object_1651: THREE.SkinnedMesh
    Object_1653: THREE.SkinnedMesh
    Object_1752: THREE.SkinnedMesh
    Object_1754: THREE.SkinnedMesh
    Object_1756: THREE.SkinnedMesh
    Object_1758: THREE.SkinnedMesh
    Object_1760: THREE.SkinnedMesh
    Object_1762: THREE.SkinnedMesh
    Object_1764: THREE.SkinnedMesh
    arch67_22_01001_zelezo_0: THREE.Mesh
    arch67_22_02001_Material005_0: THREE.Mesh
    arch67_22_03001_chrome_0: THREE.Mesh
    arch67_22_04001_wire_115115115001_0: THREE.Mesh
    arch67_22_05001_wire_115115115001_0: THREE.Mesh
    arch67_22_06001_wire_115115115001_0: THREE.Mesh
    arch67_22_07001_Material002_0: THREE.Mesh
    arch67_22_08001_wire_115115115001_0: THREE.Mesh
    arch67_22_09001_Material006_0: THREE.Mesh
    arch67_22_11001_zelezo_0: THREE.Mesh
    ['arch67_22_12001_r��fek_0']: THREE.Mesh
    ['arch67_22_13001_bic��_0']: THREE.Mesh
    arch67_22_14001_Material009_0: THREE.Mesh
    arch67_22_14001_spodek_0: THREE.Mesh
    arch67_22_16001_zelezo_0: THREE.Mesh
    arch67_22_17001_Material005_0: THREE.Mesh
    arch67_22_18001_chrome_0: THREE.Mesh
    arch67_22_19001_Material005_0: THREE.Mesh
    arch67_22_20001_Material018_0: THREE.Mesh
    arch67_22_21001_Material003_0: THREE.Mesh
    arch67_22_22001_Material005_0: THREE.Mesh
    arch67_22_23001_chrome_0: THREE.Mesh
    ['arch67_22_24001_��lapka_0']: THREE.Mesh
    arch67_22_25001_Material004_0: THREE.Mesh
    arch67_22_26001_chrome_0: THREE.Mesh
    arch67_22_27001_Material005_0: THREE.Mesh
    arch67_22_28001_None_0: THREE.Mesh
    arch67_22_30001_zelezo_0: THREE.Mesh
    arch67_22_31001_zelezo_0: THREE.Mesh
    arch67_22_32001_zelezo_0: THREE.Mesh
    arch67_22_33001_Material013_0: THREE.Mesh
    ['arch67_22_34001_r��fek_0']: THREE.Mesh
    ['arch67_22_35001_bic��_0']: THREE.Mesh
    arch67_22_36001_Material015_0: THREE.Mesh
    arch67_22_37001_Material005_0: THREE.Mesh
    arch67_22_38001_chrome_0: THREE.Mesh
    arch67_22_39001_None_0: THREE.Mesh
    arch67_22_40001_None_0: THREE.Mesh
    arch67_22_41001_None_0: THREE.Mesh
    arch67_22_42001_wire_115115115001_0: THREE.Mesh
    arch67_22_43001_None_0: THREE.Mesh
    arch67_22_44001_None_0: THREE.Mesh
    arch67_22_45001_zelezo_0: THREE.Mesh
    arch67_22_46001_Material012_0: THREE.Mesh
    arch67_22_47001_Material019_0: THREE.Mesh
    ['arch67_22_48001_r��fek_0']: THREE.Mesh
    ['arch67_22_49001_bic��_0']: THREE.Mesh
    ['arch67_22_49002_bic��_0']: THREE.Mesh
    arch67_22_50001_Material011_0: THREE.Mesh
    arch67_22_51001_Material017_0: THREE.Mesh
    ['arch67_22_52001_r��fek_0']: THREE.Mesh
    arch67_22_53001_wire_115115115001_0: THREE.Mesh
    arch67_22_54001_Material006_0: THREE.Mesh
    arch67_22_55001_zelezo_0: THREE.Mesh
    arch67_22_58001_Material005_0: THREE.Mesh
    arch67_22_59001_Material007_0: THREE.Mesh
    arch67_22_60001_Material001_0: THREE.Mesh
    ['arch67_22_61001_��lapka_0']: THREE.Mesh
    ['arch67_22_62001_r��fek_0']: THREE.Mesh
    arch67_22_63001_wire_115115115001_0: THREE.Mesh
    arch67_22_64001_wire_115115115001_0: THREE.Mesh
    arch67_22_65001_Material008_0: THREE.Mesh
    arch67_22_66001_zelezo_0: THREE.Mesh
    arch67_22_67000_Material016_0: THREE.Mesh
    ['arch67_22_67001_bic��_0']: THREE.Mesh
    left_stick_Material010_0: THREE.Mesh
    left_stick_Material014_0: THREE.Mesh
    right_stick_Material010_0: THREE.Mesh
    right_stick_Material014_0: THREE.Mesh
    Object_2137: THREE.SkinnedMesh
    Object_2139: THREE.SkinnedMesh
    Object_2141: THREE.SkinnedMesh
    Object_2143: THREE.SkinnedMesh
    Object_2145: THREE.SkinnedMesh
    Object_2147: THREE.SkinnedMesh
    Object_2149: THREE.SkinnedMesh
    Cube047__0: THREE.Mesh
    Cube048__0: THREE.Mesh
    Cube049__0: THREE.Mesh
    Cube050__0: THREE.Mesh
    Cube051__0: THREE.Mesh
    Cube052__0: THREE.Mesh
    Cube053__0: THREE.Mesh
    Cube054__0: THREE.Mesh
    Cube055__0: THREE.Mesh
    Cube056__0: THREE.Mesh
    Cube057__0: THREE.Mesh
    Cube058__0: THREE.Mesh
    Cube059__0: THREE.Mesh
    Cube060__0: THREE.Mesh
    Cylinder016__0: THREE.Mesh
    Cylinder017__0: THREE.Mesh
    Cylinder019__0: THREE.Mesh
    Cylinder020__0: THREE.Mesh
    Cylinder021__0: THREE.Mesh
    Cylinder022__0: THREE.Mesh
    Cylinder029__0: THREE.Mesh
    klavishi_bel__0: THREE.Mesh
    korpus_bok__0: THREE.Mesh
    korpus1__0: THREE.Mesh
    Cube070__0: THREE.Mesh
    Cylinder018__0: THREE.Mesh
    Cylinder023__0: THREE.Mesh
    Cylinder030__0: THREE.Mesh
    Cylinder031__0: THREE.Mesh
    Cylinder034__0: THREE.Mesh
    Cylinder035__0: THREE.Mesh
    Cylinder036__0: THREE.Mesh
    Cylinder037__0: THREE.Mesh
    micro_wire001__0: THREE.Mesh
    trinoga_1002__0: THREE.Mesh
    trinoga_1003__0: THREE.Mesh
    trinoga_2004__0: THREE.Mesh
    trinoga_2005__0: THREE.Mesh
    trinoga_4001__0: THREE.Mesh
    trinoga_5003__0: THREE.Mesh
    trinoga_6003__0: THREE.Mesh
    trinoga_7003__0: THREE.Mesh
    trinoga_derg1001__0: THREE.Mesh
    pedal__0: THREE.Mesh
    pedal_wire_input__0: THREE.Mesh
    podstavka__0: THREE.Mesh
    Cube038__0: THREE.Mesh
    Cube065__0: THREE.Mesh
    Cube066__0: THREE.Mesh
    Cube067__0: THREE.Mesh
    Cube068__0: THREE.Mesh
    Cube069__0: THREE.Mesh
    trinoga_2003__0: THREE.Mesh
    trinoga_5002__0: THREE.Mesh
    trinoga_6002__0: THREE.Mesh
    trinoga_7002__0: THREE.Mesh
    Cube061__0: THREE.Mesh
    Cube062__0: THREE.Mesh
    Cube063__0: THREE.Mesh
    Cube064__0: THREE.Mesh
    Cylinder015__0: THREE.Mesh
    main_korpus__0: THREE.Mesh
    small_piano_black_buttons__0: THREE.Mesh
    small_piano_white_buttons_p__0: THREE.Mesh
    wire_pedal__0: THREE.Mesh
    wire_piano__0: THREE.Mesh
    _rootJoint: THREE.Bone
    _rootJoint_1: THREE.Bone
    _rootJoint_2: THREE.Bone
    _rootJoint_3: THREE.Bone
    _rootJoint_4: THREE.Bone
  }
  materials: {
    Body: THREE.MeshStandardMaterial
    Hair: THREE.MeshStandardMaterial
    ['NONE-DC_Shell__0']: THREE.MeshStandardMaterial
    ['08_-_Default']: THREE.MeshStandardMaterial
    ['07_-_Default']: THREE.MeshStandardMaterial
    ['02_-_Default']: THREE.MeshStandardMaterial
    ['01_-_Default']: THREE.MeshStandardMaterial
    ['03_-_Default']: THREE.MeshStandardMaterial
    ['04_-_Default']: THREE.MeshStandardMaterial
    ['06_-_Default']: THREE.MeshStandardMaterial
    ['05_-_Default']: THREE.MeshStandardMaterial
    Material_25: THREE.MeshStandardMaterial
    Material_26: THREE.MeshStandardMaterial
    Material_27: THREE.MeshStandardMaterial
    Material_28: THREE.MeshStandardMaterial
    ['09_-_Default']: THREE.MeshStandardMaterial
    Material_29: THREE.MeshStandardMaterial
    Material_30: THREE.MeshStandardMaterial
    Material_31: THREE.MeshStandardMaterial
    Material_32: THREE.MeshStandardMaterial
    Material_33: THREE.MeshStandardMaterial
    zelezo: THREE.MeshStandardMaterial
    ['Material.005']: THREE.MeshStandardMaterial
    chrome: THREE.MeshStandardMaterial
    ['wire_115115115.001']: THREE.MeshStandardMaterial
    ['Material.002']: THREE.MeshStandardMaterial
    ['Material.006']: THREE.MeshStandardMaterial
    rfek: THREE.MeshStandardMaterial
    material: THREE.MeshStandardMaterial
    ['Material.009']: THREE.MeshStandardMaterial
    spodek: THREE.MeshStandardMaterial
    ['Material.018']: THREE.MeshStandardMaterial
    ['Material.003']: THREE.MeshStandardMaterial
    lapka: THREE.MeshStandardMaterial
    ['Material.004']: THREE.MeshStandardMaterial
    None: THREE.MeshStandardMaterial
    ['Material.013']: THREE.MeshStandardMaterial
    ['Material.015']: THREE.MeshStandardMaterial
    ['Material.012']: THREE.MeshStandardMaterial
    ['Material.019']: THREE.MeshStandardMaterial
    ['Material.011']: THREE.MeshStandardMaterial
    ['Material.017']: THREE.MeshStandardMaterial
    ['Material.007']: THREE.MeshStandardMaterial
    ['Material.001']: THREE.MeshStandardMaterial
    ['Material.008']: THREE.MeshStandardMaterial
    ['Material.016']: THREE.MeshStandardMaterial
    ['Material.010']: THREE.MeshStandardMaterial
    ['Material.014']: THREE.MeshStandardMaterial
    Material_34: THREE.MeshStandardMaterial
  }
}

type ActionName = 'Take 001'
type GLTFActions = Record<ActionName, THREE.AnimationAction>

export default function Band({ position, setPosition, ...props }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/band-transformed.glb`

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult
  // @ts-ignore
  const { actions } = useAnimations<GLTFActions>(animations, group)

  // @ts-ignore
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => {
    mixer.clipAction(animations[0], group.current).setDuration(10);
    mixer.clipAction(animations[0], group.current).play();
  }, [animations])

  useFrame((scene, delta) => {
    mixer?.update(delta)
  });


  return (
    <_MovableBase position={position} setPosition={setPosition}>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group name="blondy" position={[-1.22, 36.38, 10.5]} rotation={[-Math.PI / 2, 0, 0]}>
            <group name="H_DDS_HighRes" position={[1.22, -1.21, -36.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_L_eye" position={[0.03, -4.77, 26.23]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_L_gland" position={[1.91, -5.11, 26.14]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_R_eye" position={[0.03, -4.77, 26.23]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_R_gland" position={[0.52, -5.11, 26.14]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_TeethDown" position={[1.21, -4.37, 23.2]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_TeethUp" position={[1.21, -3.89, 23.39]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_wig" position={[1.22, -0.08, -36.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="Lights" position={[1.22, -7.83, -24.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
              <group name="spotLight1" position={[32.25, 154.38, 68.05]} rotation={[1.07, 0.13, -0.23]} scale={9.19}>
                <group name="Object_7" rotation={[Math.PI / 2, 0, 0]}>
                  <group name="Object_8" />
                </group>
              </group>
              <group name="spotLight2" position={[-88.09, 100.67, 71.04]} rotation={[-0.09, -0.77, -0.06]} scale={9.19} />
              <group name="spotLight3" position={[81.92, 98.32, -86.03]} rotation={[-3.09, 0.66, 3.11]} scale={9.19} />
              <group name="spotLight4" position={[-44.22, 104.53, -75.84]} rotation={[-3, -0.5, -3.07]} scale={9.19} />
            </group>
            <group name="master" position={[1.22, -0.08, -36.38]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
              <group name="Reference">
                <group name="Object_22">
                  <primitive object={nodes._rootJoint} />
                  <group name="Object_24" scale={0.39} />
                  <group name="Object_26" scale={0.39} />
                  <group name="Object_28" scale={0.39} />
                  <group name="Object_30" scale={0.39} />
                  <group name="Object_32" scale={0.39} />
                  <group name="Object_34" scale={0.39} />
                  <group name="Object_36" scale={0.39} />
                  <group name="Object_38" scale={0.39} />
                  <skinnedMesh name="Object_25" geometry={nodes.Object_25.geometry} material={materials.Body} skeleton={nodes.Object_25.skeleton} />
                  <skinnedMesh name="Object_27" geometry={nodes.Object_27.geometry} material={materials.Body} skeleton={nodes.Object_27.skeleton} />
                  <skinnedMesh name="Object_29" geometry={nodes.Object_29.geometry} material={materials.Body} skeleton={nodes.Object_29.skeleton} />
                  <skinnedMesh name="Object_31" geometry={nodes.Object_31.geometry} material={materials.Body} skeleton={nodes.Object_31.skeleton} />
                  <skinnedMesh name="Object_33" geometry={nodes.Object_33.geometry} material={materials.Body} skeleton={nodes.Object_33.skeleton} />
                  <skinnedMesh name="Object_35" geometry={nodes.Object_35.geometry} material={materials.Body} skeleton={nodes.Object_35.skeleton} />
                  <skinnedMesh name="Object_37" geometry={nodes.Object_37.geometry} material={materials.Body} skeleton={nodes.Object_37.skeleton} />
                  <skinnedMesh name="Object_39" geometry={nodes.Object_39.geometry} material={materials.Hair} skeleton={nodes.Object_39.skeleton} />
                </group>
              </group>
            </group>
          </group>
          <group name="solo_guitar" position={[45.87, 51.89, -9.56]} rotation={[-Math.PI / 2, 0, 0]}>
            <group name="ASTC4333136" position={[-6.4, 13.95, -31.98]} rotation={[Math.PI / 2, -0.23, 0.22]} scale={0.04}>
              <group name="ASTC4333136001">
                <group name="_" position={[-152.13, 266.19, 438.81]}>
                  <group name="Gitara_F_solid_model">
                    <group name="Gitara_F_solid_model001">
                      <group name="Gitara_F_solid_model002">
                        <group name="NONE">
                          <group name="Object_240">
                            <group name="NONE001">
                              <group name="NONE-DC_Shell">
                                <mesh name="NONE-DC_Shell__0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                              <group name="NONE-DC_Shell001">
                                <mesh name="NONE-DC_Shell__0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0001'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                              <group name="NONE-DC_Shell004">
                                <mesh name="NONE-DC_Shell__0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0002'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                              <group name="NONE-DC_Shell005">
                                <mesh name="NONE-DC_Shell__0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0003'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                              <group name="NONE-DC_Shell006">
                                <mesh name="NONE-DC_Shell__0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0004'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                              <group name="NONE-DC_Shell007">
                                <mesh name="NONE-DC_Shell__0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0005'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                              <group name="NONE002" position={[152.13, -266.19, -438.81]}>
                                <group name="NONE003">
                                  <group name="NONE-DC_Shell002">
                                    <mesh name="NONE-DC_Shell_08_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_08_-_Default_0'].geometry} material={materials['08_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell003">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                </group>
                              </group>
                              <group name="NONE004" position={[152.13, -266.19, -438.81]}>
                                <group name="NONE005">
                                  <group name="NONE-DC_Shell008">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0001'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell009">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0002'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell010">
                                    <mesh name="NONE-DC_Shell__0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0006'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                </group>
                              </group>
                              <group name="NONE006" position={[152.13, -266.19, -438.81]}>
                                <group name="NONE007">
                                  <group name="NONE-DC_Shell011">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0003'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell012">
                                    <mesh name="NONE-DC_Shell__0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0007'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell013">
                                    <mesh name="NONE-DC_Shell__0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0008'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                </group>
                              </group>
                              <group name="NONE008" position={[152.13, -266.19, -438.81]}>
                                <group name="NONE-DC_Shell014">
                                  <mesh name="NONE-DC_Shell_02_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0'].geometry} material={materials['02_-_Default']} />
                                </group>
                              </group>
                              <group name="NONE009" position={[152.13, -266.19, -438.81]}>
                                <group name="NONE010">
                                  <group name="NONE-DC_Shell015">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0001'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell016">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0002'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell017">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0003'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell018">
                                    <mesh name="NONE-DC_Shell_01_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_01_-_Default_0'].geometry} material={materials['01_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell019">
                                    <mesh name="NONE-DC_Shell_01_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_01_-_Default_0001'].geometry} material={materials['01_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell020">
                                    <mesh name="NONE-DC_Shell_01_-_Default_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_01_-_Default_0002'].geometry} material={materials['01_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_001" position={[235.96, 266.17, 437.81]} rotation={[Math.PI / 2, -1.57, 0]}>
                  <group name="Part2_gherson">
                    <group name="Part2_gherson001">
                      <group name="Part2_gherson002">
                        <group name="NONE011">
                          <group name="Object_298">
                            <group name="CirPattern1">
                              <group name="CirPattern1-DC_Shell">
                                <mesh name="CirPattern1-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['CirPattern1-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Cut-Extrude10">
                              <group name="NONE014" position={[266.18, -437.81, 235.95]} rotation={[-1.57, 0, 1.57]}>
                                <group name="NONE015">
                                  <group name="NONE-DC_Shell052">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0012" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0012'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell053">
                                    <mesh name="NONE-DC_Shell_03_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_03_-_Default_0001'].geometry} material={materials['03_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                            <group name="Fillet17">
                              <group name="Fillet17-DC_Shell">
                                <mesh name="Fillet17-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Fillet17-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Split_Line1">
                              <group name="NONE012" position={[266.18, -437.81, 235.95]} rotation={[-1.57, 0, 1.57]}>
                                <group name="NONE013">
                                  <group name="NONE-DC_Shell021">
                                    <mesh name="NONE-DC_Shell_03_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_03_-_Default_0'].geometry} material={materials['03_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell022">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0004'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell023">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0005'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell024">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0006'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell025">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0007'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell026">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0008'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell027">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0009'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell028">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0010" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0010'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell029">
                                    <mesh name="NONE-DC_Shell_02_-_Default_0011" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_02_-_Default_0011'].geometry} material={materials['02_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell030">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell031">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0001'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell032">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0002'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell033">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0003'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell034">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0004'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell035">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0005'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell036">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0006'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell037">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0007'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell038">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0008'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell039">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0009'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell040">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0010" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0010'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell041">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0011" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0011'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell042">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0012" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0012'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell043">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0013" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0013'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell044">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0014" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0014'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell045">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0015" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0015'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell046">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0016" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0016'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell047">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0017" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0017'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell048">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0018" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0018'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell049">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0019" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0019'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell050">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0020" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0020'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell051">
                                    <mesh name="NONE-DC_Shell_04_-_Default_0021" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_04_-_Default_0021'].geometry} material={materials['04_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_002" position={[87.6, 265.96, 447.77]} rotation={[Math.PI / 2, 1.57, 0]}>
                  <group name="Part2">
                    <group name="Part2001">
                      <group name="Part2002">
                        <group name="NONE016">
                          <group name="Object_382">
                            <group name="Dome1">
                              <group name="NONE017" position={[-265.96, -447.77, -87.6]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
                                <group name="NONE018">
                                  <group name="NONE-DC_Shell054">
                                    <mesh name="NONE-DC_Shell__0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0009'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell055">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell056">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0004'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell057">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0001'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell058">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0002'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell059">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0003'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell060">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0004'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell061">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0005'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_003" position={[183.6, 265.96, 445.31]} rotation={[Math.PI / 2, -Math.PI / 2, 0]}>
                  <group name="Part2003">
                    <group name="Part2004">
                      <group name="Part2005">
                        <group name="NONE019">
                          <group name="Object_407">
                            <group name="Dome1001">
                              <group name="NONE020" position={[-265.96, -447.77, -87.6]} rotation={[-Math.PI / 2, 0, -Math.PI / 2]}>
                                <group name="NONE021">
                                  <group name="NONE-DC_Shell062">
                                    <mesh name="NONE-DC_Shell__0010" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0010'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell063">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0005'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell064">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0006'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell065">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0006'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell066">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0007'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell067">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0008'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell068">
                                    <mesh name="NONE-DC_Shell_06_-_Default_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_06_-_Default_0009'].geometry} material={materials['06_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell069">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0007'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_004" position={[-33.74, 267.51, 447.69]} rotation={[2.94, -1.52, 1.37]}>
                  <group name="Part3">
                    <group name="Part3001">
                      <group name="Part3002">
                        <group name="NONE022">
                          <group name="Object_432">
                            <group name="Fillet4">
                              <group name="NONE023" position={[267.59, -448.84, -8.14]} rotation={[-1.62, 0, 1.56]}>
                                <group name="NONE024">
                                  <group name="NONE-DC_Shell070">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0008'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell071">
                                    <mesh name="NONE-DC_Shell_07_-_Default_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_07_-_Default_0009'].geometry} material={materials['07_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell072">
                                    <mesh name="NONE-DC_Shell__0011" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0011'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell073">
                                    <mesh name="NONE-DC_Shell__0012" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0012'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell074">
                                    <mesh name="NONE-DC_Shell__0013" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0013'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell075">
                                    <mesh name="NONE-DC_Shell__0014" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0014'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell076">
                                    <mesh name="NONE-DC_Shell__0015" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0015'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell077">
                                    <mesh name="NONE-DC_Shell__0016" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0016'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell078">
                                    <mesh name="NONE-DC_Shell__0017" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0017'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell079">
                                    <mesh name="NONE-DC_Shell__0018" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0018'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell080">
                                    <mesh name="NONE-DC_Shell__0019" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0019'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell081">
                                    <mesh name="NONE-DC_Shell__0020" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0020'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell082">
                                    <mesh name="NONE-DC_Shell__0021" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0021'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell083">
                                    <mesh name="NONE-DC_Shell__0022" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0022'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell084">
                                    <mesh name="NONE-DC_Shell__0023" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0023'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell085">
                                    <mesh name="NONE-DC_Shell__0024" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0024'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell086">
                                    <mesh name="NONE-DC_Shell__0025" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0025'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell087">
                                    <mesh name="NONE-DC_Shell__0026" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0026'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell088">
                                    <mesh name="NONE-DC_Shell__0027" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0027'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_005" position={[241.1, 366.96, 441.25]} rotation={[1.48, -0.73, 0]}>
                  <group name="Part4">
                    <group name="Part4001">
                      <group name="Part4002">
                        <group name="NONE025">
                          <group name="Object_479">
                            <group name="CirPattern3">
                              <group name="CirPattern3-DC_Shell">
                                <mesh name="CirPattern3-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['CirPattern3-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Cut-Extrude2">
                              <group name="Cut-Extrude2-DC_Shell">
                                <mesh name="Cut-Extrude2-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Cut-Extrude2-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Extrude-Thin1">
                              <group name="Extrude-Thin1-DC_Shell">
                                <mesh name="Extrude-Thin1-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Extrude-Thin1-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Revolve1">
                              <group name="Revolve1-DC_Shell">
                                <mesh name="Revolve1-DC_Shell_05_-_Default_0" castShadow receiveShadow geometry={nodes['Revolve1-DC_Shell_05_-_Default_0'].geometry} material={materials['05_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_006" position={[-67.57, 200.95, 443.88]} rotation={[-1.5, 0.25, 3.02]}>
                  <group name="Part7">
                    <group name="Part7001">
                      <group name="Part7002">
                        <group name="NONE026">
                          <group name="Object_497">
                            <group name="Scale11">
                              <group name="Scale11-DC_Shell">
                                <mesh name="Scale11-DC_Shell__0" castShadow receiveShadow geometry={nodes['Scale11-DC_Shell__0'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                            </group>
                            <group name="Split_Line1001">
                              <group name="NONE027" position={[-69.88, -440.65, -207.16]} rotation={[-1.5, -0.1, -2.88]}>
                                <group name="NONE028">
                                  <group name="NONE-DC_Shell089">
                                    <mesh name="NONE-DC_Shell__0028" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0028'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell090">
                                    <mesh name="NONE-DC_Shell__0029" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0029'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell091">
                                    <mesh name="NONE-DC_Shell__0030" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0030'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell092">
                                    <mesh name="NONE-DC_Shell__0031" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0031'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell093">
                                    <mesh name="NONE-DC_Shell__0032" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0032'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell094">
                                    <mesh name="NONE-DC_Shell__0033" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0033'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell095">
                                    <mesh name="NONE-DC_Shell__0034" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0034'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell096">
                                    <mesh name="NONE-DC_Shell__0035" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0035'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell097">
                                    <mesh name="NONE-DC_Shell__0036" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0036'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell098">
                                    <mesh name="NONE-DC_Shell__0037" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0037'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell099">
                                    <mesh name="NONE-DC_Shell__0038" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0038'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell100">
                                    <mesh name="NONE-DC_Shell__0039" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0039'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell101">
                                    <mesh name="NONE-DC_Shell__0040" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0040'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell102">
                                    <mesh name="NONE-DC_Shell__0041" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0041'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell103">
                                    <mesh name="NONE-DC_Shell__0042" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0042'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell104">
                                    <mesh name="NONE-DC_Shell__0043" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0043'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell105">
                                    <mesh name="NONE-DC_Shell__0044" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0044'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell106">
                                    <mesh name="NONE-DC_Shell__0045" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0045'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell107">
                                    <mesh name="NONE-DC_Shell__0046" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0046'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell108">
                                    <mesh name="NONE-DC_Shell__0047" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0047'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell109">
                                    <mesh name="NONE-DC_Shell__0048" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0048'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell110">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell111">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0001'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_007" position={[-42.03, 149.92, 443.35]} rotation={[1.65, 0.15, 0.09]}>
                  <group name="Part7003">
                    <group name="Part7004">
                      <group name="Part7005">
                        <group name="NONE029">
                          <group name="Object_555">
                            <group name="Scale11001">
                              <group name="Scale11-DC_Shell001">
                                <mesh name="Scale11-DC_Shell__0001" castShadow receiveShadow geometry={nodes['Scale11-DC_Shell__0001'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                            </group>
                            <group name="Split_Line1002">
                              <group name="NONE030" position={[-69.88, -440.65, -207.16]} rotation={[-1.5, -0.1, -2.88]}>
                                <group name="NONE031">
                                  <group name="NONE-DC_Shell112">
                                    <mesh name="NONE-DC_Shell__0049" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0049'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell113">
                                    <mesh name="NONE-DC_Shell__0050" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0050'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell114">
                                    <mesh name="NONE-DC_Shell__0051" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0051'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell115">
                                    <mesh name="NONE-DC_Shell__0052" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0052'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell116">
                                    <mesh name="NONE-DC_Shell__0053" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0053'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell117">
                                    <mesh name="NONE-DC_Shell__0054" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0054'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell118">
                                    <mesh name="NONE-DC_Shell__0055" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0055'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell119">
                                    <mesh name="NONE-DC_Shell__0056" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0056'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell120">
                                    <mesh name="NONE-DC_Shell__0057" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0057'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell121">
                                    <mesh name="NONE-DC_Shell__0058" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0058'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell122">
                                    <mesh name="NONE-DC_Shell__0059" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0059'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell123">
                                    <mesh name="NONE-DC_Shell__0060" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0060'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell124">
                                    <mesh name="NONE-DC_Shell__0061" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0061'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell125">
                                    <mesh name="NONE-DC_Shell__0062" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0062'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell126">
                                    <mesh name="NONE-DC_Shell__0063" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0063'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell127">
                                    <mesh name="NONE-DC_Shell__0064" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0064'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell128">
                                    <mesh name="NONE-DC_Shell__0065" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0065'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell129">
                                    <mesh name="NONE-DC_Shell__0066" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0066'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell130">
                                    <mesh name="NONE-DC_Shell__0067" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0067'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell131">
                                    <mesh name="NONE-DC_Shell__0068" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0068'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell132">
                                    <mesh name="NONE-DC_Shell__0069" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0069'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell133">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0002'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell134">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0003'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_008" position={[-9.26, 201.16, 448.25]} rotation={[1.6, -0.01, 0.05]}>
                  <group name="Part7006">
                    <group name="Part7007">
                      <group name="Part7008">
                        <group name="NONE032">
                          <group name="Object_613">
                            <group name="Scale11002">
                              <group name="Scale11-DC_Shell002">
                                <mesh name="Scale11-DC_Shell__0002" castShadow receiveShadow geometry={nodes['Scale11-DC_Shell__0002'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                            </group>
                            <group name="Split_Line1003">
                              <group name="NONE033" position={[-69.88, -440.65, -207.16]} rotation={[-1.5, -0.1, -2.88]}>
                                <group name="NONE034">
                                  <group name="NONE-DC_Shell135">
                                    <mesh name="NONE-DC_Shell__0070" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0070'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell136">
                                    <mesh name="NONE-DC_Shell__0071" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0071'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell137">
                                    <mesh name="NONE-DC_Shell__0072" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0072'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell138">
                                    <mesh name="NONE-DC_Shell__0073" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0073'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell139">
                                    <mesh name="NONE-DC_Shell__0074" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0074'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell140">
                                    <mesh name="NONE-DC_Shell__0075" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0075'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell141">
                                    <mesh name="NONE-DC_Shell__0076" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0076'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell142">
                                    <mesh name="NONE-DC_Shell__0077" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0077'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell143">
                                    <mesh name="NONE-DC_Shell__0078" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0078'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell144">
                                    <mesh name="NONE-DC_Shell__0079" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0079'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell145">
                                    <mesh name="NONE-DC_Shell__0080" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0080'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell146">
                                    <mesh name="NONE-DC_Shell__0081" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0081'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell147">
                                    <mesh name="NONE-DC_Shell__0082" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0082'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell148">
                                    <mesh name="NONE-DC_Shell__0083" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0083'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell149">
                                    <mesh name="NONE-DC_Shell__0084" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0084'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell150">
                                    <mesh name="NONE-DC_Shell__0085" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0085'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell151">
                                    <mesh name="NONE-DC_Shell__0086" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0086'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell152">
                                    <mesh name="NONE-DC_Shell__0087" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0087'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell153">
                                    <mesh name="NONE-DC_Shell__0088" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0088'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell154">
                                    <mesh name="NONE-DC_Shell__0089" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0089'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell155">
                                    <mesh name="NONE-DC_Shell__0090" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0090'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell156">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0004'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell157">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0005'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_009" position={[15.32, 150.29, 445.62]} rotation={[-1.49, 0.68, -3.13]}>
                  <group name="Part7009">
                    <group name="Part7010">
                      <group name="Part7011">
                        <group name="NONE035">
                          <group name="Object_671">
                            <group name="Scale11003">
                              <group name="Scale11-DC_Shell003">
                                <mesh name="Scale11-DC_Shell__0003" castShadow receiveShadow geometry={nodes['Scale11-DC_Shell__0003'].geometry} material={materials['NONE-DC_Shell__0']} />
                              </group>
                            </group>
                            <group name="Split_Line1004">
                              <group name="NONE036" position={[-69.88, -440.65, -207.16]} rotation={[-1.5, -0.1, -2.88]}>
                                <group name="NONE037">
                                  <group name="NONE-DC_Shell158">
                                    <mesh name="NONE-DC_Shell__0091" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0091'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell159">
                                    <mesh name="NONE-DC_Shell__0092" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0092'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell160">
                                    <mesh name="NONE-DC_Shell__0093" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0093'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell161">
                                    <mesh name="NONE-DC_Shell__0094" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0094'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell162">
                                    <mesh name="NONE-DC_Shell__0095" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0095'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell163">
                                    <mesh name="NONE-DC_Shell__0096" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0096'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell164">
                                    <mesh name="NONE-DC_Shell__0097" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0097'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell165">
                                    <mesh name="NONE-DC_Shell__0098" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0098'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell166">
                                    <mesh name="NONE-DC_Shell__0099" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0099'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell167">
                                    <mesh name="NONE-DC_Shell__0100" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0100'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell168">
                                    <mesh name="NONE-DC_Shell__0101" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0101'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell169">
                                    <mesh name="NONE-DC_Shell__0102" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0102'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell170">
                                    <mesh name="NONE-DC_Shell__0103" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0103'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell171">
                                    <mesh name="NONE-DC_Shell__0104" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0104'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell172">
                                    <mesh name="NONE-DC_Shell__0105" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0105'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell173">
                                    <mesh name="NONE-DC_Shell__0106" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0106'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell174">
                                    <mesh name="NONE-DC_Shell__0107" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0107'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell175">
                                    <mesh name="NONE-DC_Shell__0108" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0108'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell176">
                                    <mesh name="NONE-DC_Shell__0109" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0109'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell177">
                                    <mesh name="NONE-DC_Shell__0110" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0110'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell178">
                                    <mesh name="NONE-DC_Shell__0111" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0111'].geometry} material={materials['NONE-DC_Shell__0']} />
                                  </group>
                                  <group name="NONE-DC_Shell179">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0006'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                  <group name="NONE-DC_Shell180">
                                    <mesh name="NONE-DC_Shell_05_-_Default_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_05_-_Default_0007'].geometry} material={materials['05_-_Default']} />
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_010" position={[777.34, 243.23, 423.39]} rotation={[-Math.PI, -0.09, -0.03]}>
                  <group name="Part11">
                    <group name="Part11001">
                      <group name="Part11002">
                        <group name="NONE038">
                          <group name="Object_729">
                            <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1">
                              <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell">
                                <mesh name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_011" position={[774.88, 242.12, 417.92]} rotation={[1.57, 0.03, 2.65]}>
                  <group name="Part12">
                    <group name="Part12001">
                      <group name="Part12002">
                        <group name="NONE039">
                          <group name="Object_738">
                            <group name="Fillet1">
                              <group name="Fillet1-DC_Shell">
                                <mesh name="Fillet1-DC_Shell_05_-_Default_0" castShadow receiveShadow geometry={nodes['Fillet1-DC_Shell_05_-_Default_0'].geometry} material={materials['05_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_012" position={[769.2, 242.99, 422.11]} rotation={[-1.54, -0.32, -3.05]}>
                  <group name="Part13">
                    <group name="Part13001">
                      <group name="Part13002">
                        <group name="NONE040">
                          <group name="Object_747">
                            <group name="Fillet2">
                              <group name="Fillet2-DC_Shell">
                                <mesh name="Fillet2-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Fillet2-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_013" position={[822.14, 244.61, 419.41]} rotation={[-Math.PI, -0.09, -0.03]}>
                  <group name="Part11003">
                    <group name="Part11004">
                      <group name="Part11005">
                        <group name="NONE041">
                          <group name="Object_756">
                            <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1001">
                              <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell001">
                                <mesh name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau" castShadow receiveShadow geometry={nodes['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_014" position={[819.68, 243.49, 413.93]} rotation={[1.57, 0.03, 1.07]}>
                  <group name="Part12003">
                    <group name="Part12004">
                      <group name="Part12005">
                        <group name="NONE042">
                          <group name="Object_765">
                            <group name="Fillet1001">
                              <group name="Fillet1-DC_Shell001">
                                <mesh name="Fillet1-DC_Shell_05_-_Default_0001" castShadow receiveShadow geometry={nodes['Fillet1-DC_Shell_05_-_Default_0001'].geometry} material={materials['05_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_015" position={[814, 244.36, 418.13]} rotation={[-1.54, -0.32, -3.05]}>
                  <group name="Part13003">
                    <group name="Part13004">
                      <group name="Part13005">
                        <group name="NONE043">
                          <group name="Object_774">
                            <group name="Fillet2001">
                              <group name="Fillet2-DC_Shell001">
                                <mesh name="Fillet2-DC_Shell_07_-_Default_0001" castShadow receiveShadow geometry={nodes['Fillet2-DC_Shell_07_-_Default_0001'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_016" position={[858.77, 242.14, 414.15]} rotation={[1.56, -0.09, -0.09]}>
                  <group name="Part13006">
                    <group name="Part13007">
                      <group name="Part13008">
                        <group name="NONE044">
                          <group name="Object_783">
                            <group name="Fillet2002">
                              <group name="Fillet2-DC_Shell002">
                                <mesh name="Fillet2-DC_Shell_07_-_Default_0002" castShadow receiveShadow geometry={nodes['Fillet2-DC_Shell_07_-_Default_0002'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_017" position={[866.38, 245.96, 415.48]} rotation={[-Math.PI, -0.09, -0.03]}>
                  <group name="Part11006">
                    <group name="Part11007">
                      <group name="Part11008">
                        <group name="NONE045">
                          <group name="Object_792">
                            <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1002">
                              <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell002">
                                <mesh name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau001" castShadow receiveShadow geometry={nodes['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau001'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_018" position={[863.92, 244.85, 410]} rotation={[1.57, 0.03, -1.33]}>
                  <group name="Part12006">
                    <group name="Part12007">
                      <group name="Part12008">
                        <group name="NONE046">
                          <group name="Object_801">
                            <group name="Fillet1002">
                              <group name="Fillet1-DC_Shell002">
                                <mesh name="Fillet1-DC_Shell_05_-_Default_0002" castShadow receiveShadow geometry={nodes['Fillet1-DC_Shell_05_-_Default_0002'].geometry} material={materials['05_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_019" position={[769.2, 289.4, 422.11]} rotation={[-1.6, 0.32, -3.05]}>
                  <group name="Part13009">
                    <group name="Part13010">
                      <group name="Part13011">
                        <group name="NONE047">
                          <group name="Object_810">
                            <group name="Fillet2003">
                              <group name="Fillet2-DC_Shell003">
                                <mesh name="Fillet2-DC_Shell_07_-_Default_0003" castShadow receiveShadow geometry={nodes['Fillet2-DC_Shell_07_-_Default_0003'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_020" position={[814, 288.02, 418.13]} rotation={[-1.6, 0.32, -3.05]}>
                  <group name="Part13012">
                    <group name="Part13013">
                      <group name="Part13014">
                        <group name="NONE048">
                          <group name="Object_819">
                            <group name="Fillet2004">
                              <group name="Fillet2-DC_Shell004">
                                <mesh name="Fillet2-DC_Shell_07_-_Default_0004" castShadow receiveShadow geometry={nodes['Fillet2-DC_Shell_07_-_Default_0004'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_021" position={[858.77, 290.25, 414.15]} rotation={[1.58, 0.09, -0.09]}>
                  <group name="Part13015">
                    <group name="Part13016">
                      <group name="Part13017">
                        <group name="NONE049">
                          <group name="Object_828">
                            <group name="Fillet2005">
                              <group name="Fillet2-DC_Shell005">
                                <mesh name="Fillet2-DC_Shell_07_-_Default_0005" castShadow receiveShadow geometry={nodes['Fillet2-DC_Shell_07_-_Default_0005'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_022" position={[822.14, 287.78, 419.41]} rotation={[Math.PI, -0.09, -3.11]}>
                  <group name="Part11009">
                    <group name="Part11010">
                      <group name="Part11011">
                        <group name="NONE050">
                          <group name="Object_837">
                            <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1003">
                              <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell003">
                                <mesh name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau002" castShadow receiveShadow geometry={nodes['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau002'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_023" position={[777.34, 289.15, 423.39]} rotation={[Math.PI, -0.09, -3.11]}>
                  <group name="Part11012">
                    <group name="Part11013">
                      <group name="Part11014">
                        <group name="NONE051">
                          <group name="Object_846">
                            <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1004">
                              <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell004">
                                <mesh name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau003" castShadow receiveShadow geometry={nodes['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau003'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_024" position={[866.38, 286.42, 415.48]} rotation={[Math.PI, -0.09, -3.11]}>
                  <group name="Part11015">
                    <group name="Part11016">
                      <group name="Part11017">
                        <group name="NONE052">
                          <group name="Object_855">
                            <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1005">
                              <group name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell005">
                                <mesh name="CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau004" castShadow receiveShadow geometry={nodes['CSK_for_M2_Countersunk_Flat_Head_Screw1-DC_Shell_07_-_Defau004'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_025" position={[867.91, 287.76, 409.65]} rotation={[-1.57, 0.03, -2.61]}>
                  <group name="Part12009">
                    <group name="Part12010">
                      <group name="Part12011">
                        <group name="NONE053">
                          <group name="Object_864">
                            <group name="Fillet1003">
                              <group name="Fillet1-DC_Shell003">
                                <mesh name="Fillet1-DC_Shell_05_-_Default_0003" castShadow receiveShadow geometry={nodes['Fillet1-DC_Shell_05_-_Default_0003'].geometry} material={materials['05_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_026" position={[823.67, 289.12, 413.58]} rotation={[-1.57, 0.03, 0.03]}>
                  <group name="Part12012">
                    <group name="Part12013">
                      <group name="Part12014">
                        <group name="NONE054">
                          <group name="Object_873">
                            <group name="Fillet1004">
                              <group name="Fillet1-DC_Shell004">
                                <mesh name="Fillet1-DC_Shell_05_-_Default_0004" castShadow receiveShadow geometry={nodes['Fillet1-DC_Shell_05_-_Default_0004'].geometry} material={materials['05_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_027" position={[778.87, 290.49, 417.56]} rotation={[-1.57, 0.03, 0.03]}>
                  <group name="Part12015">
                    <group name="Part12016">
                      <group name="Part12017">
                        <group name="NONE055">
                          <group name="Object_882">
                            <group name="Fillet1005">
                              <group name="Fillet1-DC_Shell005">
                                <mesh name="Fillet1-DC_Shell_05_-_Default_0005" castShadow receiveShadow geometry={nodes['Fillet1-DC_Shell_05_-_Default_0005'].geometry} material={materials['05_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_028" position={[55.22, 246.74, 453.31]} rotation={[Math.PI / 2, 1.57, 0]}>
                  <group name="gherson_guitar_bridge">
                    <group name="gherson_guitar_bridge001">
                      <group name="_029" position={[45.96, 4.23, -2.87]} rotation={[-Math.PI / 2, 0.42, 0]}>
                        <group name="Part_10_gherson">
                          <group name="Part_10_gherson001">
                            <group name="Part_10_gherson002">
                              <group name="NONE056">
                                <group name="Object_894">
                                  <group name="Imported1">
                                    <group name="Imported1-DC_Shell">
                                      <mesh name="Imported1-DC_Shell__0" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_030" position={[3.56, 5.15, -2.87]} rotation={[-Math.PI / 2, 0.66, 0]}>
                        <group name="Part_10_gherson003">
                          <group name="Part_10_gherson004">
                            <group name="Part_10_gherson005">
                              <group name="NONE057">
                                <group name="Object_903">
                                  <group name="Imported1001">
                                    <group name="Imported1-DC_Shell001">
                                      <mesh name="Imported1-DC_Shell__0001" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0001'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_031" position={[19.46, -1.73, -12.62]}>
                        <group name="Part_08_gherson">
                          <group name="Part_08_gherson001">
                            <group name="Part_08_gherson002">
                              <group name="NONE058">
                                <group name="Object_912">
                                  <group name="Imported1002">
                                    <group name="Imported1-DC_Shell002">
                                      <mesh name="Imported1-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_032" position={[0.26, 10.5, -10.52]} rotation={[0, 0, 0.01]}>
                        <group name="Part_09_gherson">
                          <group name="Part_09_gherson001">
                            <group name="Part_09_gherson002">
                              <group name="NONE059">
                                <group name="Object_921">
                                  <group name="Imported1003">
                                    <group name="Imported1-DC_Shell003">
                                      <mesh name="Imported1-DC_Shell__0002" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0002'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_033" position={[35.36, 5.15, -2.87]} rotation={[-Math.PI / 2, 0.34, 0]}>
                        <group name="Part_10_gherson006">
                          <group name="Part_10_gherson007">
                            <group name="Part_10_gherson008">
                              <group name="NONE060">
                                <group name="Object_930">
                                  <group name="Imported1004">
                                    <group name="Imported1-DC_Shell004">
                                      <mesh name="Imported1-DC_Shell__0003" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0003'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_034" position={[14.16, 5.61, -2.87]} rotation={[-Math.PI / 2, -0.72, 0]}>
                        <group name="Part_10_gherson009">
                          <group name="Part_10_gherson010">
                            <group name="Part_10_gherson011">
                              <group name="NONE061">
                                <group name="Object_939">
                                  <group name="Imported1005">
                                    <group name="Imported1-DC_Shell005">
                                      <mesh name="Imported1-DC_Shell__0004" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0004'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_035" position={[32.25, 10.61, -13.54]} rotation={[0, 0, -0.03]}>
                        <group name="Part_09_gherson003">
                          <group name="Part_09_gherson004">
                            <group name="Part_09_gherson005">
                              <group name="NONE062">
                                <group name="Object_948">
                                  <group name="Imported1006">
                                    <group name="Imported1-DC_Shell006">
                                      <mesh name="Imported1-DC_Shell__0005" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0005'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_036" position={[42.71, 9.61, -16.61]}>
                        <group name="Part_09_gherson006">
                          <group name="Part_09_gherson007">
                            <group name="Part_09_gherson008">
                              <group name="NONE063">
                                <group name="Object_957">
                                  <group name="Imported1007">
                                    <group name="Imported1-DC_Shell007">
                                      <mesh name="Imported1-DC_Shell__0006" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0006'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_037" position={[-7.04, 4.23, -2.87]} rotation={[-Math.PI / 2, -0.92, 0]}>
                        <group name="Part_10_gherson012">
                          <group name="Part_10_gherson013">
                            <group name="Part_10_gherson014">
                              <group name="NONE064">
                                <group name="Object_966">
                                  <group name="Imported1008">
                                    <group name="Imported1-DC_Shell008">
                                      <mesh name="Imported1-DC_Shell__0007" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0007'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_038" position={[-10.34, 9.58, -9.36]} rotation={[0, 0, 0.01]}>
                        <group name="Part_09_gherson009">
                          <group name="Part_09_gherson010">
                            <group name="Part_09_gherson011">
                              <group name="NONE065">
                                <group name="Object_975">
                                  <group name="Imported1009">
                                    <group name="Imported1-DC_Shell009">
                                      <mesh name="Imported1-DC_Shell__0008" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0008'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_039" position={[10.86, 10.96, -12.26]} rotation={[0, 0, 0.01]}>
                        <group name="Part_09_gherson012">
                          <group name="Part_09_gherson013">
                            <group name="Part_09_gherson014">
                              <group name="NONE066">
                                <group name="Object_984">
                                  <group name="Imported1010">
                                    <group name="Imported1-DC_Shell010">
                                      <mesh name="Imported1-DC_Shell__0009" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0009'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_040" position={[24.76, 5.61, -2.87]} rotation={[-Math.PI / 2, 0.36, 0]}>
                        <group name="Part_10_gherson015">
                          <group name="Part_10_gherson016">
                            <group name="Part_10_gherson017">
                              <group name="NONE067">
                                <group name="Object_993">
                                  <group name="Imported1011">
                                    <group name="Imported1-DC_Shell011">
                                      <mesh name="Imported1-DC_Shell__0010" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0010'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                      <group name="_041" position={[21.54, 11, -12.44]} rotation={[0, 0, -0.01]}>
                        <group name="Part_09_gherson015">
                          <group name="Part_09_gherson016">
                            <group name="Part_09_gherson017">
                              <group name="NONE068">
                                <group name="Object_1002">
                                  <group name="Imported1012">
                                    <group name="Imported1-DC_Shell012">
                                      <mesh name="Imported1-DC_Shell__0011" castShadow receiveShadow geometry={nodes['Imported1-DC_Shell__0011'].geometry} material={materials['NONE-DC_Shell__0']} />
                                    </group>
                                  </group>
                                </group>
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
                <group name="_042" position={[-35.74, 267.48, 447.59]} rotation={[-0.2, 1.52, 1.77]}>
                  <group name="zice">
                    <group name="zice001">
                      <group name="zice002">
                        <group name="NONE069">
                          <group name="Object_1011">
                            <group name="Sweep1">
                              <group name="Sweep1-DC_Shell">
                                <mesh name="Sweep1-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Sweep1-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Sweep2">
                              <group name="Sweep2-DC_Shell">
                                <mesh name="Sweep2-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Sweep2-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Sweep3">
                              <group name="Sweep3-DC_Shell">
                                <mesh name="Sweep3-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Sweep3-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Sweep4">
                              <group name="Sweep4-DC_Shell">
                                <mesh name="Sweep4-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Sweep4-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Sweep5">
                              <group name="Sweep5-DC_Shell">
                                <mesh name="Sweep5-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Sweep5-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                            <group name="Sweep6">
                              <group name="Sweep6-DC_Shell">
                                <mesh name="Sweep6-DC_Shell_07_-_Default_0" castShadow receiveShadow geometry={nodes['Sweep6-DC_Shell_07_-_Default_0'].geometry} material={materials['07_-_Default']} />
                              </group>
                            </group>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
            <group name="Solo_guitar" position={[-2.13, 2.24, -18.58]}>
              <group name="H_DDS_HighRes001_1" position={[-0.91, 0.58, -33.31]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_L_eye001_1" position={[-2.28, -3.37, 34.36]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_L_gland001_1" position={[-0.04, -3.73, 34.33]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_R_eye001_1" position={[-2.28, -3.37, 34.36]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_R_gland001_1" position={[-1.77, -3.75, 34.35]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_TeethDown001_1" position={[-0.91, -2.97, 30.96]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_TeethUp001_1" position={[-0.91, -2.45, 31.17]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_wig001" position={[-0.91, 1.7, -33.31]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="Lights001_1" position={[-0.91, -6.05, -21.31]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
                <group name="spotLight005" position={[34.86, 169.35, 75.15]} rotation={[1.07, 0.13, -0.23]} scale={9.19}>
                  <group name="Object_130" rotation={[Math.PI / 2, 0, 0]}>
                    <group name="Object_131" />
                  </group>
                </group>
                <group name="spotLight006" position={[-95.23, 111.28, 78.38]} rotation={[-0.09, -0.77, -0.06]} scale={9.19} />
                <group name="spotLight007" position={[88.55, 108.74, -91.4]} rotation={[-3.09, 0.66, 3.11]} scale={9.19} />
                <group name="spotLight008" position={[-47.8, 115.46, -80.38]} rotation={[-3, -0.5, -3.07]} scale={9.19} />
              </group>
              <group name="master001_1" position={[-0.91, 1.7, -33.31]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
                <group name="Reference001">
                  <group name="Object_137">
                    <primitive object={nodes._rootJoint_1} />
                    <group name="Object_139" scale={0.39} />
                    <group name="Object_141" scale={0.39} />
                    <group name="Object_143" scale={0.39} />
                    <group name="Object_145" scale={0.39} />
                    <group name="Object_147" scale={0.39} />
                    <group name="Object_149" scale={0.39} />
                    <group name="Object_151" scale={0.39} />
                    <group name="Object_153" scale={0.39} />
                    <skinnedMesh name="Object_140" geometry={nodes.Object_140.geometry} material={materials.Material_25} skeleton={nodes.Object_140.skeleton} />
                    <skinnedMesh name="Object_142" geometry={nodes.Object_142.geometry} material={materials.Material_25} skeleton={nodes.Object_142.skeleton} />
                    <skinnedMesh name="Object_144" geometry={nodes.Object_144.geometry} material={materials.Material_25} skeleton={nodes.Object_144.skeleton} />
                    <skinnedMesh name="Object_146" geometry={nodes.Object_146.geometry} material={materials.Material_25} skeleton={nodes.Object_146.skeleton} />
                    <skinnedMesh name="Object_148" geometry={nodes.Object_148.geometry} material={materials.Material_25} skeleton={nodes.Object_148.skeleton} />
                    <skinnedMesh name="Object_150" geometry={nodes.Object_150.geometry} material={materials.Material_25} skeleton={nodes.Object_150.skeleton} />
                    <skinnedMesh name="Object_152" geometry={nodes.Object_152.geometry} material={materials.Material_25} skeleton={nodes.Object_152.skeleton} />
                    <skinnedMesh name="Object_154" geometry={nodes.Object_154.geometry} material={materials.Material_26} skeleton={nodes.Object_154.skeleton} />
                  </group>
                </group>
              </group>
            </group>
          </group>
          <group name="ritam_" position={[-30.62, 52.43, 4.44]} rotation={[-Math.PI / 2, 0, 0]}>
            <group name="guitar_" position={[-1.47, 2.89, -12.56]} rotation={[Math.PI / 2, -0.77, 0.6]} scale={0.04}>
              <group name="ASTFAF8564" position={[-422.09, -198.64, -206.96]}>
                <group name="ASTFAF8564001">
                  <group name="ASTFAF8564002">
                    <group name="NONE070">
                      <group name="NONE071">
                        <group name="NONE-DC_Shell181">
                          <mesh name="NONE-DC_Shell_Material_#27_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE072">
                        <group name="NONE-DC_Shell182">
                          <mesh name="NONE-DC_Shell_Material_#28_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE073">
                        <group name="NONE-DC_Shell183">
                          <mesh name="NONE-DC_Shell_Material_#28_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0001'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE074">
                        <group name="NONE-DC_Shell184">
                          <mesh name="NONE-DC_Shell_Material_#28_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0002'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE075">
                        <group name="NONE-DC_Shell185">
                          <mesh name="NONE-DC_Shell_Material_#28_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0003'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE076">
                        <group name="NONE077">
                          <group name="NONE-DC_Shell186">
                            <mesh name="NONE-DC_Shell_09_-_Default_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_09_-_Default_0'].geometry} material={materials['09_-_Default']} />
                          </group>
                          <group name="NONE-DC_Shell187">
                            <mesh name="NONE-DC_Shell_Material_#27_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0001'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell188">
                            <mesh name="NONE-DC_Shell_Material_#27_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0002'].geometry} material={materials.Material_27} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE078">
                        <group name="NONE-DC_Shell189">
                          <mesh name="NONE-DC_Shell_Material_#27_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0003'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE079">
                        <group name="NONE-DC_Shell190">
                          <mesh name="NONE-DC_Shell_09_-_Default_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_09_-_Default_0001'].geometry} material={materials['09_-_Default']} />
                        </group>
                      </group>
                      <group name="NONE080">
                        <group name="NONE-DC_Shell191">
                          <mesh name="NONE-DC_Shell_Material_#27_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0004'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE081">
                        <group name="NONE082">
                          <group name="NONE-DC_Shell192">
                            <mesh name="NONE-DC_Shell_Material_#28_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0004'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell193">
                            <mesh name="NONE-DC_Shell_Material_#28_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0005'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell194">
                            <mesh name="NONE-DC_Shell_Material_#27_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0005'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell195">
                            <mesh name="NONE-DC_Shell_Material_#27_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0006'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell196">
                            <mesh name="NONE-DC_Shell_Material_#27_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0007'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell197">
                            <mesh name="NONE-DC_Shell_Material_#28_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0006'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell198">
                            <mesh name="NONE-DC_Shell_Material_#28_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0007'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell199">
                            <mesh name="NONE-DC_Shell_Material_#28_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0008'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell200">
                            <mesh name="NONE-DC_Shell_Material_#28_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0009'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell201">
                            <mesh name="NONE-DC_Shell_Material_#28_0010" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0010'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell202">
                            <mesh name="NONE-DC_Shell_Material_#28_0011" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0011'].geometry} material={materials.Material_28} />
                          </group>
                          <group name="NONE-DC_Shell203">
                            <mesh name="NONE-DC_Shell__0112" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0112'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell204">
                            <mesh name="NONE-DC_Shell__0113" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0113'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell205">
                            <mesh name="NONE-DC_Shell__0114" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0114'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell206">
                            <mesh name="NONE-DC_Shell__0115" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0115'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell207">
                            <mesh name="NONE-DC_Shell__0116" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0116'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell208">
                            <mesh name="NONE-DC_Shell__0117" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0117'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell209">
                            <mesh name="NONE-DC_Shell__0118" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0118'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell210">
                            <mesh name="NONE-DC_Shell__0119" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0119'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE083">
                        <group name="NONE-DC_Shell211">
                          <mesh name="NONE-DC_Shell_Material_#28_0012" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0012'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE084">
                        <group name="NONE-DC_Shell212">
                          <mesh name="NONE-DC_Shell_Material_#28_0013" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0013'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE085">
                        <group name="NONE-DC_Shell213">
                          <mesh name="NONE-DC_Shell_Material_#27_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0008'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE086">
                        <group name="NONE-DC_Shell214">
                          <mesh name="NONE-DC_Shell_Material_#27_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0009'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE087">
                        <group name="NONE-DC_Shell215">
                          <mesh name="NONE-DC_Shell_Material_#28_0014" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0014'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE088">
                        <group name="NONE-DC_Shell216">
                          <mesh name="NONE-DC_Shell_Material_#28_0015" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0015'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE089">
                        <group name="NONE-DC_Shell217">
                          <mesh name="NONE-DC_Shell_Material_#27_0010" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0010'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE090">
                        <group name="NONE-DC_Shell218">
                          <mesh name="NONE-DC_Shell_Material_#27_0011" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0011'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE091">
                        <group name="NONE-DC_Shell219">
                          <mesh name="NONE-DC_Shell_Material_#28_0016" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0016'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE092">
                        <group name="NONE-DC_Shell220">
                          <mesh name="NONE-DC_Shell_Material_#27_0012" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0012'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE093">
                        <group name="NONE-DC_Shell221">
                          <mesh name="NONE-DC_Shell_Material_#27_0013" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0013'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE094">
                        <group name="NONE-DC_Shell222">
                          <mesh name="NONE-DC_Shell_Material_#28_0017" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0017'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE095">
                        <group name="NONE-DC_Shell223">
                          <mesh name="NONE-DC_Shell_Material_#27_0014" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0014'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE096">
                        <group name="NONE097">
                          <group name="NONE-DC_Shell224">
                            <mesh name="NONE-DC_Shell_Material_#27_0015" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0015'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell225">
                            <mesh name="NONE-DC_Shell__0120" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0120'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell226">
                            <mesh name="NONE-DC_Shell__0121" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0121'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell227">
                            <mesh name="NONE-DC_Shell__0122" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0122'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell228">
                            <mesh name="NONE-DC_Shell__0123" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0123'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell229">
                            <mesh name="NONE-DC_Shell_Material_#27_0016" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0016'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell230">
                            <mesh name="NONE-DC_Shell__0124" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0124'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell231">
                            <mesh name="NONE-DC_Shell_Material_#29_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0'].geometry} material={materials.Material_29} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE098">
                        <group name="NONE099">
                          <group name="NONE-DC_Shell232">
                            <mesh name="NONE-DC_Shell_Material_#27_0017" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0017'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell233">
                            <mesh name="NONE-DC_Shell__0125" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0125'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell234">
                            <mesh name="NONE-DC_Shell__0126" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0126'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell235">
                            <mesh name="NONE-DC_Shell__0127" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0127'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell236">
                            <mesh name="NONE-DC_Shell__0128" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0128'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell237">
                            <mesh name="NONE-DC_Shell_Material_#27_0018" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0018'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell238">
                            <mesh name="NONE-DC_Shell__0129" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0129'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell239">
                            <mesh name="NONE-DC_Shell_Material_#29_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0001'].geometry} material={materials.Material_29} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE100">
                        <group name="NONE-DC_Shell240">
                          <mesh name="NONE-DC_Shell__0130" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0130'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE101">
                        <group name="NONE102">
                          <group name="NONE-DC_Shell241">
                            <mesh name="NONE-DC_Shell__0131" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0131'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell242">
                            <mesh name="NONE-DC_Shell__0132" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0132'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell243">
                            <mesh name="NONE-DC_Shell__0133" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0133'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell244">
                            <mesh name="NONE-DC_Shell__0134" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0134'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell245">
                            <mesh name="NONE-DC_Shell__0135" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0135'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell246">
                            <mesh name="NONE-DC_Shell__0136" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0136'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell247">
                            <mesh name="NONE-DC_Shell__0137" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0137'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell248">
                            <mesh name="NONE-DC_Shell__0138" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0138'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell249">
                            <mesh name="NONE-DC_Shell__0139" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0139'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell250">
                            <mesh name="NONE-DC_Shell__0140" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0140'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell251">
                            <mesh name="NONE-DC_Shell__0141" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0141'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell252">
                            <mesh name="NONE-DC_Shell__0142" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0142'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell253">
                            <mesh name="NONE-DC_Shell__0143" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0143'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell254">
                            <mesh name="NONE-DC_Shell__0144" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0144'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell255">
                            <mesh name="NONE-DC_Shell__0145" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0145'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell256">
                            <mesh name="NONE-DC_Shell__0146" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0146'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell257">
                            <mesh name="NONE-DC_Shell__0147" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0147'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell258">
                            <mesh name="NONE-DC_Shell__0148" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0148'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell259">
                            <mesh name="NONE-DC_Shell__0149" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0149'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell260">
                            <mesh name="NONE-DC_Shell__0150" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0150'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell261">
                            <mesh name="NONE-DC_Shell__0151" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0151'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell262">
                            <mesh name="NONE-DC_Shell_Material_#29_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0002'].geometry} material={materials.Material_29} />
                          </group>
                          <group name="NONE-DC_Shell263">
                            <mesh name="NONE-DC_Shell_Material_#29_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0003'].geometry} material={materials.Material_29} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE103">
                        <group name="NONE-DC_Shell264">
                          <mesh name="NONE-DC_Shell__0152" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0152'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE104">
                        <group name="NONE105">
                          <group name="NONE-DC_Shell265">
                            <mesh name="NONE-DC_Shell__0153" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0153'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell266">
                            <mesh name="NONE-DC_Shell_Material_#29_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0004'].geometry} material={materials.Material_29} />
                          </group>
                          <group name="NONE-DC_Shell267">
                            <mesh name="NONE-DC_Shell_Material_#29_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0005'].geometry} material={materials.Material_29} />
                          </group>
                          <group name="NONE-DC_Shell268">
                            <mesh name="NONE-DC_Shell__0154" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0154'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell269">
                            <mesh name="NONE-DC_Shell__0155" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0155'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell270">
                            <mesh name="NONE-DC_Shell__0156" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0156'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell271">
                            <mesh name="NONE-DC_Shell__0157" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0157'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell272">
                            <mesh name="NONE-DC_Shell__0158" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0158'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell273">
                            <mesh name="NONE-DC_Shell__0159" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0159'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell274">
                            <mesh name="NONE-DC_Shell__0160" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0160'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell275">
                            <mesh name="NONE-DC_Shell__0161" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0161'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell276">
                            <mesh name="NONE-DC_Shell__0162" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0162'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell277">
                            <mesh name="NONE-DC_Shell__0163" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0163'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell278">
                            <mesh name="NONE-DC_Shell__0164" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0164'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell279">
                            <mesh name="NONE-DC_Shell__0165" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0165'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell280">
                            <mesh name="NONE-DC_Shell__0166" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0166'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell281">
                            <mesh name="NONE-DC_Shell__0167" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0167'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell282">
                            <mesh name="NONE-DC_Shell__0168" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0168'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell283">
                            <mesh name="NONE-DC_Shell__0169" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0169'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell284">
                            <mesh name="NONE-DC_Shell__0170" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0170'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell285">
                            <mesh name="NONE-DC_Shell__0171" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0171'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell286">
                            <mesh name="NONE-DC_Shell__0172" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0172'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell287">
                            <mesh name="NONE-DC_Shell__0173" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0173'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE106">
                        <group name="NONE-DC_Shell288">
                          <mesh name="NONE-DC_Shell__0174" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0174'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE107">
                        <group name="NONE108">
                          <group name="NONE-DC_Shell289">
                            <mesh name="NONE-DC_Shell__0175" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0175'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell290">
                            <mesh name="NONE-DC_Shell__0176" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0176'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell291">
                            <mesh name="NONE-DC_Shell__0177" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0177'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell292">
                            <mesh name="NONE-DC_Shell__0178" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0178'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell293">
                            <mesh name="NONE-DC_Shell__0179" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0179'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell294">
                            <mesh name="NONE-DC_Shell__0180" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0180'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell295">
                            <mesh name="NONE-DC_Shell__0181" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0181'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell296">
                            <mesh name="NONE-DC_Shell__0182" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0182'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell297">
                            <mesh name="NONE-DC_Shell__0183" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0183'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell298">
                            <mesh name="NONE-DC_Shell__0184" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0184'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell299">
                            <mesh name="NONE-DC_Shell__0185" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0185'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell300">
                            <mesh name="NONE-DC_Shell__0186" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0186'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell301">
                            <mesh name="NONE-DC_Shell__0187" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0187'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell302">
                            <mesh name="NONE-DC_Shell__0188" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0188'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell303">
                            <mesh name="NONE-DC_Shell__0189" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0189'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell304">
                            <mesh name="NONE-DC_Shell__0190" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0190'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell305">
                            <mesh name="NONE-DC_Shell__0191" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0191'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell306">
                            <mesh name="NONE-DC_Shell__0192" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0192'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell307">
                            <mesh name="NONE-DC_Shell__0193" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0193'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell308">
                            <mesh name="NONE-DC_Shell__0194" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0194'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell309">
                            <mesh name="NONE-DC_Shell__0195" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0195'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell310">
                            <mesh name="NONE-DC_Shell_Material_#29_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0006'].geometry} material={materials.Material_29} />
                          </group>
                          <group name="NONE-DC_Shell311">
                            <mesh name="NONE-DC_Shell_Material_#29_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0007'].geometry} material={materials.Material_29} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE109">
                        <group name="NONE-DC_Shell312">
                          <mesh name="NONE-DC_Shell__0196" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0196'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE110">
                        <group name="NONE111">
                          <group name="NONE-DC_Shell313">
                            <mesh name="NONE-DC_Shell_Material_#29_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0008'].geometry} material={materials.Material_29} />
                          </group>
                          <group name="NONE-DC_Shell314">
                            <mesh name="NONE-DC_Shell_Material_#29_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0009'].geometry} material={materials.Material_29} />
                          </group>
                          <group name="NONE-DC_Shell315">
                            <mesh name="NONE-DC_Shell__0197" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0197'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell316">
                            <mesh name="NONE-DC_Shell__0198" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0198'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell317">
                            <mesh name="NONE-DC_Shell__0199" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0199'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell318">
                            <mesh name="NONE-DC_Shell__0200" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0200'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell319">
                            <mesh name="NONE-DC_Shell__0201" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0201'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell320">
                            <mesh name="NONE-DC_Shell__0202" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0202'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell321">
                            <mesh name="NONE-DC_Shell__0203" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0203'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell322">
                            <mesh name="NONE-DC_Shell__0204" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0204'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell323">
                            <mesh name="NONE-DC_Shell__0205" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0205'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell324">
                            <mesh name="NONE-DC_Shell__0206" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0206'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell325">
                            <mesh name="NONE-DC_Shell__0207" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0207'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell326">
                            <mesh name="NONE-DC_Shell__0208" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0208'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell327">
                            <mesh name="NONE-DC_Shell__0209" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0209'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell328">
                            <mesh name="NONE-DC_Shell__0210" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0210'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell329">
                            <mesh name="NONE-DC_Shell__0211" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0211'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell330">
                            <mesh name="NONE-DC_Shell__0212" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0212'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell331">
                            <mesh name="NONE-DC_Shell__0213" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0213'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell332">
                            <mesh name="NONE-DC_Shell__0214" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0214'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell333">
                            <mesh name="NONE-DC_Shell__0215" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0215'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell334">
                            <mesh name="NONE-DC_Shell__0216" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0216'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell335">
                            <mesh name="NONE-DC_Shell__0217" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0217'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE112">
                        <group name="NONE-DC_Shell336">
                          <mesh name="NONE-DC_Shell_Material_#27_0019" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0019'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE113">
                        <group name="NONE-DC_Shell337">
                          <mesh name="NONE-DC_Shell_Material_#27_0020" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0020'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE114">
                        <group name="NONE-DC_Shell338">
                          <mesh name="NONE-DC_Shell_Material_#27_0021" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0021'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE115">
                        <group name="NONE-DC_Shell339">
                          <mesh name="NONE-DC_Shell_Material_#29_0010" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#29_0010'].geometry} material={materials.Material_29} />
                        </group>
                      </group>
                      <group name="NONE116">
                        <group name="NONE-DC_Shell340">
                          <mesh name="NONE-DC_Shell_Material_#27_0022" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0022'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE117">
                        <group name="NONE-DC_Shell341">
                          <mesh name="NONE-DC_Shell_Material_#30_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0'].geometry} material={materials.Material_30} />
                        </group>
                      </group>
                      <group name="NONE118">
                        <group name="NONE119">
                          <group name="NONE-DC_Shell342">
                            <mesh name="NONE-DC_Shell_Material_#30_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0001'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell343">
                            <mesh name="NONE-DC_Shell_Material_#30_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0002'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell344">
                            <mesh name="NONE-DC_Shell_Material_#30_0003" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0003'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell345">
                            <mesh name="NONE-DC_Shell_Material_#30_0004" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0004'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell346">
                            <mesh name="NONE-DC_Shell_Material_#30_0005" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0005'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell347">
                            <mesh name="NONE-DC_Shell_Material_#30_0006" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0006'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell348">
                            <mesh name="NONE-DC_Shell_Material_#30_0007" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0007'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell349">
                            <mesh name="NONE-DC_Shell_Material_#30_0008" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0008'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell350">
                            <mesh name="NONE-DC_Shell_Material_#30_0009" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0009'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell351">
                            <mesh name="NONE-DC_Shell_Material_#30_0010" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0010'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell352">
                            <mesh name="NONE-DC_Shell_Material_#30_0011" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0011'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell353">
                            <mesh name="NONE-DC_Shell_Material_#30_0012" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0012'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell354">
                            <mesh name="NONE-DC_Shell_Material_#30_0013" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0013'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell355">
                            <mesh name="NONE-DC_Shell_Material_#30_0014" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0014'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell356">
                            <mesh name="NONE-DC_Shell_Material_#30_0015" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0015'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell357">
                            <mesh name="NONE-DC_Shell_Material_#30_0016" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0016'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell358">
                            <mesh name="NONE-DC_Shell_Material_#30_0017" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0017'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell359">
                            <mesh name="NONE-DC_Shell_Material_#30_0018" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0018'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell360">
                            <mesh name="NONE-DC_Shell_Material_#30_0019" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0019'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell361">
                            <mesh name="NONE-DC_Shell_Material_#30_0020" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0020'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell362">
                            <mesh name="NONE-DC_Shell_Material_#30_0021" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0021'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell363">
                            <mesh name="NONE-DC_Shell_Material_#30_0022" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#30_0022'].geometry} material={materials.Material_30} />
                          </group>
                          <group name="NONE-DC_Shell364">
                            <mesh name="NONE-DC_Shell__0218" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0218'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell365">
                            <mesh name="NONE-DC_Shell__0219" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0219'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell366">
                            <mesh name="NONE-DC_Shell__0220" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0220'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell367">
                            <mesh name="NONE-DC_Shell__0221" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0221'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell368">
                            <mesh name="NONE-DC_Shell__0222" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0222'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell369">
                            <mesh name="NONE-DC_Shell__0223" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0223'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell370">
                            <mesh name="NONE-DC_Shell__0224" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0224'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell371">
                            <mesh name="NONE-DC_Shell__0225" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0225'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell372">
                            <mesh name="NONE-DC_Shell__0226" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0226'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE120">
                        <group name="NONE121">
                          <group name="NONE-DC_Shell373">
                            <mesh name="NONE-DC_Shell__0227" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0227'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell374">
                            <mesh name="NONE-DC_Shell_Material_#31_0" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#31_0'].geometry} material={materials.Material_31} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE122">
                        <group name="NONE-DC_Shell375">
                          <mesh name="NONE-DC_Shell_Material_#27_0023" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0023'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE123">
                        <group name="NONE-DC_Shell376">
                          <mesh name="NONE-DC_Shell_Material_#27_0024" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0024'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE124">
                        <group name="NONE-DC_Shell377">
                          <mesh name="NONE-DC_Shell_Material_#27_0025" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0025'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE125">
                        <group name="NONE-DC_Shell378">
                          <mesh name="NONE-DC_Shell_Material_#27_0026" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0026'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE126">
                        <group name="NONE-DC_Shell379">
                          <mesh name="NONE-DC_Shell_Material_#27_0027" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0027'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE127">
                        <group name="NONE-DC_Shell380">
                          <mesh name="NONE-DC_Shell_Material_#27_0028" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0028'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE128">
                        <group name="NONE-DC_Shell381">
                          <mesh name="NONE-DC_Shell_Material_#27_0029" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0029'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE129">
                        <group name="NONE-DC_Shell382">
                          <mesh name="NONE-DC_Shell_Material_#27_0030" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0030'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE130">
                        <group name="NONE-DC_Shell383">
                          <mesh name="NONE-DC_Shell_Material_#27_0031" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0031'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE131">
                        <group name="NONE-DC_Shell384">
                          <mesh name="NONE-DC_Shell_Material_#27_0032" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0032'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE132">
                        <group name="NONE-DC_Shell385">
                          <mesh name="NONE-DC_Shell_Material_#27_0033" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0033'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE133">
                        <group name="NONE-DC_Shell386">
                          <mesh name="NONE-DC_Shell_Material_#27_0034" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0034'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE134">
                        <group name="NONE-DC_Shell387">
                          <mesh name="NONE-DC_Shell_Material_#27_0035" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0035'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE135">
                        <group name="NONE-DC_Shell388">
                          <mesh name="NONE-DC_Shell_Material_#27_0036" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0036'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE136">
                        <group name="NONE-DC_Shell389">
                          <mesh name="NONE-DC_Shell_Material_#27_0037" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0037'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE137">
                        <group name="NONE-DC_Shell390">
                          <mesh name="NONE-DC_Shell_Material_#27_0038" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0038'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE138">
                        <group name="NONE-DC_Shell391">
                          <mesh name="NONE-DC_Shell_Material_#27_0039" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0039'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE139">
                        <group name="NONE-DC_Shell392">
                          <mesh name="NONE-DC_Shell_Material_#27_0040" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0040'].geometry} material={materials.Material_27} />
                        </group>
                      </group>
                      <group name="NONE140">
                        <group name="NONE-DC_Shell393">
                          <mesh name="NONE-DC_Shell_Material_#28_0018" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0018'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE141">
                        <group name="NONE-DC_Shell394">
                          <mesh name="NONE-DC_Shell_Material_#28_0019" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0019'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE142">
                        <group name="NONE-DC_Shell395">
                          <mesh name="NONE-DC_Shell_Material_#28_0020" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0020'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE143">
                        <group name="NONE-DC_Shell396">
                          <mesh name="NONE-DC_Shell_Material_#28_0021" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0021'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE144">
                        <group name="NONE-DC_Shell397">
                          <mesh name="NONE-DC_Shell_Material_#28_0022" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0022'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE145">
                        <group name="NONE-DC_Shell398">
                          <mesh name="NONE-DC_Shell_Material_#28_0023" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#28_0023'].geometry} material={materials.Material_28} />
                        </group>
                      </group>
                      <group name="NONE146">
                        <group name="NONE-DC_Shell399">
                          <mesh name="NONE-DC_Shell__0228" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0228'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE147">
                        <group name="NONE-DC_Shell400">
                          <mesh name="NONE-DC_Shell__0229" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0229'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE148">
                        <group name="NONE-DC_Shell401">
                          <mesh name="NONE-DC_Shell__0230" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0230'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE149">
                        <group name="NONE-DC_Shell402">
                          <mesh name="NONE-DC_Shell__0231" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0231'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE150">
                        <group name="NONE-DC_Shell403">
                          <mesh name="NONE-DC_Shell__0232" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0232'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE151">
                        <group name="NONE-DC_Shell404">
                          <mesh name="NONE-DC_Shell__0233" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0233'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE152">
                        <group name="NONE-DC_Shell405">
                          <mesh name="NONE-DC_Shell__0234" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0234'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE153">
                        <group name="NONE-DC_Shell406">
                          <mesh name="NONE-DC_Shell__0235" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0235'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE154">
                        <group name="NONE-DC_Shell407">
                          <mesh name="NONE-DC_Shell__0236" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0236'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE155">
                        <group name="NONE-DC_Shell408">
                          <mesh name="NONE-DC_Shell__0237" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0237'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE156">
                        <group name="NONE-DC_Shell409">
                          <mesh name="NONE-DC_Shell__0238" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0238'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE157">
                        <group name="NONE-DC_Shell410">
                          <mesh name="NONE-DC_Shell__0239" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0239'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE158">
                        <group name="NONE-DC_Shell411">
                          <mesh name="NONE-DC_Shell__0240" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0240'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE159">
                        <group name="NONE-DC_Shell412">
                          <mesh name="NONE-DC_Shell__0241" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0241'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE160">
                        <group name="NONE-DC_Shell413">
                          <mesh name="NONE-DC_Shell__0242" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0242'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE161">
                        <group name="NONE-DC_Shell414">
                          <mesh name="NONE-DC_Shell__0243" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0243'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE162">
                        <group name="NONE-DC_Shell415">
                          <mesh name="NONE-DC_Shell__0244" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0244'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE163">
                        <group name="NONE164">
                          <group name="NONE-DC_Shell416">
                            <mesh name="NONE-DC_Shell__0245" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0245'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell417">
                            <mesh name="NONE-DC_Shell__0246" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0246'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE165">
                        <group name="NONE-DC_Shell418">
                          <mesh name="NONE-DC_Shell__0247" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0247'].geometry} material={materials['NONE-DC_Shell__0']} />
                        </group>
                      </group>
                      <group name="NONE166">
                        <group name="NONE167">
                          <group name="NONE-DC_Shell419">
                            <mesh name="NONE-DC_Shell_Material_#27_0041" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#27_0041'].geometry} material={materials.Material_27} />
                          </group>
                          <group name="NONE-DC_Shell420">
                            <mesh name="NONE-DC_Shell__0248" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0248'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell421">
                            <mesh name="NONE-DC_Shell__0249" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0249'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                          <group name="NONE-DC_Shell422">
                            <mesh name="NONE-DC_Shell_Material_#31_0001" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#31_0001'].geometry} material={materials.Material_31} />
                          </group>
                          <group name="NONE-DC_Shell423">
                            <mesh name="NONE-DC_Shell__0250" castShadow receiveShadow geometry={nodes['NONE-DC_Shell__0250'].geometry} material={materials['NONE-DC_Shell__0']} />
                          </group>
                        </group>
                      </group>
                      <group name="NONE168">
                        <group name="NONE-DC_Shell424">
                          <mesh name="NONE-DC_Shell_Material_#31_0002" castShadow receiveShadow geometry={nodes['NONE-DC_Shell_Material_#31_0002'].geometry} material={materials.Material_31} />
                        </group>
                      </group>
                    </group>
                  </group>
                </group>
              </group>
            </group>
            <group name="H_DDS_HighRes001" position={[-3.22, 3.31, -52.43]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_L_eye001" position={[-4.61, -0.62, 13.92]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_L_gland001" position={[-2.41, -1.1, 13.86]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_R_eye001" position={[-4.61, -0.62, 13.92]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_R_gland001" position={[-4.01, -1.05, 13.88]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_TeethDown001" position={[-3.23, -0.15, 10.6]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_TeethUp001" position={[-3.22, 0.38, 10.82]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="Lights001" position={[-3.22, -3.31, -40.43]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
              <group name="spotLight009" position={[35.31, 171.96, 76.39]} rotation={[1.07, 0.13, -0.23]} scale={9.19}>
                <group name="Object_1624" rotation={[Math.PI / 2, 0, 0]}>
                  <group name="Object_1625" />
                </group>
              </group>
              <group name="spotLight010" position={[-96.47, 113.14, 79.66]} rotation={[-0.09, -0.77, -0.06]} scale={9.19} />
              <group name="spotLight011" position={[89.71, 110.56, -92.33]} rotation={[-3.09, 0.66, 3.11]} scale={9.19} />
              <group name="spotLight012" position={[-48.42, 117.36, -81.18]} rotation={[-3, -0.5, -3.07]} scale={9.19} />
            </group>
            <group name="master001" position={[-3.22, 4.44, -52.43]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
              <group name="Reference002">
                <group name="Object_1638">
                  <primitive object={nodes._rootJoint_2} />
                  <group name="Object_1640" scale={0.39} />
                  <group name="Object_1642" scale={0.39} />
                  <group name="Object_1644" scale={0.39} />
                  <group name="Object_1646" scale={0.39} />
                  <group name="Object_1648" scale={0.39} />
                  <group name="Object_1650" scale={0.39} />
                  <group name="Object_1652" scale={0.39} />
                  <skinnedMesh name="Object_1641" geometry={nodes.Object_1641.geometry} material={materials.Material_32} skeleton={nodes.Object_1641.skeleton} />
                  <skinnedMesh name="Object_1643" geometry={nodes.Object_1643.geometry} material={materials.Material_32} skeleton={nodes.Object_1643.skeleton} />
                  <skinnedMesh name="Object_1645" geometry={nodes.Object_1645.geometry} material={materials.Material_32} skeleton={nodes.Object_1645.skeleton} />
                  <skinnedMesh name="Object_1647" geometry={nodes.Object_1647.geometry} material={materials.Material_32} skeleton={nodes.Object_1647.skeleton} />
                  <skinnedMesh name="Object_1649" geometry={nodes.Object_1649.geometry} material={materials.Material_32} skeleton={nodes.Object_1649.skeleton} />
                  <skinnedMesh name="Object_1651" geometry={nodes.Object_1651.geometry} material={materials.Material_32} skeleton={nodes.Object_1651.skeleton} />
                  <skinnedMesh name="Object_1653" geometry={nodes.Object_1653.geometry} material={materials.Material_32} skeleton={nodes.Object_1653.skeleton} />
                </group>
              </group>
            </group>
          </group>
          <group name="drums" position={[15.85, 51.42, -76.6]} rotation={[-Math.PI / 2, 0, 0]}>
            <group name="drums_player" position={[-1.66, 3.36, -12]} rotation={[0, 0, -0.79]}>
              <group name="H_DDS_HighRes002_1" position={[1.24, -0.37, -37.26]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_L_eye002_1" position={[0.06, -4.14, 26.63]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_L_gland002_1" position={[1.9, -4.39, 26.62]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_R_eye002_1" position={[0.06, -4.14, 26.63]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_R_gland002_1" position={[0.58, -4.39, 26.63]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_TeethDown002_1" position={[1.24, -3.75, 23.57]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="h_TeethUp002_1" position={[1.24, -3.29, 23.63]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
              <group name="Lights002_1" position={[1.24, -7, -25.26]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
                <group name="spotLight1001" position={[33.01, 158.78, 70.14]} rotation={[1.07, 0.13, -0.23]} scale={9.19}>
                  <group name="Object_1735" rotation={[Math.PI / 2, 0, 0]}>
                    <group name="Object_1736" />
                  </group>
                </group>
                <group name="spotLight2001" position={[-90.19, 103.79, 73.19]} rotation={[-0.09, -0.77, -0.06]} scale={9.19} />
                <group name="spotLight3001" position={[83.87, 101.38, -87.61]} rotation={[-3.09, 0.66, 3.11]} scale={9.19} />
                <group name="spotLight4001" position={[-45.27, 107.74, -77.17]} rotation={[-3, -0.5, -3.07]} scale={9.19} />
              </group>
              <group name="master002_1" position={[1.24, 0.75, -37.26]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
                <group name="Reference001_1">
                  <group name="Object_1749">
                    <primitive object={nodes._rootJoint_3} />
                    <group name="Object_1751" scale={0.39} />
                    <group name="Object_1753" scale={0.39} />
                    <group name="Object_1755" scale={0.39} />
                    <group name="Object_1757" scale={0.39} />
                    <group name="Object_1759" scale={0.39} />
                    <group name="Object_1761" scale={0.39} />
                    <group name="Object_1763" scale={0.39} />
                    <skinnedMesh name="Object_1752" geometry={nodes.Object_1752.geometry} material={materials.Material_33} skeleton={nodes.Object_1752.skeleton} />
                    <skinnedMesh name="Object_1754" geometry={nodes.Object_1754.geometry} material={materials.Material_33} skeleton={nodes.Object_1754.skeleton} />
                    <skinnedMesh name="Object_1756" geometry={nodes.Object_1756.geometry} material={materials.Material_33} skeleton={nodes.Object_1756.skeleton} />
                    <skinnedMesh name="Object_1758" geometry={nodes.Object_1758.geometry} material={materials.Material_33} skeleton={nodes.Object_1758.skeleton} />
                    <skinnedMesh name="Object_1760" geometry={nodes.Object_1760.geometry} material={materials.Material_33} skeleton={nodes.Object_1760.skeleton} />
                    <skinnedMesh name="Object_1762" geometry={nodes.Object_1762.geometry} material={materials.Material_33} skeleton={nodes.Object_1762.skeleton} />
                    <skinnedMesh name="Object_1764" geometry={nodes.Object_1764.geometry} material={materials.Material_33} skeleton={nodes.Object_1764.skeleton} />
                  </group>
                </group>
              </group>
            </group>
            <group name="drums001" position={[-6.3, -23.05, -26.19]} scale={0.06}>
              <group name="arch67_22_01001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_01001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_01001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_02001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_02001_Material005_0" castShadow receiveShadow geometry={nodes.arch67_22_02001_Material005_0.geometry} material={materials['Material.005']} />
              </group>
              <group name="arch67_22_03001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_03001_chrome_0" castShadow receiveShadow geometry={nodes.arch67_22_03001_chrome_0.geometry} material={materials.chrome} />
              </group>
              <group name="arch67_22_04001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_04001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_04001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_05001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_05001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_05001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_06001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_06001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_06001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_07001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_07001_Material002_0" castShadow receiveShadow geometry={nodes.arch67_22_07001_Material002_0.geometry} material={materials['Material.002']} />
              </group>
              <group name="arch67_22_08001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_08001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_08001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_09001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_09001_Material006_0" castShadow receiveShadow geometry={nodes.arch67_22_09001_Material006_0.geometry} material={materials['Material.006']} />
              </group>
              <group name="arch67_22_11001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_11001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_11001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_12001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_12001_r��fek_0" castShadow receiveShadow geometry={nodes['arch67_22_12001_r��fek_0'].geometry} material={materials.rfek} />
              </group>
              <group name="arch67_22_13001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_13001_bic��_0" castShadow receiveShadow geometry={nodes['arch67_22_13001_bic��_0'].geometry} material={materials.material} />
              </group>
              <group name="arch67_22_14001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_14001_Material009_0" castShadow receiveShadow geometry={nodes.arch67_22_14001_Material009_0.geometry} material={materials['Material.009']} />
                <mesh name="arch67_22_14001_spodek_0" castShadow receiveShadow geometry={nodes.arch67_22_14001_spodek_0.geometry} material={materials.spodek} />
              </group>
              <group name="arch67_22_16001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_16001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_16001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_17001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_17001_Material005_0" castShadow receiveShadow geometry={nodes.arch67_22_17001_Material005_0.geometry} material={materials['Material.005']} />
              </group>
              <group name="arch67_22_18001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_18001_chrome_0" castShadow receiveShadow geometry={nodes.arch67_22_18001_chrome_0.geometry} material={materials.chrome} />
              </group>
              <group name="arch67_22_19001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_19001_Material005_0" castShadow receiveShadow geometry={nodes.arch67_22_19001_Material005_0.geometry} material={materials['Material.005']} />
              </group>
              <group name="arch67_22_20001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_20001_Material018_0" castShadow receiveShadow geometry={nodes.arch67_22_20001_Material018_0.geometry} material={materials['Material.018']} />
              </group>
              <group name="arch67_22_21001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_21001_Material003_0" castShadow receiveShadow geometry={nodes.arch67_22_21001_Material003_0.geometry} material={materials['Material.003']} />
              </group>
              <group name="arch67_22_22001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_22001_Material005_0" castShadow receiveShadow geometry={nodes.arch67_22_22001_Material005_0.geometry} material={materials['Material.005']} />
              </group>
              <group name="arch67_22_23001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_23001_chrome_0" castShadow receiveShadow geometry={nodes.arch67_22_23001_chrome_0.geometry} material={materials.chrome} />
              </group>
              <group name="arch67_22_24001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_24001_��lapka_0" castShadow receiveShadow geometry={nodes['arch67_22_24001_��lapka_0'].geometry} material={materials.lapka} />
              </group>
              <group name="arch67_22_25001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_25001_Material004_0" castShadow receiveShadow geometry={nodes.arch67_22_25001_Material004_0.geometry} material={materials['Material.004']} />
              </group>
              <group name="arch67_22_26001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_26001_chrome_0" castShadow receiveShadow geometry={nodes.arch67_22_26001_chrome_0.geometry} material={materials.chrome} />
              </group>
              <group name="arch67_22_27001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_27001_Material005_0" castShadow receiveShadow geometry={nodes.arch67_22_27001_Material005_0.geometry} material={materials['Material.005']} />
              </group>
              <group name="arch67_22_28001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_28001_None_0" castShadow receiveShadow geometry={nodes.arch67_22_28001_None_0.geometry} material={materials.None} />
              </group>
              <group name="arch67_22_30001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_30001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_30001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_31001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_31001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_31001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_32001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_32001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_32001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_33001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_33001_Material013_0" castShadow receiveShadow geometry={nodes.arch67_22_33001_Material013_0.geometry} material={materials['Material.013']} />
              </group>
              <group name="arch67_22_34001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_34001_r��fek_0" castShadow receiveShadow geometry={nodes['arch67_22_34001_r��fek_0'].geometry} material={materials.rfek} />
              </group>
              <group name="arch67_22_35001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_35001_bic��_0" castShadow receiveShadow geometry={nodes['arch67_22_35001_bic��_0'].geometry} material={materials.material} />
              </group>
              <group name="arch67_22_36001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_36001_Material015_0" castShadow receiveShadow geometry={nodes.arch67_22_36001_Material015_0.geometry} material={materials['Material.015']} />
              </group>
              <group name="arch67_22_37001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_37001_Material005_0" castShadow receiveShadow geometry={nodes.arch67_22_37001_Material005_0.geometry} material={materials['Material.005']} />
              </group>
              <group name="arch67_22_38001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_38001_chrome_0" castShadow receiveShadow geometry={nodes.arch67_22_38001_chrome_0.geometry} material={materials.chrome} />
              </group>
              <group name="arch67_22_39001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_39001_None_0" castShadow receiveShadow geometry={nodes.arch67_22_39001_None_0.geometry} material={materials.None} />
              </group>
              <group name="arch67_22_40001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_40001_None_0" castShadow receiveShadow geometry={nodes.arch67_22_40001_None_0.geometry} material={materials.None} />
              </group>
              <group name="arch67_22_41001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_41001_None_0" castShadow receiveShadow geometry={nodes.arch67_22_41001_None_0.geometry} material={materials.None} />
              </group>
              <group name="arch67_22_42001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_42001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_42001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_43001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_43001_None_0" castShadow receiveShadow geometry={nodes.arch67_22_43001_None_0.geometry} material={materials.None} />
              </group>
              <group name="arch67_22_44001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_44001_None_0" castShadow receiveShadow geometry={nodes.arch67_22_44001_None_0.geometry} material={materials.None} />
              </group>
              <group name="arch67_22_45001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_45001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_45001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_46001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_46001_Material012_0" castShadow receiveShadow geometry={nodes.arch67_22_46001_Material012_0.geometry} material={materials['Material.012']} />
              </group>
              <group name="arch67_22_47001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_47001_Material019_0" castShadow receiveShadow geometry={nodes.arch67_22_47001_Material019_0.geometry} material={materials['Material.019']} />
              </group>
              <group name="arch67_22_48001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_48001_r��fek_0" castShadow receiveShadow geometry={nodes['arch67_22_48001_r��fek_0'].geometry} material={materials.rfek} />
              </group>
              <group name="arch67_22_49001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_49001_bic��_0" castShadow receiveShadow geometry={nodes['arch67_22_49001_bic��_0'].geometry} material={materials.material} />
              </group>
              <group name="arch67_22_49002" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_49002_bic��_0" castShadow receiveShadow geometry={nodes['arch67_22_49002_bic��_0'].geometry} material={materials.material} />
              </group>
              <group name="arch67_22_50001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_50001_Material011_0" castShadow receiveShadow geometry={nodes.arch67_22_50001_Material011_0.geometry} material={materials['Material.011']} />
              </group>
              <group name="arch67_22_51001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_51001_Material017_0" castShadow receiveShadow geometry={nodes.arch67_22_51001_Material017_0.geometry} material={materials['Material.017']} />
              </group>
              <group name="arch67_22_52001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_52001_r��fek_0" castShadow receiveShadow geometry={nodes['arch67_22_52001_r��fek_0'].geometry} material={materials.rfek} />
              </group>
              <group name="arch67_22_53001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_53001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_53001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_54001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_54001_Material006_0" castShadow receiveShadow geometry={nodes.arch67_22_54001_Material006_0.geometry} material={materials['Material.006']} />
              </group>
              <group name="arch67_22_55001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_55001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_55001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_58001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_58001_Material005_0" castShadow receiveShadow geometry={nodes.arch67_22_58001_Material005_0.geometry} material={materials['Material.005']} />
              </group>
              <group name="arch67_22_59001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_59001_Material007_0" castShadow receiveShadow geometry={nodes.arch67_22_59001_Material007_0.geometry} material={materials['Material.007']} />
              </group>
              <group name="arch67_22_60001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_60001_Material001_0" castShadow receiveShadow geometry={nodes.arch67_22_60001_Material001_0.geometry} material={materials['Material.001']} />
              </group>
              <group name="arch67_22_61001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_61001_��lapka_0" castShadow receiveShadow geometry={nodes['arch67_22_61001_��lapka_0'].geometry} material={materials.lapka} />
              </group>
              <group name="arch67_22_62001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_62001_r��fek_0" castShadow receiveShadow geometry={nodes['arch67_22_62001_r��fek_0'].geometry} material={materials.rfek} />
              </group>
              <group name="arch67_22_63001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_63001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_63001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_64001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_64001_wire_115115115001_0" castShadow receiveShadow geometry={nodes.arch67_22_64001_wire_115115115001_0.geometry} material={materials['wire_115115115.001']} />
              </group>
              <group name="arch67_22_65001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_65001_Material008_0" castShadow receiveShadow geometry={nodes.arch67_22_65001_Material008_0.geometry} material={materials['Material.008']} />
              </group>
              <group name="arch67_22_66001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_66001_zelezo_0" castShadow receiveShadow geometry={nodes.arch67_22_66001_zelezo_0.geometry} material={materials.zelezo} />
              </group>
              <group name="arch67_22_67000" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_67000_Material016_0" castShadow receiveShadow geometry={nodes.arch67_22_67000_Material016_0.geometry} material={materials['Material.016']} />
              </group>
              <group name="arch67_22_67001" position={[44.36, 0, -403.99]} rotation={[Math.PI / 2, 0, 0]} scale={39.37}>
                <mesh name="arch67_22_67001_bic��_0" castShadow receiveShadow geometry={nodes['arch67_22_67001_bic��_0'].geometry} material={materials.material} />
              </group>
              <group name="left_stick" position={[-248.66, 132.13, 50.53]} rotation={[-1.14, 0.86, -2.94]} scale={15.97}>
                <mesh name="left_stick_Material010_0" castShadow receiveShadow geometry={nodes.left_stick_Material010_0.geometry} material={materials['Material.010']} />
                <mesh name="left_stick_Material014_0" castShadow receiveShadow geometry={nodes.left_stick_Material014_0.geometry} material={materials['Material.014']} />
              </group>
              <group name="right_stick" position={[-272.23, 134.24, 51.25]} rotation={[-1.88, 0.86, 0.24]} scale={15.97}>
                <mesh name="right_stick_Material010_0" castShadow receiveShadow geometry={nodes.right_stick_Material010_0.geometry} material={materials['Material.010']} />
                <mesh name="right_stick_Material014_0" castShadow receiveShadow geometry={nodes.right_stick_Material014_0.geometry} material={materials['Material.014']} />
              </group>
            </group>
          </group>
          <group name="piano" position={[-53.69, 57.22, -49.36]} rotation={[-Math.PI / 2, 0, 0.98]}>
            <group name="H_DDS_HighRes002" position={[-14.29, 33.18, -41.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_L_eye002" position={[-15.56, 29.24, 25.13]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_L_gland002" position={[-13.5, 29, 25.07]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_R_eye002" position={[-15.56, 29.24, 25.13]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_R_gland002" position={[-15.08, 29, 25.07]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_TeethDown002" position={[-14.3, 29.77, 21.89]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="h_TeethUp002" position={[-14.3, 30.22, 22.05]} rotation={[Math.PI / 2, 0, 0]} scale={0.39} />
            <group name="Lights002" position={[-14.29, 26.56, -29.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
              <group name="spotLight013" position={[34.23, 165.76, 73.45]} rotation={[1.07, 0.13, -0.23]} scale={9.19}>
                <group name="Object_2120" rotation={[Math.PI / 2, 0, 0]}>
                  <group name="Object_2121" />
                </group>
              </group>
              <group name="spotLight014" position={[-93.51, 108.74, 76.62]} rotation={[-0.09, -0.77, -0.06]} scale={9.19} />
              <group name="spotLight015" position={[86.96, 106.24, -90.11]} rotation={[-3.09, 0.66, 3.11]} scale={9.19} />
              <group name="spotLight016" position={[-46.94, 112.83, -79.29]} rotation={[-3, -0.5, -3.07]} scale={9.19} />
            </group>
            <group name="master002" position={[-14.29, 34.31, -41.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.39}>
              <group name="Reference003">
                <group name="Object_2134">
                  <primitive object={nodes._rootJoint_4} />
                  <group name="Object_2136" scale={0.39} />
                  <group name="Object_2138" scale={0.39} />
                  <group name="Object_2140" scale={0.39} />
                  <group name="Object_2142" scale={0.39} />
                  <group name="Object_2144" scale={0.39} />
                  <group name="Object_2146" scale={0.39} />
                  <group name="Object_2148" scale={0.39} />
                  <skinnedMesh name="Object_2137" geometry={nodes.Object_2137.geometry} material={materials.Material_34} skeleton={nodes.Object_2137.skeleton} />
                  <skinnedMesh name="Object_2139" geometry={nodes.Object_2139.geometry} material={materials.Material_34} skeleton={nodes.Object_2139.skeleton} />
                  <skinnedMesh name="Object_2141" geometry={nodes.Object_2141.geometry} material={materials.Material_34} skeleton={nodes.Object_2141.skeleton} />
                  <skinnedMesh name="Object_2143" geometry={nodes.Object_2143.geometry} material={materials.Material_34} skeleton={nodes.Object_2143.skeleton} />
                  <skinnedMesh name="Object_2145" geometry={nodes.Object_2145.geometry} material={materials.Material_34} skeleton={nodes.Object_2145.skeleton} />
                  <skinnedMesh name="Object_2147" geometry={nodes.Object_2147.geometry} material={materials.Material_34} skeleton={nodes.Object_2147.skeleton} />
                  <skinnedMesh name="Object_2149" geometry={nodes.Object_2149.geometry} material={materials.Material_34} skeleton={nodes.Object_2149.skeleton} />
                </group>
              </group>
            </group>
            <group name="piano_lp" position={[6.47, -18.77, -28.63]} rotation={[0, 0, 1.57]} scale={0.16}>
              <group name="buttons" position={[132.19, -262.35, 158.73]} scale={39.37}>
                <group name="Cube047" position={[1.3, 7.03, -2.34]} rotation={[0, 0.46, 0]} scale={[-0.03, -0.03, -0.07]}>
                  <mesh name="Cube047__0" castShadow receiveShadow geometry={nodes.Cube047__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube048" position={[1.22, 6.42, -2.35]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.02, -0.02, -0.07]}>
                  <mesh name="Cube048__0" castShadow receiveShadow geometry={nodes.Cube048__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube049" position={[1.19, 5.75, -2.35]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.02, -0.06, -0.07]}>
                  <mesh name="Cube049__0" castShadow receiveShadow geometry={nodes.Cube049__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube050" position={[1.22, 6.08, -2.35]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.02, -0.02, -0.07]}>
                  <mesh name="Cube050__0" castShadow receiveShadow geometry={nodes.Cube050__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube051" position={[1.3, 10.25, -2.34]} rotation={[0, 0.46, 0]} scale={[-0.03, -0.03, -0.07]}>
                  <mesh name="Cube051__0" castShadow receiveShadow geometry={nodes.Cube051__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube052" position={[1.3, 10.83, -2.34]} rotation={[0, 0.46, 0]} scale={[-0.03, -0.03, -0.07]}>
                  <mesh name="Cube052__0" castShadow receiveShadow geometry={nodes.Cube052__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube053" position={[1.3, 12.26, -2.34]} rotation={[0, 0.37, 0]} scale={[0.01, 0.06, 0.07]}>
                  <mesh name="Cube053__0" castShadow receiveShadow geometry={nodes.Cube053__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube054" position={[1.3, 11.84, -2.34]} rotation={[0, 0.46, 0]} scale={[-0.03, -0.03, -0.07]}>
                  <mesh name="Cube054__0" castShadow receiveShadow geometry={nodes.Cube054__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube055" position={[3.32, 12.88, -3.34]} scale={0.05}>
                  <mesh name="Cube055__0" castShadow receiveShadow geometry={nodes.Cube055__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube056" position={[3.36, 9.25, -3.34]} scale={0.05}>
                  <mesh name="Cube056__0" castShadow receiveShadow geometry={nodes.Cube056__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube057" position={[3.32, 8.82, -3.34]} scale={[0.05, 0.09, 0.05]}>
                  <mesh name="Cube057__0" castShadow receiveShadow geometry={nodes.Cube057__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube058" position={[3.32, 7.45, -3.34]} scale={0.05}>
                  <mesh name="Cube058__0" castShadow receiveShadow geometry={nodes.Cube058__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube059" position={[3.32, 6.73, -3.34]} scale={0.05}>
                  <mesh name="Cube059__0" castShadow receiveShadow geometry={nodes.Cube059__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube060" position={[3.36, 6.21, -3.34]} scale={0.05}>
                  <mesh name="Cube060__0" castShadow receiveShadow geometry={nodes.Cube060__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder016" position={[1.27, 8.16, -2.31]} rotation={[0, 0.37, 0]} scale={[-0.08, -0.08, -0.03]}>
                  <mesh name="Cylinder016__0" castShadow receiveShadow geometry={nodes.Cylinder016__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder017" position={[1.27, 9.49, -2.31]} rotation={[0, 0.37, 0]} scale={[-0.08, -0.08, -0.03]}>
                  <mesh name="Cylinder017__0" castShadow receiveShadow geometry={nodes.Cylinder017__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder019" position={[1.27, 5.38, -2.31]} rotation={[0, 0.37, 0]} scale={[-0.08, -0.08, -0.03]}>
                  <mesh name="Cylinder019__0" castShadow receiveShadow geometry={nodes.Cylinder019__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder020" position={[2.08, 5.01, -2.55]} scale={0.05}>
                  <mesh name="Cylinder020__0" castShadow receiveShadow geometry={nodes.Cylinder020__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder021" position={[2.08, 5.37, -2.55]} scale={0.05}>
                  <mesh name="Cylinder021__0" castShadow receiveShadow geometry={nodes.Cylinder021__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder022" position={[2.08, 5.7, -2.55]} scale={0.05}>
                  <mesh name="Cylinder022__0" castShadow receiveShadow geometry={nodes.Cylinder022__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder029" position={[3.41, 5.35, -3.36]} rotation={[0, 1.57, 0]} scale={0.05}>
                  <mesh name="Cylinder029__0" castShadow receiveShadow geometry={nodes.Cylinder029__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
              </group>
              <group name="first_piano" position={[-103.49, -354.38, 145.41]} scale={39.37} />
              <group name="klavishi_bel" position={[203.32, 50.52, 61.08]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-62.94, -4.06, -5.65]}>
                <mesh name="klavishi_bel__0" castShadow receiveShadow geometry={nodes.klavishi_bel__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
              <group name="korpus_bok" position={[195.45, 96.11, 49.24]} scale={[76.55, 10.58, 39.37]}>
                <mesh name="korpus_bok__0" castShadow receiveShadow geometry={nodes.korpus_bok__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
              <group name="korpus1" position={[199.94, 266.13, 54.28]} scale={[62.94, 16.67, 5.65]}>
                <mesh name="korpus1__0" castShadow receiveShadow geometry={nodes.korpus1__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
              <group name="micro_lp" position={[-280.78, -47.88, -18.62]} scale={39.37}>
                <group name="Cube070" position={[9.04, 4.37, 3.08]} scale={[0.02, 0.05, 0.08]}>
                  <mesh name="Cube070__0" castShadow receiveShadow geometry={nodes.Cube070__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder018" position={[9.04, 4.42, 3.08]} rotation={[Math.PI / 2, 0, 0]} scale={[0.08, 0.08, 0.02]}>
                  <mesh name="Cylinder018__0" castShadow receiveShadow geometry={nodes.Cylinder018__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder023" position={[9.04, 4.48, 3.08]} rotation={[Math.PI / 2, 0, 0]} scale={[0.24, 0.24, 0.05]}>
                  <mesh name="Cylinder023__0" castShadow receiveShadow geometry={nodes.Cylinder023__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder030" position={[12.59, 4.49, 6.12]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[0.36, 0.36, 0.91]}>
                  <mesh name="Cylinder030__0" castShadow receiveShadow geometry={nodes.Cylinder030__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder031" position={[13.24, 4.49, 6.12]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[0.36, 0.36, 0.86]}>
                  <mesh name="Cylinder031__0" castShadow receiveShadow geometry={nodes.Cylinder031__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder034" position={[13.25, 4.49, 6.12]} rotation={[Math.PI / 2, 1.57, 0]} scale={[0.31, 0.31, 0.89]}>
                  <mesh name="Cylinder034__0" castShadow receiveShadow geometry={nodes.Cylinder034__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder035" position={[13.03, 4.49, 6.12]} rotation={[Math.PI / 2, 1.57, 0]} scale={[0.36, 0.36, 0.9]}>
                  <mesh name="Cylinder035__0" castShadow receiveShadow geometry={nodes.Cylinder035__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder036" position={[12.59, 4.5, 6.12]} rotation={[Math.PI / 2, 1.57, 0]} scale={[0.12, 0.12, 0.19]}>
                  <mesh name="Cylinder036__0" castShadow receiveShadow geometry={nodes.Cylinder036__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder037" position={[11.99, 4.49, 6.12]} rotation={[Math.PI / 2, Math.PI / 2, 0]} scale={[0.36, 0.36, 0.91]}>
                  <mesh name="Cylinder037__0" castShadow receiveShadow geometry={nodes.Cylinder037__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="micro_wire001" position={[11.41, 4.49, 6.12]}>
                  <mesh name="micro_wire001__0" castShadow receiveShadow geometry={nodes.micro_wire001__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="trinoga001" position={[0.65, 6.9, -4.37]}>
                  <group name="trinoga_1002" position={[5.4, -2.42, 5.39]} rotation={[0, 0.94, 0]} scale={[-0.15, -0.14, -0.17]}>
                    <mesh name="trinoga_1002__0" castShadow receiveShadow geometry={nodes.trinoga_1002__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_1003" position={[11.9, -2.42, 10.14]} rotation={[0, 0.94, 0]} scale={[-0.1, -0.09, -0.11]}>
                    <mesh name="trinoga_1003__0" castShadow receiveShadow geometry={nodes.trinoga_1003__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_2004" position={[8.4, -2.42, 7.7]} rotation={[0, 0.02, 0]} scale={[-0.1, -0.09, -0.13]}>
                    <mesh name="trinoga_2004__0" castShadow receiveShadow geometry={nodes.trinoga_2004__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_2005" position={[11.94, -2.39, 10.33]} scale={0.14}>
                    <mesh name="trinoga_2005__0" castShadow receiveShadow geometry={nodes.trinoga_2005__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_4001" position={[8.22, -2.42, 0.63]} scale={[0.21, 0.21, 0.12]}>
                    <mesh name="trinoga_4001__0" castShadow receiveShadow geometry={nodes.trinoga_4001__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_5003" position={[8.22, -2.42, 0.63]} rotation={[0, 1.52, 0]} scale={[-0.1, -0.09, -0.13]}>
                    <mesh name="trinoga_5003__0" castShadow receiveShadow geometry={nodes.trinoga_5003__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_6003" position={[8.22, -2.42, 0.63]} rotation={[-1.51, -0.72, 1.61]} scale={[-0.1, -0.09, -0.13]}>
                    <mesh name="trinoga_6003__0" castShadow receiveShadow geometry={nodes.trinoga_6003__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_7003" position={[8.22, -2.42, 0.63]} rotation={[1.52, -0.36, -1.59]} scale={[-0.1, -0.09, -0.13]}>
                    <mesh name="trinoga_7003__0" castShadow receiveShadow geometry={nodes.trinoga_7003__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                  <group name="trinoga_derg1001" position={[11.9, -2.42, 10.14]} rotation={[0, 0.94, 0]} scale={[-0.1, -0.09, -0.11]}>
                    <mesh name="trinoga_derg1001__0" castShadow receiveShadow geometry={nodes.trinoga_derg1001__0.geometry} material={materials['NONE-DC_Shell__0']} />
                  </group>
                </group>
              </group>
              <group name="pedal" position={[184.38, 106.09, -172.04]} scale={[-41.9, -7.92, -6.38]}>
                <mesh name="pedal__0" castShadow receiveShadow geometry={nodes.pedal__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
              <group name="pedal_wire_input" position={[160.25, 109.21, -174.02]} rotation={[Math.PI / 2, 0, 0]} scale={[-2.46, -2.46, -9.84]}>
                <mesh name="pedal_wire_input__0" castShadow receiveShadow geometry={nodes.pedal_wire_input__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
              <group name="podstavka" position={[201.32, 96.11, -70.93]} scale={39.37}>
                <mesh name="podstavka__0" castShadow receiveShadow geometry={nodes.podstavka__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
              <group name="pupitr_lp" position={[175.65, 350.46, 66.25]} rotation={[0, 0, -0.23]} scale={39.37}>
                <group name="Cube038" position={[-0.05, -3.06, 1.28]} rotation={[0, -0.22, 0]}>
                  <mesh name="Cube038__0" castShadow receiveShadow geometry={nodes.Cube038__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube065" position={[-0.18, -3.06, 1.84]} rotation={[0, -0.22, 0]}>
                  <mesh name="Cube065__0" castShadow receiveShadow geometry={nodes.Cube065__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube066" position={[-0.05, -3.08, 1.28]} rotation={[0, -0.22, 0]}>
                  <mesh name="Cube066__0" castShadow receiveShadow geometry={nodes.Cube066__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube067" position={[0.08, -3.07, 0.7]} scale={[0.03, 1.21, 0.02]}>
                  <mesh name="Cube067__0" castShadow receiveShadow geometry={nodes.Cube067__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube068" position={[-0.05, -3.97, 1.26]} rotation={[0, -0.22, 0]}>
                  <mesh name="Cube068__0" castShadow receiveShadow geometry={nodes.Cube068__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube069" position={[-0.05, -2.14, 1.26]} rotation={[0, -0.22, 0]}>
                  <mesh name="Cube069__0" castShadow receiveShadow geometry={nodes.Cube069__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="trinoga_2003" position={[0.08, -3.08, 0.72]} rotation={[0, 0.02, 0]} scale={[-0.04, -0.04, -0.01]}>
                  <mesh name="trinoga_2003__0" castShadow receiveShadow geometry={nodes.trinoga_2003__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="trinoga_5002" position={[0.08, -3.08, 0.26]} rotation={[0, 1.52, 0]} scale={[-0.04, -0.04, -0.05]}>
                  <mesh name="trinoga_5002__0" castShadow receiveShadow geometry={nodes.trinoga_5002__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="trinoga_6002" position={[0.08, -3.08, 0.26]} rotation={[-1.51, -0.72, 1.61]} scale={[-0.04, -0.04, -0.05]}>
                  <mesh name="trinoga_6002__0" castShadow receiveShadow geometry={nodes.trinoga_6002__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="trinoga_7002" position={[0.08, -3.08, 0.26]} rotation={[1.52, -0.36, -1.59]} scale={[-0.04, -0.04, -0.05]}>
                  <mesh name="trinoga_7002__0" castShadow receiveShadow geometry={nodes.trinoga_7002__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
              </group>
              <group name="second_piano_lp" position={[-254.76, -297.23, -110.58]} scale={39.37}>
                <group name="Cube061" position={[9.47, 6.5, 4.72]} rotation={[0, 0, 0.18]} scale={1.24}>
                  <mesh name="Cube061__0" castShadow receiveShadow geometry={nodes.Cube061__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube062" position={[9.71, 5.84, 4.72]} rotation={[0, 0, 0.18]} scale={[0.77, 0.84, 1]}>
                  <mesh name="Cube062__0" castShadow receiveShadow geometry={nodes.Cube062__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube063" position={[9.43, 6.14, 4.72]} rotation={[0, 0, 0.18]} scale={1.33}>
                  <mesh name="Cube063__0" castShadow receiveShadow geometry={nodes.Cube063__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cube064" position={[9.48, 5.86, 4.72]} rotation={[0, 0, 0.18]} scale={1.33}>
                  <mesh name="Cube064__0" castShadow receiveShadow geometry={nodes.Cube064__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="Cylinder015" position={[9.21, 8.04, 4.82]} rotation={[0, 0, 0.18]} scale={[0.07, 0.07, 0.14]}>
                  <mesh name="Cylinder015__0" castShadow receiveShadow geometry={nodes.Cylinder015__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="main_korpus" position={[9.68, 7.54, 4.66]} rotation={[0, 0, 0.18]} scale={[1.04, 1.87, 0.05]}>
                  <mesh name="main_korpus__0" castShadow receiveShadow geometry={nodes.main_korpus__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="small_piano_black_buttons" position={[9.94, 7.58, 4.76]} rotation={[0, 0, 0.18]} scale={[0.53, 1, 0.13]}>
                  <mesh name="small_piano_black_buttons__0" castShadow receiveShadow geometry={nodes.small_piano_black_buttons__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
                <group name="small_piano_white_buttons_p" position={[10.44, 6.03, 4.66]} rotation={[0, 0, 0.18]} scale={[0.85, 1, 1]}>
                  <mesh name="small_piano_white_buttons_p__0" castShadow receiveShadow geometry={nodes.small_piano_white_buttons_p__0.geometry} material={materials['NONE-DC_Shell__0']} />
                </group>
              </group>
              <group name="wire_pedal" position={[162.91, 131.02, -174.75]} scale={39.37}>
                <mesh name="wire_pedal__0" castShadow receiveShadow geometry={nodes.wire_pedal__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
              <group name="wire_piano" position={[115.88, -26.76, 30.37]} scale={21.06}>
                <mesh name="wire_piano__0" castShadow receiveShadow geometry={nodes.wire_piano__0.geometry} material={materials['NONE-DC_Shell__0']} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </_MovableBase>
  )
}
