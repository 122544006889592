import {useRef} from "react";
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';

export default function PhotoViewer () {

  const viewerRef = useRef<any>(null!);

  // const viewer = new Viewer({
  //   // container: document.querySelector('#viewer'),
  //   container: viewerRef.current,
  //   panorama: 'https://photo-sphere-viewer-data.netlify.app/assets/sphere.jpg',
  // });

  return (
    <>
      <ReactPhotoSphereViewer
        container="here"
        src={`${process.env.REACT_APP_ASSETS_URL}/360photos/wardour5.jpg`}
        height={'75vh'} width={"100%"}
        defaultZoomLvl={1}
      ></ReactPhotoSphereViewer>

      {/*<div ref={viewerRef} id="viewer"></div>*/}
    </>
  )
}
