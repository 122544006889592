import React, { Suspense, useEffect, useState } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame } from '@react-three/fiber'
import { Reflector, Text, useTexture, useGLTF } from '@react-three/drei'

// const VIDEO_URI = 'https://assets.unegma.net/shared/videos/rio.mp4';
const VIDEO_URI = 'https://assets.unegma.net/shared/videos/rio-small.mp4';

export default function SplashScreen () {
  let TIMEOUT: number | undefined;
  if (process.env.NODE_ENV === 'development') {
    TIMEOUT = 0;
  } else {
    // TIMEOUT = 5600;
    TIMEOUT = 3300;
  }

  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      console.log('SplashScreen: setTimeout')
      setShowSplashScreen(false);
    }, TIMEOUT);
  }, [])

  return (
    <>
      <div className={`splash-screen ${showSplashScreen ? '' : 'hidden'}`}>
        <Canvas gl={{ alpha: false }} camera={{ position: [0, 3, 100], fov: 15 }}>
          <color attach="background" args={['black']} />
          <fog attach="fog" args={['black', 15, 20]} />
          <Suspense fallback={null}>
            <group position={[0, -1, 0]}>
              <VideoText position={[0, 1.3, -2]} />
              <Ground />
            </group>
            <ambientLight intensity={0.5} />
            <spotLight position={[0, 10, 0]} intensity={0.3} />
            <directionalLight position={[-50, 0, -40]} intensity={0.7} />
            <Intro />
          </Suspense>
        </Canvas>
      </div>
    </>
  )
}


function VideoText(props: any) {
  const [video] = useState(() => Object.assign(document.createElement('video'), { src: VIDEO_URI, crossOrigin: 'Anonymous', loop: true, muted: true }))
  useEffect(() => void video.play(), [video])
  return (
    <>
      <Text font={'https://assets.unegma.net/shared/fonts/Inter-Bold.woff'} fontSize={1.5} letterSpacing={-0.06} {...props}>
        unegma
        <meshBasicMaterial toneMapped={false}>
          <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
        </meshBasicMaterial>
      </Text>
      {/*<Text position={[-0.001,-0.05,0]} font={'https://assets.unegma.net/shared/fonts/Inter-Bold.woff'} fontSize={0.4} letterSpacing={-0.06}>*/}
      {/*  studio*/}
      {/*  <meshBasicMaterial toneMapped={false}>*/}
      {/*    <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />*/}
      {/*  </meshBasicMaterial>*/}
      {/*</Text>*/}
    </>
  )
}

function Ground() {
  const [floor, normal] = useTexture(['https://assets.unegma.net/shared/maps/SurfaceImperfections003_1K_var1.jpg', 'https://assets.unegma.net/shared/maps/SurfaceImperfections003_1K_Normal.jpg'])
  return (
    <Reflector blur={[400, 100]} resolution={512} args={[10, 10]} mirror={0.5} mixBlur={6} mixStrength={1.5} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
      {(Material, props) => <Material color="#a0a0a0" metalness={0.4} roughnessMap={floor} normalMap={normal} {...props} />}
    </Reflector>
  )
}

function Intro() {
  const [vec] = useState(() => new THREE.Vector3())
  return useFrame((state) => {
    state.camera.position.lerp(vec.set(state.clock.getElapsedTime() * 1.6, 3 + state.clock.getElapsedTime() * 1.6, 14), 0.05)
    state.camera.lookAt(0, 0, 0)
  })
}
