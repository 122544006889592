import React, {useEffect, useState} from "react";
import {Typography, Box, Button} from "@mui/material";
import useSceneInteractions from "../hooks/useSceneInteractions";

export default function Counter ({dummiesCount, setDummiesCount}: any) {
  const [count, setCount] = useState(0);
  const { room } = useSceneInteractions();


  const decreaseCount = () => {
    if (count === 0) return;
    setCount(count - 1);
    setDummiesCount(dummiesCount - 1);
  }

  const increaseCount = () => {
    setCount(count + 1);
    setDummiesCount(dummiesCount + 1);
  }

  useEffect(() => {
    setCount(0);
  }, [room])

  return (
    <Box className={"counter my-second-step"} component={"div"} display="flex" justifyContent="center" alignItems="center" p={2}>
      <Typography className="counter__count" variant="h5">Person Count:</Typography>

      <Box  className="counter__counter" component={"div"} ml={2} mr={2}>
        <Button variant="outlined" color="primary" onClick={() => decreaseCount()}>-</Button>
        <Box component={"div"} ml={2} mr={2}>
          <Typography className="counter__count" variant="h5">{count}</Typography>
        </Box>
        <Button variant="outlined" color="primary" onClick={() => increaseCount()}>+</Button>
      </Box>
    </Box>
  );
};
