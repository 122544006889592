import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import _RoomBase from "./_RoomBase";
import useSceneInteractions from "../../hooks/useSceneInteractions";
import Band from "../Dummies/Band";
import {WembleyStage} from "../Furniture/WembleyStage";


type GLTFResult = GLTF & {
  nodes: {
    Model_material4_0005_1: THREE.Mesh
    Model_material4_0005_2: THREE.Mesh
    Model_material4_0005_3: THREE.Mesh
    Model_material4_0005_4: THREE.Mesh
    Model_material4_0005_5: THREE.Mesh
  }
  materials: {
    material4: THREE.MeshStandardMaterial
    material1: THREE.MeshStandardMaterial
    material3: THREE.MeshStandardMaterial
    material0: THREE.MeshStandardMaterial
    material2: THREE.MeshStandardMaterial
  }
}

export default function Orion({ }: any) {
  const ROOM_NAME = 'orion';
  const ROOM_NAME2 = 'orion'; // todo fix this
  const [itemURI, setItemURI] = useState(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}-transformed.glb`);
  const { highQuality, band, furnished, room } = useSceneInteractions();

  useEffect(() => {
    if (!highQuality) {
      setItemURI(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}-transformed.glb`);
    } else {
      setItemURI(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}.glb`);
    }
  }, [highQuality]);

  // @ts-ignore
  const { nodes, materials } = useGLTF(itemURI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult;

  const SCALE = 1;


  const [bandPosition, setBandPosition] = useState<any>([18,1.85,0]);



  return (
    <group dispose={null} >

      { band && (
        <>
          <Band scale={0.03} position={bandPosition} setPosition={setBandPosition} rotation={[0,5,0]} />
        </>
      )}

      <_RoomBase  rotation={[0, 0, 0]} room={ROOM_NAME2}>


       {/*<group ref={group} {...props} dispose={null}>*/}
         <group position={room.position} rotation={[0, 0, 0]}>
           <mesh scale={SCALE} geometry={nodes.Model_material4_0005_1.geometry} material={materials.material4} />
           <mesh scale={SCALE} geometry={nodes.Model_material4_0005_2.geometry} material={materials.material1} />
           <mesh scale={SCALE} geometry={nodes.Model_material4_0005_3.geometry} material={materials.material3} />
           <mesh scale={SCALE} geometry={nodes.Model_material4_0005_4.geometry} material={materials.material0} />
           <mesh scale={SCALE} geometry={nodes.Model_material4_0005_5.geometry} material={materials.material2} />
         </group>
       {/*</group>*/}
      </_RoomBase>
    </group>
  )
}
