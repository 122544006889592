import * as THREE from 'three';
import _RoomBase from "./_RoomBase";
import React, {useEffect, useState} from "react";
import {Reflector, useTexture} from "@react-three/drei";
import Band from "../Dummies/Band";
import useSceneInteractions from "../../hooks/useSceneInteractions";

export function LightRoom () {
  const ROOM_NAME = 'lightroom';
  const VIDEO_URI = 'https://assets.unegma.net/shared/videos/rio.mp4';
  const [floor, normal] = useTexture(['https://assets.unegma.net/shared/maps/SurfaceImperfections003_1K_var1.jpg', 'https://assets.unegma.net/shared/maps/SurfaceImperfections003_1K_Normal.jpg'])
  const { band } = useSceneInteractions();

  const [bandPosition, setBandPosition] = useState<any>([3,0.7,-3]);

  const [video] = useState(() => Object.assign(document.createElement('video'), { src: VIDEO_URI, crossOrigin: 'Anonymous', loop: true, muted: true }))
  useEffect(() => void video.play(), [video])

  const WALL_SIZE = 30;

  return (
    <>
      { band && (
        <>
          <Band scale={0.03} position={bandPosition} setPosition={setBandPosition} rotation={[0,5,0]} />
        </>
      )}

      <_RoomBase room={ROOM_NAME} cratePosition={[-3,0.7,-3]} dummiesPosition={[0,0.7,0]}>
        {/*todo still need to have a base for the crate so it moves properly*/}
        <group position={[0,15,0]}>
          <mesh position={[-WALL_SIZE/2,0,0]}>
            <boxGeometry args={[1, WALL_SIZE, WALL_SIZE]} />
            <meshBasicMaterial toneMapped={false}>
              <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
            </meshBasicMaterial>
          </mesh>
          <mesh position={[WALL_SIZE/2,0,0]}>
            <boxGeometry args={[1, WALL_SIZE, WALL_SIZE]} />
            <meshBasicMaterial toneMapped={false}>
              <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
            </meshBasicMaterial>
          </mesh>

          <mesh position={[0,0,-WALL_SIZE/2]} rotation={[0,Math.PI / 2,0]}>
            <boxGeometry args={[1, WALL_SIZE, WALL_SIZE]} />
            <meshBasicMaterial toneMapped={false}>
              <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
            </meshBasicMaterial>
          </mesh>
          <mesh position={[0,0,WALL_SIZE/2]} rotation={[0,Math.PI / 2,0]}>
            <boxGeometry args={[1, WALL_SIZE, WALL_SIZE]} />
            <meshBasicMaterial toneMapped={false}>
              <videoTexture attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
            </meshBasicMaterial>
          </mesh>

          <mesh position={[0,WALL_SIZE/4,0]}>
            <Reflector blur={[400, 100]} resolution={512} args={[WALL_SIZE, WALL_SIZE]} mirror={0.5} mixBlur={6} mixStrength={1.5} rotation={[-Math.PI / 2, Math.PI, Math.PI / 2]}>
              {(Material, props) => <Material color="#a0a0a0" metalness={0.4} roughnessMap={floor} normalMap={normal} {...props} />}
            </Reflector>
          </mesh>
          <mesh position={[0,-WALL_SIZE/2+0.7 ,0]}>
            {/*<boxGeometry args={[WALL_SIZE, 1, WALL_SIZE]} />*/}
            <Reflector blur={[400, 100]} resolution={512} args={[WALL_SIZE, WALL_SIZE]} mirror={0.5} mixBlur={6} mixStrength={1.5} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
              {(Material, props) => <Material color="#a0a0a0" metalness={0.4} roughnessMap={floor} normalMap={normal} {...props} />}
            </Reflector>
          </mesh>
        </group>
      </_RoomBase>
    </>
  )
}
