import _MovableBase from "../Rooms/_MovableBase";
import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    WoodenCrate1_WoodenCrates_0: THREE.Mesh
  }
  materials: {
    WoodenCrates: THREE.MeshStandardMaterial
  }
}

export default function Crate({ position, setPosition, ...props }: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/furniture/crate-transformed.glb`

  const group = useRef<THREE.Group>(null!)
  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <_MovableBase position={position} setPosition={setPosition}>
      <group ref={group} {...props} dispose={null}>
        <mesh castShadow receiveShadow geometry={nodes.WoodenCrate1_WoodenCrates_0.geometry} material={materials.WoodenCrates} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
    </_MovableBase>
  )
}
