// TODO THIS MIGHT NEED TO BE UP TO 21 BECAUSE OF MATH.ROUND?? (check which is the final dummy at position 20)
// This function generates a random number within a range
const generateRandomDummyNumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

// This function generates a random number within a range
const generateRandomNumber = (min: number, max: number): number =>
  Math.random() * (max - min) + min;

// This function generates a random position within the bounding box
const generateRandomPosition = (room: any): { x: number; y: number; z: number } => {
  const x = generateRandomNumber(
    room.boundingBox.xz[0],
    room.boundingBox.xz[0] + room.boundingBox.size[0]
  );
  const z = generateRandomNumber(
    room.boundingBox.xz[1],
    room.boundingBox.xz[1] + room.boundingBox.size[1]
  );
  return { x, y: 0, z }; // keeping y constant based on your code
};

/**
 * Generate Dummies
 *
 * @param prevState
 * @param room
 * @param dummiesCount
 */
export const generateDummiesFunction = (prevState: any, room: string, dummiesCount: number) => {
  if (prevState.length < dummiesCount) {
    // When a dummy needs to be added.
    const newDummy = {
      position: generateRandomPosition(room),
      rotation: { x: -1.6, y: 0, z: generateRandomNumber(0, 5) },
      dummyNumber: generateRandomDummyNumber(1, 20)
    };
    return [...prevState, newDummy];
  } else if (prevState.length > dummiesCount) {
    // When a dummy needs to be removed.
    return prevState.slice(0, prevState.length - 1);
  } else {
    // When the count hasn't changed.
    return prevState;
  }
}
