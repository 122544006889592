type Room = {
  name: string,
  fullName: string
}

export interface ISceneInteractions {
  zoomOn: boolean;
  furnished: boolean;
  dummies: boolean;
  highQuality: boolean;
  band: boolean;
  isDragging: boolean;
  gridOn: boolean;
  error: string;
  helperText: string;
  target: [number, number, number];
  roomCount: number;
  selectedMesh: number;
  room: any; // todo create type for room
  roomsArray: any; // todo find out how to use Room type here
  asset: any; // todo create type for asset
  assetsArray: any; // todo find out how to use Room type here
  firstPerson: boolean;
  zoom: number,
  dummiesArray: [],
  dummiesCount: number,
  pan: number,
  cameraPosition: [number, number, number]
}

export enum SCENE_INTERACTIONS {
  TOGGLE_GRID = 'TOGGLE_GRID',
  TOGGLE_FURNISHED = 'TOGGLE_FURNISHED',
  TOGGLE_DUMMIES = 'TOGGLE_DUMMIES',
  TOGGLE_BAND = 'TOGGLE_BAND',
  TOGGLE_ZOOM = 'TOGGLE_ZOOM',
  SET_SELECTED_MESH = 'SET_SELECTED_MESH',
  SET_TARGET = 'SET_TARGET',
  SET_ROOM_COUNT = 'SET_ROOM_COUNT',
  SET_ROOMS_ARRAY = 'SET_ROOMS_ARRAY',
  SET_ROOM = 'SET_ROOM',
  SET_ASSETS_ARRAY = 'SET_ASSETS_ARRAY',
  SET_ASSET = 'SET_ASSET',
  ADD_ASSET = 'ADD_ASSET',
  TOGGLE_FIRST_PERSON = 'TOGGLE_FIRST_PERSON',
  TOGGLE_HIGH_QUALITY = 'TOGGLE_HIGH_QUALITY',
  SET_DUMMIES_ARRAY = 'SET_DUMMIES_ARRAY',
  SET_DUMMIES_COUNT = 'SET_DUMMIES_COUNT',
  SET_CAMERA_POSITION = 'SET_CAMERA_POSITION',
  SET_IS_DRAGGING = 'SET_IS_DRAGGING',
  SET_ZOOM = 'SET_ZOOM',
  SET_PAN = 'SET_PAN',
  ERROR = 'ERROR',
}
