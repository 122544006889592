import React, {useEffect, useRef, useState} from 'react';
import {
  Route, Routes, useLocation, useNavigate
} from "react-router-dom";
import './App.scss';
import NavBar from "./components/NavBar";
import {
  CameraAltOutlined,
  House,
  InfoOutlined,
} from "@mui/icons-material";
import InfoModal from "./components/InfoModal";
import LeftSideDrawer from "./components/LeftSideDrawer";
import SpaceOne from "./components/SpaceOne";
import useSceneInteractions from "./hooks/useSceneInteractions";
import {CssBaseline} from "@mui/material";
import PhotosModal from "./components/PhotosModal";
import BlueprintsModal from "./components/BlueprintsModal";
import {RightSideButtons} from "./components/RightSideButtons";
import RoomSelector from "./components/RoomSelector";
import ZoomSlider from "./components/ZoomSlider";
import PanSlider from "./components/PanSlider";
import Counter from "./components/Counter";
import {generateDummiesFunction} from "./helper/functions";
import Joyride from 'react-joyride';
import SplashScreen from "./components/SplashScreen";
import AssetAdder from "./components/AssetAdder";

/**
 * Todo add unegma.digital loading screen
 * @constructor
 */
function App() {
  const {
    helperText, selectedMesh, setSelectedMesh,
    room, dummiesArray, dummiesCount,
    cameraPosition, zoom, setCameraPosition,
    pan, setDummiesArray, setDummiesCount,
    roomsArray, setRoom
  } = useSceneInteractions();

  // move zoom stuff to global state

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showPhotosModal, setShowPhotosModal] = useState(false);
  const [showBlueprintsModal, setShowBlueprintsModal] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const pointerControls = useRef(null);
  const [nightTime, setNightTime] = React.useState(true);


  // if the url includes the room name
  const location = useLocation();
  let navigate = useNavigate();

  // on first load
  // todo this is supposed to navigate to lightroom if nothing is set, but it keeps overriding the room state
  // useEffect(() => {
  //   if (roomsArray) {
  //     // todo maybe move this to global helpfer function
  //     const roomDetails = roomsArray.filter((_room: any) => _room.name === room.name)[0];
  //
  //     if (roomDetails) {
  //       console.log('setting room', roomDetails)
  //       navigate(`/${room.name}`);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (roomsArray) {
      const cleanedLocation = location.pathname.replace('/', '');
      // todo maybe move this to global helpfer function
      const roomDetails = roomsArray.filter((_room: any) => _room.name === cleanedLocation)[0];
      console.log('roomDetails', roomDetails)

      if (roomDetails) {
        console.log('setting room', roomDetails)
        setRoom(roomDetails);
      }
    }
  }, [location]);


  // useEffect(() => {
  //   if(roomNameArray) {
  //     const roomDetails = roomNameArray.filter((room: any) => room.name === roomName)[0];
  //     setRoomDetails(roomDetails);
  //     console.log('roomDetails', roomDetails.position)
  //   }
  // }, [roomName])

  useEffect(() => {
    setDummiesArray([]);
    setDummiesCount(0);
    setCameraPosition(room.cameraPosition);
  }, [room]);

  useEffect(() => {
    setCameraPosition([zoom, zoom, zoom])
  }, [zoom]);

  useEffect(() => {
    setCameraPosition([cameraPosition[0]+pan, cameraPosition[1], cameraPosition[2]])
  }, [pan]);

  useEffect(() => {
    console.log(dummiesCount);
    const dummiesValue = generateDummiesFunction(dummiesArray, room, dummiesCount);
    setDummiesArray(dummiesValue);
  }, [dummiesCount]);


  useEffect(() => {
    console.log(dummiesArray)
  }, [dummiesArray])

  const toggleLeftSideDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && (
        (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift'))
      {
        return;
      }
      setDrawerOpen(!drawerOpen);
  };

  const handleClickInfo = () => {
    setShowInfoModal(!showInfoModal)
  }

  const handleShowPhotosModal = () => {
    setShowPhotosModal(!showPhotosModal)
  }
  const handleShowBlueprintsModal = () => {
    setShowBlueprintsModal(!showBlueprintsModal)
  }

  const showHelperTextMessage = () => {};

  // todo move to somewhere else
  const joyrideState = {
    steps: [
      {
        target: '.my-first-step',
        content: 'Click to change room, select HQ for higher quality (be aware, the models are digital reconstructions of the spaces and some areas may appear low quality).',
      },
      {
        target: '.my-second-step',
        content: 'Click here to add people to the room. You can remove and re-add someone to move them somewhere else.',
      },
      {
        target: '.my-third-step',
        content: 'Use this slider or the slider below to zoom or pan (pinch or scroll with 2 fingers on mobile).',
      },
      {
        target: '.my-fourth-step',
        content: 'These buttons will show: Floor Plan, Photos, Location info.',
      },
      {
        target: '.my-fifth-step',
        content: 'Double click the room in order to change the rotation point.',
      }
    ]
  };

  const { steps } = joyrideState;

  return (
    <div className="App">
      <CssBaseline />

      <SplashScreen />

      <Joyride
        steps={steps}
      />

      <NavBar
        nightTime={nightTime}
        setNightTime={setNightTime}
        pointerControls={pointerControls}
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        toggleLeftSideDrawer={toggleLeftSideDrawer}
        showBookingModal={showBookingModal}
        setShowBookingModal={setShowBookingModal}
      />

      <div className={`buttons-container buttons-container--left-helper my-fifth-step`}>
        <p className='helperText' onClick={() => {showHelperTextMessage()}}>{helperText}</p>
      </div>

      <RoomSelector />
      <AssetAdder />
      {/*<ZoomSlider />*/}
      {/*<PanSlider />*/}
      <Counter dummiesCount={dummiesCount} setDummiesCount={setDummiesCount} />
      <InfoModal pointerControls={pointerControls} showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} />
      <PhotosModal pointerControls={pointerControls} showPhotosModal={showPhotosModal} setShowPhotosModal={setShowPhotosModal} />
      <BlueprintsModal pointerControls={pointerControls} showBlueprintsModal={showBlueprintsModal} setShowBlueprintsModal={setShowBlueprintsModal} />
      {/*<WarningModal setShowWarningModal={setShowWarningModal} showWarningModal={showWarningModal} />*/}
      <RightSideButtons pointerControls={pointerControls} />

      <LeftSideDrawer
        pointerControls={pointerControls}
        drawerOpen={drawerOpen}
        toggleLeftSideDrawer={toggleLeftSideDrawer}
        setShowInfoModal={setShowInfoModal}
        setShowMenuModal={setShowMenuModal}
        showMenuModal={showMenuModal}
      />

      <Routes>
        <Route
          key={'home'}
          path="/"
          element={
            <SpaceOne
              nightTime={nightTime}
              selectedMesh={selectedMesh}
              setSelectedMesh={setSelectedMesh}
              pointerControls={pointerControls}
              // setShowWarningModal={setShowWarningModal}
            />
          }
        />

        <Route
          path="*"
          element={
            <SpaceOne
              nightTime={nightTime}
              selectedMesh={selectedMesh}
              setSelectedMesh={setSelectedMesh}
              pointerControls={pointerControls}
              // setShowWarningModal={setShowWarningModal}
            />
          }
        />
      </Routes>

      <div className="buttons-container my-fourth-step">
        <House className={`pointer site-button`} onClick={() => {handleShowBlueprintsModal()}} style={{ color: "white", margin: "0 4px" }} />
        <CameraAltOutlined className={`pointer site-button`} onClick={() => {handleShowPhotosModal()}} style={{ color: "white", margin: "0 4px" }} />
        <InfoOutlined className={`pointer site-button`} style={{ color: "white", margin: "0 4px" }} onClick={() => {handleClickInfo()}}/>
      </div>
    </div>
  );
}

export default App;
