import { ISceneInteractions, SCENE_INTERACTIONS } from './types';
import {useState} from "react";

// const INITIAL_HELPER_TEXT = "Controls: ⚲ or ↺ or ⇉ Model";
const INITIAL_HELPER_TEXT = "Double Click to set Rotation Point";

// todo add room offsets (so people are on floor)
// todo add default camera positions
// todo add default rotations
// todo add bounding box
// todo can the Components be added here too? (see space one)
const ROOMS_ARRAY = [
  {
    name: 'theatre1', fullName: "Theatre 1", blueprint: "greenroom-blueprint.png", position: [0,-9,0], rotation:[0,0,0],
    minZoom: 1, maxZoom: 50, cameraPosition: [11,11,11], target: [0,0,0], fov: 75, boundingBox: {
      xz: [-9, 8],
      size: [20, 3]
    }
  }, {
    // name: 'theatre2', fullName: "Theatre 2", blueprint: "greenroom-blueprint.png", position: [0,-9,0], rotation:[0,0,0],
    // minZoom: 5, maxZoom: 50, zoom: 5, cameraPosition: [11,11,11], boundingBox: {
    //   xz: [-5, -5],
    //   size: [5, 5]
    // }
  }, {
    name: 'wembley', fullName: "Wembley Arena (lidar)", blueprint: "greenroom-blueprint.png", position: [0,0,0], rotation:[0,0,0],
    minZoom: 1, maxZoom: 100, cameraPosition: [11,11,11], target: [0,0,0],  fov: 75, boundingBox: {
      xz: [-5, -5],
      size: [5, 5]
    }
  }, {
    // name: 'orion', fullName: "Orion (lidar)", blueprint: "greenroom-blueprint.png", position: [0,2.2,0], rotation:[0,0,0],
    // minZoom: 1, maxZoom: 100, zoom: 5, cameraPosition: [11,11,11], boundingBox: {
    //   xz: [-10, -16],
    //   size: [20, 30]
    // }
  }, {
    name: 'lightroom', fullName: "LightRoom", blueprint: "lightroom-blueprint.png", position: [0,0,0], rotation:[0,0,0],
    minZoom: 1, maxZoom: 70, cameraPosition: [1,1.7,1], target: [0,2.25,0], fov: 100, boundingBox: {
      xz: [-14, -14],
      size: [28, 28]
    }
  }
];

// todo there will need to be a counter for each asset added (and also for positions) maybe have a subarray for actual instances?
const ASSETS_ARRAY = [
  {
    name: 'crate', fullName: "Crate", position: [0,0.65,0], rotation:[0,0,0],
    boundingBox: {
      xz: [-9, 8],
      size: [20, 3]
    }
  }
];

export const initialState: ISceneInteractions = {
  zoomOn: true,
  gridOn: false,
  furnished: false,
  dummies: true,
  highQuality: false,
  band: false,
  isDragging: false,
  helperText: INITIAL_HELPER_TEXT,
  selectedMesh: 0,
  target: [0,0,0],
  roomCount: 1, // todo what is this?
  error: '',
  firstPerson: false,
  room: ROOMS_ARRAY[4],
  asset: null,
  roomsArray: ROOMS_ARRAY, // todo rename to room array
  assetsArray: ASSETS_ARRAY, // todo rename to room array
  dummiesArray: [],
  dummiesCount: 0,
  cameraPosition: [11,11,11], // default camera position (now moved to roomArray
  zoom: 11, // todo remove this and get from room name array
  pan: 0
}

export default function sceneInteractions(state = initialState, action: any) {
  switch (action.type) {
    case SCENE_INTERACTIONS.SET_SELECTED_MESH:
      return {
        ...state,
        error: '',
        selectedMesh: action.payload.selectedMesh
      };
    case SCENE_INTERACTIONS.SET_TARGET:
      return {
        ...state,
        error: '',
        target: action.payload.target
      };
    case SCENE_INTERACTIONS.TOGGLE_FURNISHED:
      return {
        ...state,
        error: '',
        furnished: !state.furnished
      };
    case SCENE_INTERACTIONS.TOGGLE_DUMMIES:
      return {
        ...state,
        error: '',
        dummies: !state.dummies
      };
    case SCENE_INTERACTIONS.TOGGLE_BAND:
      return {
        ...state,
        error: '',
        band: !state.band
      };
    case SCENE_INTERACTIONS.TOGGLE_GRID:
      return {
        ...state,
        error: '',
        gridOn: !state.gridOn,
        helperText: !state.gridOn ? '1 Square is approx 1m²': INITIAL_HELPER_TEXT  // todo check this switches back
      };
    // todo might want to: alert('Zoom Enabled\n\nPLEASE BE AWARE\n\nThis model is a reduced quality scan\nThis will be more noticeable when zooming!')
    case SCENE_INTERACTIONS.TOGGLE_ZOOM:
      return {
        ...state,
        error: '',
        zoomOn: !state.zoomOn,
        helperText: !state.zoomOn ? `⚲ or ${INITIAL_HELPER_TEXT}` : INITIAL_HELPER_TEXT // todo check this switches back
      };
    case SCENE_INTERACTIONS.SET_ROOM_COUNT:
      return {
        ...state,
        error: '',
        roomCount: action.payload.roomCount,
      };
    case SCENE_INTERACTIONS.SET_ROOMS_ARRAY:
      return {
        ...state,
        error: '',
        roomsArray: action.payload.roomsArray,
      };
    case SCENE_INTERACTIONS.SET_ROOM:
      return {
        ...state,
        error: '',
        room: action.payload.room,
      };
    case SCENE_INTERACTIONS.SET_ASSETS_ARRAY:
      return {
        ...state,
        error: '',
        assetsArray: action.payload.assetsArray,
      };
    case SCENE_INTERACTIONS.SET_ASSET:
      return {
        ...state,
        error: '',
        asset: action.payload.asset,
      };
    case SCENE_INTERACTIONS.ADD_ASSET:
      return {
        ...state,
        error: '',
        asset: action.payload.asset, // todo there will need to be a counter on the assets array for each asset added
      };
    case SCENE_INTERACTIONS.TOGGLE_FIRST_PERSON:
      return {
        ...state,
        error: '',
        firstPerson: !state.firstPerson,
      };
    case SCENE_INTERACTIONS.TOGGLE_HIGH_QUALITY:
      return {
        ...state,
        error: '',
        highQuality: !state.highQuality,
      };
    case SCENE_INTERACTIONS.SET_DUMMIES_ARRAY:
      return {
        ...state,
        error: '',
        dummiesArray: action.payload.dummiesArray,
      };
    case SCENE_INTERACTIONS.SET_IS_DRAGGING:
      return {
        ...state,
        error: '',
        isDragging: action.payload.isDragging,
      };
    case SCENE_INTERACTIONS.SET_DUMMIES_COUNT:
      return {
        ...state,
        error: '',
        dummiesCount: action.payload.dummiesCount,
      };
    case SCENE_INTERACTIONS.SET_CAMERA_POSITION:
      return {
        ...state,
        error: '',
        cameraPosition: action.payload.cameraPosition,
      };
    case SCENE_INTERACTIONS.SET_ZOOM:
      return {
        ...state,
        error: '',
        zoom: action.payload.zoom,
      };
    case SCENE_INTERACTIONS.SET_PAN:
      return {
        ...state,
        error: '',
        pan: action.payload.pan,
      };
    default: {
      return state;
    }
  }
}
