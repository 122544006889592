import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {HighQuality, HighQualityOutlined, PlusOne, PlusOneOutlined} from "@mui/icons-material";
import useSceneInteractions from "../hooks/useSceneInteractions";
import {useState} from "react";

export default function AssetAdder({}: any) {

  const { addAsset, assetsArray, highQuality, toggleHighQuality } = useSceneInteractions();
  const [selectedAsset, setSelectedAsset] = useState(assetsArray[0]);

  const handleAddAsset = () => {
    console.log('Adding Crate')
    addAsset(selectedAsset);
  }

  return (
    <div className={`asset-adder`}>

      <div className={`hq-selector ${highQuality ? 'hq-selector--on' : 'hq-selector--off'}`}>
        { highQuality && (
          <HighQuality className={"button"}  onClick={() => toggleHighQuality()} />
        )}
        { !highQuality && (
          <HighQualityOutlined className={"button button--light"} onClick={() => toggleHighQuality()} />
        )}
      </div>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Asset</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Asset"
          defaultValue={assetsArray[0].name}
        >
          { assetsArray.map((_asset: any, index:number) => {
            return <MenuItem key={index} value={_asset.name}>{_asset.fullName}</MenuItem>
          })}

        </Select>
      </FormControl>

      <div className={`hq-selector ${highQuality ? 'hq-selector--on' : 'hq-selector--off'}`}>
        <PlusOne className={"button"}  onClick={() => handleAddAsset()} />
      </div>

    </div>
  )
}
