import React, {useEffect, useRef, useState} from 'react'
import {useGLTF} from "@react-three/drei";
import {Object3D} from "three";
import {GLTF} from "three-stdlib";
import * as THREE from "three";
import useSceneInteractions from "../../hooks/useSceneInteractions";

type GLTFResult = GLTF & {
  nodes: {
    Dummy: THREE.Mesh
  }
  materials: {
    Dummy: THREE.MeshStandardMaterial
  }
}

/**
 *
 * @param nodes
 * @param materials
 * @param dummiesArray
 * @constructor
 */
function DummiesBase({nodes, materials, dummiesArray}: any) {
  const ref = useRef(null!);
  const dummy = new Object3D();

  useEffect(() => {
    if (dummiesArray) {
      // console.log('here1')
      dummiesArray.map((object: any, index: number) => {
        // console.log(object);
        dummy.position.set(object.position.x, object.position.y, object.position.z);
        dummy.scale.setScalar(0.01);
        dummy.rotation.set(object.rotation.x, object.rotation.y, object.rotation.z);

        dummy.updateMatrix();
        // @ts-ignore
        ref.current.setMatrixAt(index, dummy.matrix);
      })
      // @ts-ignore
      ref.current.instanceMatrix.needsUpdate = true;
    }
  }, [dummiesArray]);

  return (
    <>
      { dummiesArray && (
        <>
          <instancedMesh ref={ref} args={[nodes.Dummy.geometry, materials.Dummy, dummiesArray.length]}>
          </instancedMesh>
        </>
      )}
    </>
  )
}

export function Dummies1({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy1-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies2({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy2-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies3({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy3-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies4({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy4-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies5({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy5-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies6({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy6-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies7({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy7-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies8({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy8-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies9({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy9-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}
export function Dummies10({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy10-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies11({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy11-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies12({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy12-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies13({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy13-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies14({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy14-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies15({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy15-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies16({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy16-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies17({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy17-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies18({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy18-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies19({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy19-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies20({dummiesArray}: any) {
  const ITEM_URI = `${process.env.REACT_APP_ASSETS_URL}../../../shared/dummys/dummy20-transformed.glb`

  // @ts-ignore
  const { nodes, materials } = useGLTF(ITEM_URI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult

  return (
    <DummiesBase nodes={nodes} materials={materials} dummiesArray={dummiesArray} />
  )
}

export function Dummies({}: any) {
  const { dummiesArray } = useSceneInteractions();
  const [groupedDummies, setGroupedDummies] = useState<any>({});

  useEffect(() => {
    if (dummiesArray) {
      // sort the dummys
      console.log('dummiesArray:', dummiesArray);
      setGroupedDummies(dummiesArray.reduce((acc: any, dummy: any) => {
        const {dummyNumber} = dummy;
        if (!acc[dummyNumber]) {
          acc[dummyNumber] = [];
        }
        acc[dummyNumber].push(dummy);
        return acc;
      }, {}));
      console.log(groupedDummies)
    }
  }, [dummiesArray]);


  return (
    <>
      <Dummies1 dummiesArray={groupedDummies['1']}/>
      <Dummies2 dummiesArray={groupedDummies['2']}/>
      <Dummies3 dummiesArray={groupedDummies['3']}/>
      <Dummies4 dummiesArray={groupedDummies['4']}/>
      <Dummies5 dummiesArray={groupedDummies['5']}/>
      <Dummies6 dummiesArray={groupedDummies['6']}/>
      <Dummies7 dummiesArray={groupedDummies['7']}/>
      <Dummies8 dummiesArray={groupedDummies['8']}/>
      <Dummies9 dummiesArray={groupedDummies['9']}/>
      <Dummies10 dummiesArray={groupedDummies['10']}/>
      <Dummies11 dummiesArray={groupedDummies['11']}/>
      <Dummies12 dummiesArray={groupedDummies['12']}/>
      <Dummies13 dummiesArray={groupedDummies['13']}/>
      <Dummies14 dummiesArray={groupedDummies['14']}/>
      <Dummies15 dummiesArray={groupedDummies['15']}/>
      <Dummies16 dummiesArray={groupedDummies['16']}/>
      <Dummies17 dummiesArray={groupedDummies['17']}/>
      <Dummies18 dummiesArray={groupedDummies['18']}/>
      <Dummies19 dummiesArray={groupedDummies['19']}/>
      <Dummies20 dummiesArray={groupedDummies['20']}/>
    </>
  )
}

