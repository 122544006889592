import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

export default function Carousel() {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    // @ts-ignore
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    const request = {
      placeId: process.env.REACT_APP_GOOGLE_MAPS_PLACE_ID,
      fields: ['photos'],
    };
    console.log(request);
    service.getDetails(request, (place: any, status: any) => {
      console.log(place);
      console.log(status);
      // @ts-ignore
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setPhotos(place.photos);
        console.log(place.photos);
      }
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
  };


  return (
    <div className="slick-container">
      <Slider {...settings}>
        {photos.map((photo: any, key: number) => (
          <img src={photo.getUrl()} alt={photo.alt} key={key} />
        ))}
      </Slider>
    </div>
  );
}
