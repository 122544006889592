import { SCENE_INTERACTIONS } from './types';

export function toggleGrid() {
	return {
		type: SCENE_INTERACTIONS.TOGGLE_GRID
	}
}

export function toggleZoom() {
	return {
		type: SCENE_INTERACTIONS.TOGGLE_ZOOM,
	}
}
export function toggleFirstPerson() {
	return {
		type: SCENE_INTERACTIONS.TOGGLE_FIRST_PERSON,
	}
}

export function toggleFurnished() {
	return {
		type: SCENE_INTERACTIONS.TOGGLE_FURNISHED,
	}
}

export function toggleDummies() {
	return {
		type: SCENE_INTERACTIONS.TOGGLE_DUMMIES,
	}
}

export function toggleBand() {
	return {
		type: SCENE_INTERACTIONS.TOGGLE_BAND,
	}
}

export function setSelectedMesh(selectedMesh: number) {
	return {
		type: SCENE_INTERACTIONS.SET_SELECTED_MESH,
		payload: { selectedMesh }
	}
}

export function setIsDragging(isDragging: boolean) {
	return {
		type: SCENE_INTERACTIONS.SET_IS_DRAGGING,
		payload: { isDragging }
	}
}

export function setTarget(target: [number,number,number]) {
	console.log(target)
	return {
		type: SCENE_INTERACTIONS.SET_TARGET,
		payload: { target }
	}
}

export function setRoomCount(roomCount:number) {
	return {
		type: SCENE_INTERACTIONS.SET_ROOM_COUNT,
		payload: { roomCount }
	}
}
export function setRoomsArray(roomsArray:[]) {
	return {
		type: SCENE_INTERACTIONS.SET_ROOMS_ARRAY,
		payload: { roomsArray }
	}
}
export function setRoom(room:any) { // change to room
	return {
		type: SCENE_INTERACTIONS.SET_ROOM,
		payload: { room }
	}
}
export function setAssetsArray(assetsArray:[]) {
	return {
		type: SCENE_INTERACTIONS.SET_ASSETS_ARRAY,
		payload: { assetsArray }
	}
}
export function setAsset(asset:any) {
	return {
		type: SCENE_INTERACTIONS.SET_ASSET,
		payload: { asset }
	}
}
export function addAsset(asset:any) { // todo change to asset
	return {
		type: SCENE_INTERACTIONS.SET_ASSET,
		payload: { asset }
	}
}

export function toggleHighQuality() {
	return {
		type: SCENE_INTERACTIONS.TOGGLE_HIGH_QUALITY,
	}
}

export function setDummiesArray(dummiesArray:[]) {
	return {
		type: SCENE_INTERACTIONS.SET_DUMMIES_ARRAY,
		payload: { dummiesArray }
	}
}

export function setDummiesCount(dummiesCount:number) {
	return {
		type: SCENE_INTERACTIONS.SET_DUMMIES_COUNT,
		payload: { dummiesCount }
	}
}

export function setCameraPosition(cameraPosition:[]) {
	return {
		type: SCENE_INTERACTIONS.SET_CAMERA_POSITION,
		payload: { cameraPosition }
	}
}

export function setZoom(zoom:number) {
	return {
		type: SCENE_INTERACTIONS.SET_ZOOM,
		payload: { zoom }
	}
}


export function setPan(pan:number) {
	return {
		type: SCENE_INTERACTIONS.SET_PAN,
		payload: { pan }
	}
}

