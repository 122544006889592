import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import _RoomBase from "./_RoomBase";
import useSceneInteractions from "../../hooks/useSceneInteractions";
import Band from "../Dummies/Band";
import Chairs from "../Dummies/Chairs";
import Chair from "../Dummies/Chair";


type GLTFResult = GLTF & {
  nodes: {
    Material2001: THREE.Mesh
    Material2002: THREE.Mesh
    Material2003: THREE.Mesh
    Material2004: THREE.Mesh
    Material2005: THREE.Mesh
    Material2006: THREE.Mesh
    Material3: THREE.Mesh
    Material3001: THREE.Mesh
    Material2007: THREE.Mesh
    Material2008: THREE.Mesh
    Material2009: THREE.Mesh
    Material2010: THREE.Mesh
    Material3002: THREE.Mesh
    Material2011: THREE.Mesh
    Material2012: THREE.Mesh
    Material2013: THREE.Mesh
    Material2014: THREE.Mesh
    Material2015: THREE.Mesh
    Material2016: THREE.Mesh
    Material2017: THREE.Mesh
    Material2018: THREE.Mesh
    Material2019: THREE.Mesh
    Material2020: THREE.Mesh
    Material3003: THREE.Mesh
    Material2021: THREE.Mesh
    Material2022: THREE.Mesh
    Material2023: THREE.Mesh
  }
  materials: {
    MetalTrim: THREE.MeshStandardMaterial
    Tile002: THREE.MeshStandardMaterial
    Carpet: THREE.MeshStandardMaterial
    material: THREE.MeshStandardMaterial
    Color_07: THREE.MeshStandardMaterial
    Marble: THREE.MeshStandardMaterial
    Ceiling_Gypsum_Base_Color: THREE.MeshStandardMaterial
    Color_14: THREE.MeshStandardMaterial
    Curtain: THREE.MeshStandardMaterial
    Plastic002: THREE.MeshStandardMaterial
    Color_12: THREE.MeshStandardMaterial
    Floor: THREE.MeshStandardMaterial
    Fabric_Padded_Polyester_002_basecolor: THREE.MeshStandardMaterial
    Plastic001: THREE.MeshStandardMaterial
    GlassWindow: THREE.MeshStandardMaterial
    GlassDoor: THREE.MeshStandardMaterial
    Black: THREE.MeshStandardMaterial
    GlassDoor02: THREE.MeshStandardMaterial
    color_15: THREE.MeshStandardMaterial
    ExitSign: THREE.MeshStandardMaterial
  }
}


export default function Theatre1({ bandPosition, setBandPosition }: any) {
  const ROOM_NAME = 'theatre1';
  const ROOM_NAME2 = 'theatre1'; // todo fix this
  const [itemURI, setItemURI] = useState(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}-transformed.glb`);
  const { highQuality, band, furnished, room } = useSceneInteractions();

  useEffect(() => {
    if (!highQuality) {
      setItemURI(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}-transformed.glb`);
    } else {
      setItemURI(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}.glb`);
    }
  }, [highQuality]);

  // @ts-ignore
  const { nodes, materials } = useGLTF(itemURI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult;

  const SCALE = 0.035;

  return (
    <group dispose={null} >


        { band && (
          <Band scale={0.03} position={bandPosition} setPosition={setBandPosition} rotation={[0,3,0]} />
        )}
        { furnished && (
          <Chairs />
        )}

      <_RoomBase position={room.position} rotation={[0, 0, 0]} room={ROOM_NAME2}>

        <mesh scale={SCALE} geometry={nodes.Material2001.geometry} material={materials.MetalTrim} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2002.geometry} material={materials.Tile002} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2003.geometry} material={materials.Carpet} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2004.geometry} material={materials.material} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2005.geometry} material={materials.Color_07} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2006.geometry} material={materials.Marble} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material3.geometry} material={materials.Ceiling_Gypsum_Base_Color} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material3001.geometry} material={materials.Color_14} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2007.geometry} material={materials.Curtain} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2008.geometry} material={materials.Plastic002} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2009.geometry} material={materials.Plastic002} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2010.geometry} material={materials.Plastic002} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material3002.geometry} material={materials.Color_12} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} castShadow receiveShadow geometry={nodes.Material2011.geometry} material={materials.Floor} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2012.geometry} material={materials.Fabric_Padded_Polyester_002_basecolor} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2013.geometry} material={materials.Fabric_Padded_Polyester_002_basecolor} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2014.geometry} material={materials.Fabric_Padded_Polyester_002_basecolor} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2015.geometry} material={materials.Plastic001} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2016.geometry} material={materials.Plastic001} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2017.geometry} material={materials.Plastic001} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2018.geometry} material={materials.Plastic001} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2019.geometry} material={materials.GlassWindow} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2020.geometry} material={materials.GlassDoor} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material3003.geometry} material={materials.Black} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2021.geometry} material={materials.GlassDoor02} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2022.geometry} material={materials.color_15} rotation={[-Math.PI / 2, 0, 0]} />
        <mesh scale={SCALE} geometry={nodes.Material2023.geometry} material={materials.ExitSign} rotation={[-Math.PI / 2, 0, 0]} />


      </_RoomBase>

    </group>
  )
}
