import React, { useRef } from 'react'
import { RigidBody } from '@react-three/rapier';


export function WembleyStage({ rotation, position, scale }: any) {
  return (
    <group rotation={rotation} position={[-5.2,-0.5,2.6]}>
      <mesh>
        <boxGeometry args={[5, 0.3, 1]} />
        <meshPhongMaterial color="#ffffff" opacity={0.02} transparent />
        <meshPhongMaterial color="#ffffff" />
      </mesh>
      <mesh position={[-2,0,0]}>
        <boxGeometry args={[2, 0.3, 7]} />
        <meshPhongMaterial color="#ffffff" opacity={0.02} transparent />
        <meshPhongMaterial color="#ffffff" />
      </mesh>
    </group>
  )
}

