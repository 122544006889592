import * as THREE from 'three'
import React, {useEffect, useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import _RoomBase from "./_RoomBase";
import useSceneInteractions from "../../hooks/useSceneInteractions";
import Band from "../Dummies/Band";
import Chairs from "../Dummies/Chairs";
import Chair from "../Dummies/Chair";


type GLTFResult = GLTF & {
  nodes: {
    pCube472_lambert140_0: THREE.Mesh
    pCube482_lambert145_0: THREE.Mesh
    pCube520_lambert154_0: THREE.Mesh
    pCube521_lambert164_0: THREE.Mesh
    polySurface11245_lambert143_0: THREE.Mesh
    polySurface11221_lambert131_0: THREE.Mesh
    polySurface11223_lambert147_0: THREE.Mesh
    polySurface11344_lambert142_0: THREE.Mesh
    polySurface11159_lambert146_0: THREE.Mesh
    polySurface11242_lambert134_0: THREE.Mesh
    polySurface11324_lambert144_0: THREE.Mesh
    polySurface11330_lambert129_0: THREE.Mesh
    polySurface11332_lambert128_0: THREE.Mesh
    polySurface11342_lambert141_0: THREE.Mesh
    polySurface11345_lambert137_0: THREE.Mesh
    polySurface11346_lambert138_0: THREE.Mesh
    polySurface11348_lambert139_0: THREE.Mesh
    polySurface11363_lambert135_0: THREE.Mesh
    polySurface11385_lambert136_0: THREE.Mesh
    polySurface11395_lambert150_0: THREE.Mesh
    polySurface11414_lambert149_0: THREE.Mesh
    polySurface11415_lambert151_0: THREE.Mesh
    polySurface11417_lambert153_0: THREE.Mesh
    polySurface11419_lambert157_0: THREE.Mesh
    polySurface11421_lambert160_0: THREE.Mesh
    polySurface11448_lambert161_0: THREE.Mesh
    polySurface11515_lambert152_0: THREE.Mesh
    polySurface11516_blinn2_0: THREE.Mesh
    polySurface11517_lambert156_0: THREE.Mesh
    polySurface11518_lambert158_0: THREE.Mesh
    polySurface11519_lambert159_0: THREE.Mesh
    polySurface11520_lambert162_0: THREE.Mesh
    polySurface11555_lambert166_0: THREE.Mesh
    polySurface11556_lambert167_0: THREE.Mesh
    polySurface11557_lambert168_0: THREE.Mesh
    polySurface11609_lambert170_0: THREE.Mesh
    polySurface11626_lambert171_0: THREE.Mesh
    polySurface11627_lambert173_0: THREE.Mesh
    polySurface11635_lambert172_0: THREE.Mesh
    polySurface11679_lambert177_0: THREE.Mesh
    polySurface11664_lambert176_0: THREE.Mesh
    transform29_lambert132_0: THREE.Mesh
    transform39_lambert169_0: THREE.Mesh
    transform39_lambert169_0001: THREE.Mesh
  }
  materials: {
    lambert140: THREE.MeshStandardMaterial
    lambert145: THREE.MeshStandardMaterial
    lambert154: THREE.MeshStandardMaterial
    lambert164: THREE.MeshStandardMaterial
    lambert143: THREE.MeshStandardMaterial
    lambert131: THREE.MeshStandardMaterial
    lambert147: THREE.MeshStandardMaterial
    lambert142: THREE.MeshStandardMaterial
    lambert146: THREE.MeshStandardMaterial
    lambert134: THREE.MeshStandardMaterial
    lambert144: THREE.MeshStandardMaterial
    lambert129: THREE.MeshStandardMaterial
    lambert128: THREE.MeshStandardMaterial
    lambert141: THREE.MeshStandardMaterial
    lambert137: THREE.MeshStandardMaterial
    lambert138: THREE.MeshStandardMaterial
    lambert139: THREE.MeshStandardMaterial
    lambert135: THREE.MeshStandardMaterial
    lambert136: THREE.MeshStandardMaterial
    lambert150: THREE.MeshStandardMaterial
    lambert149: THREE.MeshStandardMaterial
    lambert151: THREE.MeshStandardMaterial
    lambert153: THREE.MeshStandardMaterial
    lambert157: THREE.MeshStandardMaterial
    lambert160: THREE.MeshStandardMaterial
    lambert161: THREE.MeshStandardMaterial
    lambert152: THREE.MeshStandardMaterial
    blinn2: THREE.MeshStandardMaterial
    lambert156: THREE.MeshStandardMaterial
    lambert158: THREE.MeshStandardMaterial
    lambert159: THREE.MeshStandardMaterial
    lambert162: THREE.MeshStandardMaterial
    lambert166: THREE.MeshStandardMaterial
    lambert167: THREE.MeshStandardMaterial
    lambert168: THREE.MeshStandardMaterial
    lambert170: THREE.MeshStandardMaterial
    lambert171: THREE.MeshStandardMaterial
    lambert173: THREE.MeshStandardMaterial
    lambert172: THREE.MeshStandardMaterial
    lambert177: THREE.MeshStandardMaterial
    lambert176: THREE.MeshStandardMaterial
    lambert132: THREE.MeshStandardMaterial
    lambert169: THREE.MeshStandardMaterial
  }
}


export default function Theatre2({ }: any) {
  const ROOM_NAME = 'theatre2';
  const ROOM_NAME2 = 'theatre2'; // todo fix this
  const [itemURI, setItemURI] = useState(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}-transformed.glb`);
  const { highQuality, band, furnished, room } = useSceneInteractions();

  useEffect(() => {
    if (!highQuality) {
      setItemURI(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}-transformed.glb`);
    } else {
      setItemURI(`${process.env.REACT_APP_ASSETS_URL}/${ROOM_NAME}.glb`);
    }
  }, [highQuality]);

  // @ts-ignore
  const { nodes, materials } = useGLTF(itemURI, 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/') as GLTFResult;

  const SCALE = 0.1;

  return (
    <group dispose={null} >


        { band && (
          <Band scale={0.02} position={[0,0,0]} rotation={[0,3,0]} />
        )}
        { furnished && (
          <Chairs />
        )}

      <_RoomBase position={room.position} rotation={[0, 0, 0]} room={ROOM_NAME2}>

        <mesh scale={SCALE} geometry={nodes.pCube472_lambert140_0.geometry} material={materials.lambert140} />
        <mesh scale={SCALE} geometry={nodes.pCube482_lambert145_0.geometry} material={materials.lambert145} />
        <mesh scale={SCALE} geometry={nodes.pCube520_lambert154_0.geometry} material={materials.lambert154} />
        <mesh scale={SCALE} geometry={nodes.pCube521_lambert164_0.geometry} material={materials.lambert164} />
        <mesh scale={SCALE} geometry={nodes.polySurface11245_lambert143_0.geometry} material={materials.lambert143} />
        <mesh scale={SCALE} geometry={nodes.polySurface11221_lambert131_0.geometry} material={materials.lambert131} />
        <mesh scale={SCALE} geometry={nodes.polySurface11223_lambert147_0.geometry} material={materials.lambert147} />
        <mesh scale={SCALE} geometry={nodes.polySurface11344_lambert142_0.geometry} material={materials.lambert142} />
        <mesh scale={SCALE} geometry={nodes.polySurface11159_lambert146_0.geometry} material={materials.lambert146} />
        <mesh scale={SCALE} geometry={nodes.polySurface11242_lambert134_0.geometry} material={materials.lambert134} />
        <mesh scale={SCALE} geometry={nodes.polySurface11324_lambert144_0.geometry} material={materials.lambert144} />
        <mesh scale={SCALE} geometry={nodes.polySurface11330_lambert129_0.geometry} material={materials.lambert129} />
        <mesh scale={SCALE} geometry={nodes.polySurface11332_lambert128_0.geometry} material={materials.lambert128} />
        <mesh scale={SCALE} geometry={nodes.polySurface11342_lambert141_0.geometry} material={materials.lambert141} />
        <mesh scale={SCALE} geometry={nodes.polySurface11345_lambert137_0.geometry} material={materials.lambert137} />
        <mesh scale={SCALE} geometry={nodes.polySurface11346_lambert138_0.geometry} material={materials.lambert138} />
        <mesh scale={SCALE} geometry={nodes.polySurface11348_lambert139_0.geometry} material={materials.lambert139} />
        <mesh scale={SCALE} geometry={nodes.polySurface11363_lambert135_0.geometry} material={materials.lambert135} />
        <mesh scale={SCALE} geometry={nodes.polySurface11385_lambert136_0.geometry} material={materials.lambert136} />
        <mesh scale={SCALE} geometry={nodes.polySurface11395_lambert150_0.geometry} material={materials.lambert150} />
        <mesh scale={SCALE} geometry={nodes.polySurface11414_lambert149_0.geometry} material={materials.lambert149} />
        <mesh scale={SCALE} geometry={nodes.polySurface11415_lambert151_0.geometry} material={materials.lambert151} />
        <mesh scale={SCALE} geometry={nodes.polySurface11417_lambert153_0.geometry} material={materials.lambert153} />
        <mesh scale={SCALE} geometry={nodes.polySurface11419_lambert157_0.geometry} material={materials.lambert157} />
        <mesh scale={SCALE} geometry={nodes.polySurface11421_lambert160_0.geometry} material={materials.lambert160} />
        <mesh scale={SCALE} geometry={nodes.polySurface11448_lambert161_0.geometry} material={materials.lambert161} />
        <mesh scale={SCALE} geometry={nodes.polySurface11515_lambert152_0.geometry} material={materials.lambert152} />
        <mesh scale={SCALE} geometry={nodes.polySurface11516_blinn2_0.geometry} material={materials.blinn2} />
        <mesh scale={SCALE} geometry={nodes.polySurface11517_lambert156_0.geometry} material={materials.lambert156} />
        <mesh scale={SCALE} geometry={nodes.polySurface11518_lambert158_0.geometry} material={materials.lambert158} />
        <mesh scale={SCALE} geometry={nodes.polySurface11519_lambert159_0.geometry} material={materials.lambert159} />
        <mesh scale={SCALE} geometry={nodes.polySurface11520_lambert162_0.geometry} material={materials.lambert162} />
        <mesh scale={SCALE} geometry={nodes.polySurface11555_lambert166_0.geometry} material={materials.lambert166} />
        <mesh scale={SCALE} geometry={nodes.polySurface11556_lambert167_0.geometry} material={materials.lambert167} />
        <mesh scale={SCALE} geometry={nodes.polySurface11557_lambert168_0.geometry} material={materials.lambert168} />
        <mesh scale={SCALE} geometry={nodes.polySurface11609_lambert170_0.geometry} material={materials.lambert170} />
        <mesh scale={SCALE} geometry={nodes.polySurface11626_lambert171_0.geometry} material={materials.lambert171} />
        <mesh scale={SCALE} geometry={nodes.polySurface11627_lambert173_0.geometry} material={materials.lambert173} />
        <mesh scale={SCALE} geometry={nodes.polySurface11635_lambert172_0.geometry} material={materials.lambert172} />
        <mesh scale={SCALE} geometry={nodes.polySurface11679_lambert177_0.geometry} material={materials.lambert177} />
        <mesh scale={SCALE} geometry={nodes.polySurface11664_lambert176_0.geometry} material={materials.lambert176} />
        <mesh scale={SCALE} geometry={nodes.transform29_lambert132_0.geometry} material={materials.lambert132} />
        <mesh scale={SCALE} geometry={nodes.transform39_lambert169_0.geometry} material={materials.lambert169} />
        <mesh scale={SCALE} geometry={nodes.transform39_lambert169_0001.geometry} material={materials.lambert169} />

      </_RoomBase>

    </group>
  )
}
