import {Button, Modal, Typography, Box} from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Carousel from "./Carousel";
import PhotoViewer from "./PhotoViewer";
import useSceneInteractions from "../hooks/useSceneInteractions";

export default function PhotosModal ({ pointerControls, showPhotosModal, setShowPhotosModal }: any) {
  const { room, roomsArray } = useSceneInteractions();

  const handleClose = () => {
    setShowPhotosModal(false);
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      className="info-modal"
      open={showPhotosModal}
      onClose={handleClose}
    >
      <Box component="div" sx={modalStyle}>
        <HighlightOffIcon className="closeModalButton" onClick={() => { setShowPhotosModal(false)}}/>
        {/*<Typography variant="h3" className="secondaryColor">Photos</Typography>*/}
        <div>
          <Typography  variant="h2" className="secondaryColor">{room.fullName}</Typography>
        </div>

        <br/>

        <PhotoViewer />

        <br/>

        <div className={'infoModal__button-container'}>
          {/*todo fix errors if user clicks close too quickly*/}
          <Button className="closeInfoModalButton--large" variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  )
}
