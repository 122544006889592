import {
  Loader,
  OrbitControls,
  PerspectiveCamera, Sky, Stars
} from "@react-three/drei";
import React, {Suspense, useEffect, useRef, useState} from "react";
import useSceneInteractions from "../hooks/useSceneInteractions";
import {Canvas, PerspectiveCameraProps} from "@react-three/fiber";
import {Grid} from "./Grid";
import {setTarget} from "../store/sceneInteractions/actions";
import Rooms from "./Rooms/Rooms";

export default function SpaceOne({  setShowWarningModal, nightTime }: any) {
  const { target, firstPerson, room, zoom, cameraPosition, setTarget, isDragging } = useSceneInteractions();

  const perspectiveCameraRef = useRef<PerspectiveCameraProps>(null!);

  useEffect(() => {
    if (perspectiveCameraRef && perspectiveCameraRef.current) {
      // @ts-ignore
      // perspectiveCameraRef.current.position.set([zoom, zoom, zoom]);
      console.log('room camera position:', room.cameraPosition);
      // @ts-ignore
      perspectiveCameraRef.current.position.set(room.cameraPosition[0], room.cameraPosition[1], room.cameraPosition[2]);
      // if (orbitControlsRef && orbitControlsRef.current) {
      //   orbitControlsRef.current.update();
      // }
    }
  }, []);

  /**
   * When user adjusts the zoom using the slider
   */
  useEffect(() => {
    if (perspectiveCameraRef && perspectiveCameraRef.current) {
      console.log(zoom)
      console.log('here setting camera')
      // @ts-ignore
      perspectiveCameraRef.current.position.set([zoom, zoom, zoom]);
    }
  }, [zoom]);

  // useEffect(() => {
  //   setShowWarningModal(true)
  // },[])

  const orbitControlsRef = useRef<any>(null!);

  useEffect(() => {
      if (orbitControlsRef && orbitControlsRef.current) {
        orbitControlsRef.current.update();
        console.log('here3')
      }
  }, [target])

  useEffect(() => {
    console.log('room changed')
    setTarget([room.target[0], room.target[1], room.target[2]]); // change to room's initial target
    // todo might need to trigger an update here. if the pivot point doesnt change when changing room especially
    if (orbitControlsRef && orbitControlsRef.current) {
      orbitControlsRef.current.update();
      console.log('here3')
      console.log('current target:', target)
    }
  }, [room])

  const [bandPosition, setBandPosition] = useState<any>([0,0.65,14]);

  return (
    <>
      <Loader />

      { !firstPerson && (
        <Canvas>
          {/*lock zoom to keep dolls house view. Can use minPolarAngle={Math.PI/2.1} maxPolarAngle={Math.PI/2.1} to lock rotation */}
          {/*min and max distance should correspond to the values in the slider*/}
          <OrbitControls enabled={!isDragging} minDistance={room.minZoom} maxDistance={room.maxZoom} ref={orbitControlsRef} enableZoom={true} enablePan={true} target={[target[0], target[1], target[2]]} />

          <ambientLight/>
          <pointLight intensity={3} position={[0, 0, 0]}/>
          <PerspectiveCamera fov={room.fov} ref={perspectiveCameraRef} position={[room.cameraPosition[0], room.cameraPosition[1], room.cameraPosition[2]]} makeDefault/>

          <pointLight position={[1, 2, 0]} intensity={2} color="#fff" />
          <pointLight position={[-1, 2, -2]} intensity={1.5} color="#fff" />
          <pointLight position={[-1, 2, 1.5]} intensity={1.5} color="#fff" />

          <Grid />

          { !nightTime && (
            <>
              {/*<Sky scale={1000} sunPosition={[500, 150, -1000]} turbidity={0.1} />*/}
              <Sky sunPosition={[500, 150, -1000]} turbidity={0.1} />
            </>
          )}

          { nightTime && (
            <>
              <color attach="background" args={['#000']}/>
              <Stars
                radius={100} // Radius of the inner sphere (default=100)
                depth={50} // Depth of area where stars should fit (default=50)
                count={5000} // Amount of stars (default=5000)
                factor={4} // Size factor (default=4)
                saturation={0} // Saturation 0-1 (default=0)
                fade // Faded dots (default=false)
              />
            </>
          )}

          <Suspense>
            <Rooms bandPosition={bandPosition} setBandPosition={setBandPosition} />
          </Suspense>
        </Canvas>
      )}
    </>
  )
}
