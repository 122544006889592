import React from "react";
import {Brightness5, Brightness2, MusicNoteOutlined, MusicOffOutlined, ViewInAr} from '@mui/icons-material';

const NightTimeSwitch = ({nightTime, setNightTime}: any) => {
  const toggle = () => setNightTime(!nightTime);

  return (
    <div  style={{paddingTop:'4px'}}>
      {!nightTime && (
        <Brightness5 className="pointer" style={{color: "white"}} onClick={toggle} />
      )}
      {nightTime && (
        <Brightness2 className="pointer" style={{color: "white"}} onClick={toggle} />
      )}
    </div>
  );
};

export default NightTimeSwitch;
